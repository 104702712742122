import React from 'react';
import { Table, Layout, Space, Card, Badge } from 'antd';
import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { usePaginate } from 'backend/usePaginate';
import { getBadgeColor } from 'utils/labels';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';
import { useSelector } from 'react-redux';
import { selectCMS } from 'features/cms/cmsSlice';
import { sorter } from 'utils/custom';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'good-standing';
const GoodStandings = () => {
  const { booleanOptions, paymentStatusOptions } = useSelector(selectCMS);
  const { updateItem } = useBackend();
  const { hasMore, items, loadMore, updateById, filter } = usePaginate(
    endpoint,
    {
      // orderBy: "title",
      // order: "asc",
      limit: 100,
    },
  );

  const handleDeliver = (item) => {
    updateItem(`${endpoint}/fulfill`, item.id, {}, { loader: true }).then(() =>
      updateById(item.id, { delivered: true }),
    );
  };

  /*
  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: "Deleting...", success: "Deleted successfully." },
    };
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <>
          <div>
            You want to delete <b>{item.name}</b>?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: "No",
      onOk: () =>
        trashItem(endpoint, item.id, config).then(() =>
          setList(list.filter((current) => current.id !== item.id))
        ),
      okText: "Yes",
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };
  */

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'title'),
      // sortDirections: ['descend']
      render: (title) => (
        <div>
          <b>{title}</b>
        </div>
      ),
    },
    {
      title: 'Delivery Email',
      dataIndex: 'deliveryEmail',
      key: 'deliveryEmail',
    },
    {
      title: 'Where to Use',
      dataIndex: 'whereToUse',
      key: 'whereToUse',
    },
    {
      title: 'Delivered',
      dataIndex: 'delivered',
      key: 'delivered',
      render: (delivered) => (delivered ? 'Yes' : 'No'),
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <DateTimeAgo date={createdAt} />,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `view/${row.id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          {row.delivered ? (
            'Delivered'
          ) : (
            <CheckOutlined
              className="text-green-600"
              onClick={() => handleDeliver(row)}
            />
          )}
          {/* <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          /> */}
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Good Standings Certificates">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            // placeholder: "Search...",
            options: [
              {
                key: 'title',
                label: 'Title',
              },
              {
                key: 'deliveryEmail',
                label: 'Delivery Email',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Delivered',
              dbKey: 'delivered',
              options: booleanOptions,
            },
            {
              placeholder: 'Payment Status',
              dbKey: 'status',
              options: paymentStatusOptions,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
    </Content>
  );
};

export default GoodStandings;
