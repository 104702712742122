import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

// project import
import { LoaderSuspense } from 'utils/loader';
import { useSelector } from 'react-redux';
import { selectAuth } from 'features/auth/authSlice';
import { idle, loading } from 'features/status';

const AuthGuard = ({ children }) => {
  const { roles, logged, status } = useSelector(selectAuth);

  // return <LoaderSuspense />;

  if (status === idle || status === loading) {
    return <LoaderSuspense />;
  } else {
    if (!logged) {
      return <Navigate to="/auth/login" />;
    } else if (roles.length > 0 && roles.includes('admin')) {
      return children;
    } else {
      return <Navigate to="/403" />;
    }
  }
};

AuthGuard.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AuthGuard;
