import { createSlice } from '@reduxjs/toolkit';
import { config } from 'config';
// project imports

export const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
};

export const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    menuOpenAction: (state, action) => {
      let id = action.payload.id;
      state.isOpen = [id];
    },
    setMenuAction: (state, action) => {
      state.opened = action.payload.opened;
    },
    setFontFamilyAction: (state, action) => {
      state.fontFamily = action.payload.fontFamily;
    },
    setBorderRadiusAction: (state, action) => {
      state.borderRadius = action.payload.borderRadius;
    },
  },
});

export const {
  menuOpenAction,
  setBorderRadiusAction,
  setFontFamilyAction,
  setMenuAction,
} = customizationSlice.actions;

export default customizationSlice.reducer;
