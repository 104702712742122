import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Descriptions, Skeleton, Card } from 'antd';
import { useBackend } from 'backend/useBackend';
// import { countryNameByIso2 } from "data/countries";
import { businessTypeLabel } from 'utils/labels';
import { useSelector } from 'react-redux';
import { selectAssetActiveCertificates } from 'features/cms/assetsCertificateSlice';
import { config } from 'config';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;
const apiUrl = config.apiUrl;
const endpoint = 'portfolio/assets';

const BusinessInfo = () => {
  const { assetId } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();
  const activeCertificates = useSelector(selectAssetActiveCertificates);

  let totalCertificateCount = 0;
  if (item?.businessType) {
    totalCertificateCount = activeCertificates.filter(
      (c) =>
        c.certificateType === item.businessType && c.entityEnding === 'ALL',
    ).length;
    totalCertificateCount += activeCertificates.filter(
      (c) =>
        c.certificateType === item.businessType &&
        c.entityEnding === item.entityEnding,
    ).length;
  }
  useEffect(() => {
    getItem(endpoint, assetId).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title={`${item?.businessName} ${item?.entityEnding}`}>
          <Descriptions
            title="Asset/Business Details"
            layout="vertical"
            bordered
          >
            <Descriptions.Item label="Asset/Business Name" span={2}>
              <b>
                {item?.businessName} {item?.entityEnding}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Asset/Business Type" span={1}>
              <b>{businessTypeLabel(item?.businessType)}</b>
            </Descriptions.Item>
            {/* <Descriptions.Item label="Asset/Business Residency">
              {countryNameByIso2(item?.country)}
            </Descriptions.Item> */}
            <Descriptions.Item label="BICRAP">
              {item.bicraFormatted || 'Generating In Backend...'}
            </Descriptions.Item>
            <Descriptions.Item label="Filing Number">
              {item.filingNumberFormatted || 'Generating In Backend...'}
            </Descriptions.Item>
          </Descriptions>
          <br />
          {totalCertificateCount > 0 ? (
            <Descriptions
              title="Download Certificates"
              layout="vertical"
              // bordered
              // className="bg-green-200"
            >
              {/* <pre>{JSON.stringify(certificates, null, 2)}</pre> */}
              {activeCertificates
                .filter(
                  (c) =>
                    c.certificateType === item.businessType &&
                    c.entityEnding === 'ALL',
                )
                .map((certificate) => (
                  <Descriptions.Item span={3}>
                    <div className="mx-6 w-full">
                      <a
                        href={`${apiUrl}/assets-certificate/download/${certificate.id}/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        {certificate?.title}
                      </a>
                    </div>
                  </Descriptions.Item>
                ))}
              {activeCertificates
                .filter(
                  (c) =>
                    c.certificateType === item.businessType &&
                    c.entityEnding === item.entityEnding,
                )
                .map((certificate) => (
                  <Descriptions.Item span={3}>
                    <div className="mx-6 w-full">
                      <a
                        href={`${apiUrl}/assets-certificate/download/${certificate.id}/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        {certificate?.title}
                      </a>
                    </div>
                  </Descriptions.Item>
                ))}
            </Descriptions>
          ) : (
            <Descriptions
              title="Download Certificates"
              layout="vertical"
              // bordered
              // className="bg-green-200"
            >
              <Descriptions.Item span={3}>
                <div className="mx-6 w-full">
                  <h4>No certificate available to download.</h4>
                  <div className="right">
                    <b>
                      <small className="text-danger">
                        Please upload/update certificate for this type of entity
                      </small>
                    </b>
                  </div>
                </div>
              </Descriptions.Item>
            </Descriptions>
          )}
          {/* <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item.state}</Descriptions.Item>
            <Descriptions.Item label="Zip Code">
              {item.zipCode}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {item.country}
            </Descriptions.Item>
          </Descriptions> */}
          {/* <br />
          <Descriptions title="Shareholder Details" layout="vertical" bordered>
            <Descriptions.Item label="Shareholder Type">
              {item.shareholderType}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Contact Info" layout="vertical" bordered>
            <Descriptions.Item label="Tel Number">
              {item.landLine}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{item.email}</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item.state}</Descriptions.Item>
            <Descriptions.Item label="Country">
              {countryNameByIso2(item?.country)}
            </Descriptions.Item>
            <Descriptions.Item label="ZipCode">
              {item.zipCode}
            </Descriptions.Item>
          </Descriptions> */}
          <br />
          <Descriptions title="System Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
            <Descriptions.Item label="Created At">
              <DateTimeAgo date={item.createdAt} onlyDate />
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At">
              <DateTimeAgo date={item.updatedAt} onlyDate />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </Content>
  );
};

export default BusinessInfo;
