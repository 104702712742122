import { lazy } from 'react';

// project imports
import Loadable from 'components/Loadable';
import PageLayout from '../layouts/PageLayout';

const Page404 = Loadable(lazy(() => import('../pages/404')));
const Page403 = Loadable(lazy(() => import('../pages/403')));

// ==============================|| PAGES ROUTING ||============================== //

const PageRoutes = {
  path: '/',
  element: <PageLayout />,
  children: [
    {
      path: '/403',
      element: <Page403 />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ],
};

export default PageRoutes;
