import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Layout, Descriptions, Skeleton, Card, Row, Col, message } from 'antd';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useBackend } from 'backend/useBackend';
import DateTimeAgo from 'components/DateTimeAgo';
import { API_ENDPOINTS } from 'utils/constants';
import { handleError } from 'utils/validation';
import { config } from 'config';

const { Content } = Layout;
const pinataUrl = config.pinataUrl;

const AssetInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();
  const [copiedText, setCopiedText] = useState(false);
  const copyText = useRef(null);

  useEffect(() => {
    getItem(API_ENDPOINTS.assets, id)
      .then(({ item }) => setItem(item))
      .catch((e) => {
        message.error(handleError(e));
        let timeoutId = setTimeout(() => {
          navigate('/rwa-assets');
        }, 1500);
        return () => clearTimeout(timeoutId);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const copyTextToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(copyText.current.textContent);
    setCopiedText(true);
    let timeoutId = setTimeout(() => setCopiedText(false), 2000);
    return () => clearTimeout(timeoutId);
  }, [copyText]);

  return (
    <Content>
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title={`Asset Id: ${id}`}>
          <Row gutter={[20, 20]}>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <Descriptions title="Asset Image" layout="vertical" bordered>
                <Descriptions.Item label="Asset Image">
                  <img
                    className="w-48"
                    src={pinataUrl + '/' + item.ipfsCid}
                    alt="asset"
                  />
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="Asset Info" layout="vertical" bordered>
                <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
                <Descriptions.Item label="Category">
                  {item.category
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Descriptions.Item>
                <Descriptions.Item label="Sub Category">
                  {item.subCategory
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="Price Info" layout="vertical" bordered>
                <Descriptions.Item label="Price in USD">
                  {item.price}
                </Descriptions.Item>
                <Descriptions.Item label="Price in Matic">
                  {item.priceInMatic}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="Description" layout="vertical" bordered>
                <Descriptions.Item label="Description">
                  {item.description}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="Asset Status" layout="vertical" bordered>
                <Descriptions.Item label="Wallet Address">
                  <span ref={copyText}>{item.account}</span>{' '}
                  {!copiedText ? (
                    <CopyOutlined onClick={copyTextToClipboard} />
                  ) : (
                    <CheckCircleOutlined />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Verified">
                  {item.isVerified ? 'Yes' : 'No'}
                </Descriptions.Item>
                <Descriptions.Item label="Minted">
                  {item.isMinted ? 'Yes' : 'No'}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="System Details" layout="vertical" bordered>
                <Descriptions.Item label="Created At">
                  <DateTimeAgo date={item.createdAt} onlyDate />
                </Descriptions.Item>
                <Descriptions.Item label="Last Updated At">
                  <DateTimeAgo date={item.updatedAt} onlyDate />
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <Descriptions title="Documents" layout="vertical" bordered>
                <Descriptions.Item label="Documents">
                  <div className="grid grid-cols-2 gap-4">
                    {item.documents?.map(({ name, s3Url }, i) => (
                      <div key={s3Url}>
                        <object
                          key={s3Url}
                          data={s3Url}
                          height="300"
                          className="w-full"
                        >
                          {name}
                        </object>
                        <div>{name}</div>
                      </div>
                    ))}
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      )}
    </Content>
  );
};

export default AssetInfo;
