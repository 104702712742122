import { configureStore } from '@reduxjs/toolkit';
import authSlice from 'features/auth/authSlice';
import { cmsSlice } from './cms/cmsSlice';
import { customizationSlice } from './customization/customizationSlice';
import { assetsSlice } from './cms/assetsSlice';
import { themeSlice } from './theme/themeSlice';
import { paginationSlice } from './pagination/paginationSlice';
import { web3Slice } from './web3Slice';
import { assetsCertificateSlice } from './cms/assetsCertificateSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    [themeSlice.name]: themeSlice.reducer,
    [cmsSlice.name]: cmsSlice.reducer,
    [assetsSlice.name]: assetsSlice.reducer,
    [customizationSlice.name]: customizationSlice.reducer,
    [paginationSlice.name]: paginationSlice.reducer,
    [web3Slice.name]: web3Slice.reducer,
    [assetsCertificateSlice.name]: assetsCertificateSlice.reducer,
  },
});

// https://www.youtube.com/watch?v=93CR_yURoII

// https://redux.js.org/introduction/why-rtk-is-redux-today
