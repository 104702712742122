import React from 'react';
// import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Layout, Space, Card, Modal, FloatButton } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
  // EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
// import dayjs from 'dayjs';
import { usePaginate } from 'backend/usePaginate';
import { useBackend } from 'backend/useBackend';
import FilterPanel from 'components/FilterPanel';
// import { sorter } from 'utils/custom';
// import { selectCMS } from 'features/cms/cmsSlice';
import DateTimeAgo from 'components/DateTimeAgo';
import ShareholderDetailsByType from './ShareholderDetailsByType';
import { countryNameByIso2 } from 'data/countries';
// import { config } from "config";
// const contractExplorerUrl = config.contractExplorerUrl;

const { Content } = Layout;

const endpoint = 'shareholder-business';

const BusinessShareholder = () => {
  // const { statuses } = useSelector(selectCMS);
  const navigation = useNavigate();
  const { trashItem } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
  });

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.businessName} {item.firstName} {item.lastName}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Shareholder Details',
      dataIndex: 'id',
      key: 'id',
      // filters: {},
      // sorter: true,
      // sorter: (a, b) => sorter(a, b, 'id'),
      // sortDirections: ['descend']
      render: (id, row) => (
        <div>
          <b>{row.type} Shareholder</b>
          {row?.user?.email && (
            <div className="text-xs">
              Belongs to{' '}
              <b>
                <Link to={`/users/view/${row?.user.id}`} className="underline">
                  {row.user.email}
                </Link>
              </b>
            </div>
          )}
          <ShareholderDetailsByType shareholder={row} />
          {row?.email && (
            <div>
              Email <b>{row?.email}</b>
            </div>
          )}
          {row?.mobile && (
            <div>
              Mobile <b>{row?.mobile}</b>
            </div>
          )}
          <p className="text-sm">
            Share <b>{row.share}%</b>
          </p>
          <div className="mt-2 text-muted">
            <div className="font-bold">Address</div>
            {row.address}
            {row.city} - {row.zipCode}
            <p>
              {row.state}, {countryNameByIso2(row.country)}
            </p>
          </div>
        </div>
      ),
    },
    // {
    //   title: 'Filing Number',
    //   dataIndex: 'filingNumber',
    //   key: 'filingNumber',
    // },
    {
      title: 'Share',
      dataIndex: 'share',
      key: 'share',
      render: (share) => <b>{share}%</b>,
    },
    // {
    //   title: 'Registered Date',
    //   dataIndex: 'regDate',
    //   key: 'regDate',
    //   render: (regDate) => dayjs(new Date(regDate)).format('DD/MM/YYYY'),
    // },
    {
      title: 'Last Update At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => (
        <DateTimeAgo date={row.updatedAt || createdAt} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          {/* <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link> */}
          <Link
            to={{
              pathname: `${row.type}/edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Business Shareholders">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'filingNumber',
                label: 'Filing Number',
              },
              {
                key: 'passportNumber',
                label: 'Passport Number',
              },
            ],
          }}
          // selectControl={[
          //   {
          //     placeholder: 'Status...',
          //     dbKey: 'status',
          //     options: statuses,
          //   },
          // ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton.Group trigger="hover" type="primary" icon={<PlusOutlined />}>
        <FloatButton
          tooltip="Add Corporate Shareholder"
          icon={<UsergroupAddOutlined />}
          onClick={() => navigation('Corporate/add')}
        />
        <FloatButton
          tooltip="Add Individual Shareholder"
          icon={<UsergroupAddOutlined />}
          onClick={() => navigation('Individual/add')}
        />
      </FloatButton.Group>
    </Content>
  );
};

export default BusinessShareholder;
