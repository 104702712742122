import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Button,
  Collapse,
  Skeleton,
} from 'antd';
import { ArrowLeftOutlined, LockOutlined } from '@ant-design/icons';

import { useBackend } from 'backend/useBackend';

const { Content } = Layout;

const endpoint = 'users';

const PasswordReset = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const { updateItem, getItem } = useBackend();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      ...data,
    };
    let config = {
      loader: true,
      msg: { loading: 'Updating...', success: 'Password reset successfully.' },
      redirectTo: '../' + endpoint,
    };
    console.log(payload);
    updateItem('users', `${id}/password-reset`, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Reset Password">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel
                    header={`Reset Password ${item.email}`}
                    key="1"
                  >
                    <Row>
                      <Col sm={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item
                          label="New Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Password is required!',
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="New Password"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Action" key="1">
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Reset
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Content>
  );
};

export default PasswordReset;
