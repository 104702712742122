import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  Button,
  Collapse,
  InputNumber,
  // DatePicker,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// import dayjs from "dayjs";
// import weekday from "dayjs/plugin/weekday";
// import localeData from "dayjs/plugin/localeData";

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';

// dayjs.extend(weekday);
// dayjs.extend(localeData);
// dayjs.locale("en");

const { Content } = Layout;

const endpoint = 'products';

const ProductsAdd = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { addItem } = useBackend();
  const { productModuleOptions, productTypeOptions, productStatusOptions } =
    useSelector(selectCMS);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      ...data,
    };
    let config = {
      loader: true,
      msg: { loading: 'Processing...', success: 'Added successfully.' },
      redirectTo: '../' + endpoint,
    };
    console.log(payload);
    addItem(endpoint, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      <Card title="Add Product">
        <Form
          form={form}
          layout="vertical"
          // initialValues={{
          //   ...item,
          // }}
          // size="large"
          // onValuesChange={onRequiredTypeChange}
          // requiredMark={requiredMark}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[20, 20]}>
            <Col sm={{ span: 24 }} md={{ span: 18 }}>
              <Collapse defaultActiveKey="1">
                <Collapse.Panel header="Product Details" key="1">
                  <Form.Item
                    label="Product Name"
                    name="name"
                    rules={[{ required: 'Product Name required' }]}
                    hasFeedback
                  >
                    <Input placeholder="Product Name" />
                  </Form.Item>
                  <Form.Item
                    label="Product SKU"
                    name="sku"
                    rules={[{ required: 'Product SKU required' }]}
                    hasFeedback
                  >
                    <Input placeholder="Product SKU" />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: 'Description required' }]}
                    hasFeedback
                  >
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                  <Row gutter={[12, 12]}>
                    <Col sm={24}>
                      <Form.Item
                        className="mb-2"
                        label="Product Price"
                        name="price"
                        rules={[
                          {
                            required: true,
                            message: 'Product Price is required',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Product Price"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="Product Type"
                        name="productType"
                        rules={[{ required: 'Product Type required' }]}
                        hasFeedback
                      >
                        <Select
                          placeholder="Select product type"
                          options={productTypeOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        label="Site Module"
                        name="siteModule"
                        rules={[{ required: 'Site Module required' }]}
                        hasFeedback
                      >
                        <Select
                          placeholder="Select product target"
                          options={productModuleOptions}
                        />
                      </Form.Item>
                    </Col>
                    {/* <Col md={24}>
                      <Form.Item
                        className="mb-2"
                        label="Expiry Date"
                        name="expireAt"
                        help="Expiry date of this coupon"
                      >
                        <DatePicker
                          defaultValue={dayjs().add(1, "month")}
                          placeholder="Expiry Date"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col> */}
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 6 }}>
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Action" key="1">
                    <Form.Item
                      name="status"
                      label="Status"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Status is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select status"
                        options={productStatusOptions}
                      />
                    </Form.Item>
                    <Row justify="end">
                      <Button
                        type="ghost"
                        htmlType="button"
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                      >
                        Back
                      </Button>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Content>
  );
};

export default ProductsAdd;
