import React from 'react';
import loader from '../assets/svg/loader.svg';

const Loader = () => {
  return (
    <div
      id="loader"
      className="loading-container align-items-center justify-content-center"
      style={{ display: 'none' }}
    >
      <img src={loader} className="loader-container" alt="Loading" />
    </div>
  );
};

export default Loader;

export const LoaderSuspense = () => {
  return (
    <div className="loading-container align-items-center justify-content-center">
      <img src={loader} className="loader-container" alt="Loading" />
    </div>
  );
};

export const showLoader = (loading) => {
  let loader = document.getElementById('loader');
  if (loader) {
    if (loader && loading === true) {
      loader.style.display = 'flex';
    } else {
      loader.style.display = 'none';
    }
  }
};
