export const cleanObject = (object) => {
  // return pickBy(object, identity);
  // return _(object).pickBy(_.isObject)
  // .mapValues(cleanObject)
  // .assign(_.omitBy(object, _.isArray))
  // .assign(_.omitBy(object, _.isObject))
  // .omitBy(_.isNil).omitBy(_.isEmpty)
  // .value();
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') {
      cleanObject(v);
    }
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined
    ) {
      if (Array.isArray(object)) {
        object.splice(k, 1);
      } else {
        delete object[k];
      }
    }
  });
  return object;
};
