import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  Button,
  Collapse,
  InputNumber,
  DatePicker,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import DebounceSelect from 'components/DebounceSelect';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');

const { Content } = Layout;

const endpoint = 'coupons';

const CouponAdd = () => {
  const navigate = useNavigate();
  const [discountType, setDiscountType] = useState(null);
  const [userRule, setUserRule] = useState();
  const [productRule, setProductRule] = useState();
  const [value, setValue] = useState([]);
  const [form] = Form.useForm();
  const { addItem, getList } = useBackend();
  const {
    couponDiscountTypeOptions,
    couponUserRuleOptions,
    couponProductRuleOptions,
    couponStatusOptions,
  } = useSelector(selectCMS);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      ...data,
    };
    let config = {
      loader: true,
      msg: { loading: 'Processing...', success: 'Added successfully.' },
      redirectTo: '../' + endpoint,
    };
    console.log(payload);
    addItem(endpoint, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  async function fetchUserList(username) {
    console.log('fetching user', username);
    return getList('users', { email: `${username}%`, limit: 50 }).then(
      (items) =>
        items.items.map((user) => ({
          label: `${user.firstName} (${user.email})`,
          value: user.id,
        })),
    );
  }

  async function fetchProductList(query) {
    console.log('fetching product', query);
    return getList('products', { name: `%${query}%`, limit: 50 }).then((data) =>
      data.items.map((item) => ({
        label: `${item.name} (${item.siteModule})`,
        value: item.id,
      })),
    );
  }

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      <Card title="Add Coupon">
        <Form
          form={form}
          layout="vertical"
          // initialValues={{
          //   ...item,
          // }}
          // size="large"
          // onValuesChange={onRequiredTypeChange}
          // requiredMark={requiredMark}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[20, 20]}>
            <Col sm={{ span: 24 }} md={{ span: 18 }}>
              <Collapse defaultActiveKey="1">
                <Collapse.Panel header="Coupon Details" key="1">
                  <Form.Item
                    label="Code"
                    name="code"
                    rules={[{ required: 'Code required' }]}
                    hasFeedback
                  >
                    <Input placeholder="Coupon Code" />
                  </Form.Item>
                  <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: 'Title required' }]}
                    hasFeedback
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ required: 'Description required' }]}
                    hasFeedback
                  >
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                  <Row gutter={[12, 12]}>
                    <Col sm={12}>
                      <Form.Item
                        label="Discount Type"
                        name="discountType"
                        rules={[{ required: 'Discount Type required' }]}
                        hasFeedback
                      >
                        <Select
                          placeholder="Discount Type"
                          options={couponDiscountTypeOptions}
                          value={discountType}
                          onChange={(value) => {
                            setDiscountType(value);
                            form.resetFields(['entityEnding']);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        className="mb-2"
                        label="Coupon Discount Amount"
                        name="discount"
                        rules={[
                          {
                            required: true,
                            message: 'Coupon Discount Amount is required',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="Coupon Discount Amount"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {discountType === 'Percentage' && (
                      <Col md={24}>
                        <Form.Item
                          className="mb-2"
                          label="Max Discount (Up To)"
                          name="upTo"
                          help={
                            <>
                              only applicable where <b>Discount Type</b> is
                              Percentage
                            </>
                          }
                        >
                          <InputNumber
                            placeholder="Max Discount"
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col sm={12}>
                      <Form.Item
                        label="Coupon Usage Rule"
                        name="userRule"
                        // rules={[{ required: "Discount Type required" }]}
                        hasFeedback
                      >
                        <Select
                          placeholder="How user can use this coupon?"
                          options={couponUserRuleOptions}
                          value={userRule}
                          onChange={(value) => {
                            setUserRule(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <Form.Item
                        className="mb-2"
                        label="Coupon Use Limit"
                        name="limit"
                        help={
                          <>
                            <div>
                              How many times can this coupon can be use?
                            </div>
                            <b> 0 = unlimited, 1 = use only once, 2 = twice</b>
                          </>
                        }
                      >
                        <InputNumber
                          placeholder="Use limit"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {userRule === 'SelectedUsers' && (
                      <Col md={24}>
                        <Form.Item
                          label="Select Users who can use coupon"
                          name="users"
                          help="Type user email to find user."
                          hasFeedback
                        >
                          <DebounceSelect
                            mode="multiple"
                            value={value}
                            placeholder="Select users"
                            fetchOptions={fetchUserList}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col sm={24}>
                      <Form.Item
                        label="Coupon Product Rule"
                        name="productRule"
                        // rules={[{ required: "Discount Type required" }]}
                        hasFeedback
                      >
                        <Select
                          placeholder="Did this coupon only apply to products?"
                          options={couponProductRuleOptions}
                          value={productRule}
                          onChange={(value) => {
                            setProductRule(value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    {productRule === 'SelectedProducts' && (
                      <Col md={24}>
                        <Form.Item
                          label="Select Products to apply coupon"
                          name="products"
                          help="Type product name find."
                          hasFeedback
                        >
                          <DebounceSelect
                            mode="multiple"
                            value={value}
                            placeholder="Type product name..."
                            fetchOptions={fetchProductList}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col md={12}>
                      <Form.Item
                        className="mb-2"
                        label="Over All Use Limit"
                        name="overAllLimit"
                        help={
                          <>
                            <div>Maximum times this coupon can be used?</div>
                            <b> 0 = unlimited, 1 = use only once, 2 = twice</b>
                          </>
                        }
                      >
                        <InputNumber
                          placeholder="Maximum usage limit"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        className="mb-2"
                        label="Expiry Date"
                        name="expireAt"
                        help={
                          <>
                            <div>Expiry date of this coupon</div>
                            <b> After this date this coupon will be expired.</b>
                          </>
                        }
                      >
                        <DatePicker
                          // defaultValue={dayjs().add(1, "month")}
                          placeholder="Expiry Date"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 6 }}>
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Action" key="1">
                    <Form.Item
                      name="status"
                      label="Status"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Status is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select status"
                        options={couponStatusOptions}
                      />
                    </Form.Item>
                    <Row justify="end">
                      <Button
                        type="ghost"
                        htmlType="button"
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                      >
                        Back
                      </Button>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Content>
  );
};

export default CouponAdd;
