import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Button,
  Collapse,
  Select,
  Skeleton,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';

const { Content } = Layout;

const endpoint = 'portfolio/assets';

const Add = () => {
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [item, setItem] = useState(null);
  const [tmp, setTmp] = useState(null);
  const [shareholders, setShareholders] = useState([]);
  const [form] = Form.useForm();
  const {
    businessType,
    btEntityEndings,
    corpEntityEndings,
    familyEntityEndings,
    statuses,
  } = useSelector(selectCMS);
  const { addItem, getItem, getList } = useBackend();

  useEffect(() => {
    getItem('portfolio', portfolioId).then(({ item }) => setItem(item));
    getList('shareholder', { limit: 1000 }).then(({ items }) =>
      setShareholders(
        items.map((item) => ({ value: item.id, label: item.businessName })),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId]);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      portfolioId: portfolioId,
      ...data,
      // filename: fileName,
    };
    let config = {
      loader: true,
      msg: { loading: 'Processing...', success: 'Added successfully.' },
      redirectTo: '../' + endpoint + '/' + portfolioId,
    };
    console.log(payload);
    addItem(endpoint, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  const entityEndingOptions = () => {
    const businessType = form.getFieldValue('businessType');
    switch (businessType) {
      case 'TRUST':
        return btEntityEndings;
      case 'CORP':
        return corpEntityEndings;
      case 'FAMILY':
        return familyEntityEndings;
      default:
        return [{ value: '', label: 'Please select Asset Type first...' }];
    }
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Add Asset in Portfolio">
          <Form
            form={form}
            layout="vertical"
            // size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Asset Details" key="1">
                    <Form.Item label="Selected Portfolio">
                      <Input disabled defaultValue={item.title} />
                    </Form.Item>
                    <Form.Item
                      label="Business Type"
                      name="businessType"
                      rules={[{ required: 'Business Type required' }]}
                      hasFeedback
                    >
                      <Select
                        placeholder="Business Type"
                        options={businessType}
                        value={tmp}
                        onChange={(value) => {
                          setTmp(value);
                          form.resetFields(['entityEnding']);
                        }}
                      />
                    </Form.Item>
                    <Row gutter={12}>
                      <Col sm={12}>
                        <Form.Item
                          label="Business Name"
                          name="businessName"
                          rules={[{ required: 'Business Name required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Business Name" />
                        </Form.Item>
                      </Col>
                      <Col sm={12}>
                        <Form.Item
                          label="Entity Ending"
                          name="entityEnding"
                          rules={[{ required: 'Entity Ending required' }]}
                          hasFeedback
                        >
                          <Select
                            placeholder="Entity Endings"
                            options={entityEndingOptions()}
                            // options={[
                            //   ...btEntityEndings,
                            //   ...familyEntityEndings,
                            //   ...corpEntityEndings,
                            // ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={24}>
                        <Form.Item
                          label="Select Shareholder"
                          name="shareholderId"
                          rules={[{ required: 'Select Shareholder required' }]}
                          hasFeedback
                        >
                          <Select
                            placeholder="Select Shareholder"
                            options={shareholders}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Action" key="1">
                      <Form.Item
                        name="status"
                        label="Status"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Status is required',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select status"
                          options={statuses}
                        />
                      </Form.Item>
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Add
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Content>
  );
};

export default Add;
