import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Layout, Space, Card, Modal, FloatButton } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import dayjs from 'dayjs';
import { usePaginate } from 'backend/usePaginate';
import { useBackend } from 'backend/useBackend';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';
// import DateTimeAgo from 'components/DateTimeAgo';
// import { config } from "config";
// const contractExplorerUrl = config.contractExplorerUrl;

const { Content } = Layout;

const endpoint = 'shareholder';

const Shareholder = () => {
  const { statuses } = useSelector(selectCMS);
  const navigation = useNavigate();
  const { trashItem } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
  });

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.businessName} {item.entityEnding}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Corporate/Business Name',
      dataIndex: 'businessName',
      key: 'businessName',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'businessName'),
      // sortDirections: ['descend']
    },
    {
      title: 'Filing Number',
      dataIndex: 'filingNumber',
      key: 'filingNumber',
    },
    {
      title: 'Share',
      dataIndex: 'share',
      key: 'share',
      render: (share) => <b>{share}%</b>,
    },
    {
      title: 'Registered Date',
      dataIndex: 'regDate',
      key: 'regDate',
      render: (regDate) => dayjs(new Date(regDate)).format('DD/MM/YYYY'),
      // render: (regDate) => <DateTimeAgo date={regDate} />,
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (createdAt) => format(new Date(createdAt), "PPp"),
    // },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Shareholders">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'title',
                label: 'Title',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Status...',
              dbKey: 'status',
              options: statuses,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton
        type="primary"
        tooltip="Add New Shareholder"
        icon={<PlusOutlined />}
        onClick={() => navigation('add')}
      />
    </Content>
  );
};

export default Shareholder;
