const env = process.env.REACT_APP_ENV || 'uat';

const prodEnv = {
  siteName: 'Blockchaintrust - Admin',
  description: 'blockchaintrust.pro',
  apiUrl: process.env.REACT_APP_API_URL || 'https://api.blockchaintrust.pro',
  cdnUrl: 'https://s3.amazonaws.com/cdn.blockchaintrust.pro',
  contractExplorerUrl: 'https://polygonscan.com',
  openSeaUrl: 'https://opensea.io/assets/matic',
  pinataUrl: 'https://gateway.pinata.cloud/ipfs',
  tokenContractAddress: '0x8D4f639220683d192DEAECDDfeC8BD0BD5D88cd9', //enable this in production
  chainId: '0x89', // for metamask validation
  businessContractAddress: '0x222bf22115bc7ecab8f7e5560bfa4369b63a8483',
  // portfolioContractAddress: '0x770413cF97f033bD53D833a7e7870dA2dD610696',  // old
  portfolioContractAddress: '0xf641d9668079134343583c6f599a41bf2330d03d',
  // contractOwner: '0x53bD48B7e789a0831FC454C47554b63788d05F96',
  resellerUrl:
    process.env.REACT_APP_RESELLER_URL ||
    'https://reseller.blockchaintrust.pro',
  adminUrl:
    process.env.REACT_APP_ADMIN_URL || 'https://admin.blockchaintrust.pro',
  env: 'prod',
};
// https://polygonscan.com/token/0xf641d9668079134343583c6f599a41bf2330d03d

const uatEnv = {
  siteName: 'UAT Blockchaintrust - Admin',
  description: 'blockchaintrust.pro',
  // apiUrl: "http://localhost:2500",
  // apiUrl: 'https://dev.blockchaintrust.pro',
  apiUrl: process.env.REACT_APP_API_URL || 'https://uat.blockchaintrust.pro',
  cdnUrl: 'https://s3.amazonaws.com/cdn.blockchaintrust.pro',
  contractExplorerUrl: 'https://amoy.polygonscan.com',
  openSeaUrl: 'https://testnets.opensea.io/assets/amoy',
  pinataUrl: 'https://gateway.pinata.cloud/ipfs',
  tokenContractAddress: '0x761dDE6a88A7f73A2fb45B695Fd14C8598124661',
  chainId: '0x13882', // for metamask validation
  businessContractAddress: '0xe9839fe6548603f080d51529c273c1039a7e6c2e',
  portfolioContractAddress: '0xf90c9cb1197079caa59104081f3a91ba973fcf8f',
  // contractOwner: '0x53bD48B7e789a0831FC454C47554b63788d05F96',
  resellerUrl:
    process.env.REACT_APP_RESELLER_URL ||
    'https://uat-reseller.blockchaintrust.pro',
  adminUrl:
    process.env.REACT_APP_ADMIN_URL || 'https://uat-admin.blockchaintrust.pro',
  env: 'uat',
};

const config = env === 'prod' ? prodEnv : uatEnv;
export { config };
