import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Button,
  Collapse,
  Select,
  Skeleton,
  Upload,
  Table,
} from 'antd';
import {
  DeleteOutlined,
  UploadOutlined,
  ReloadOutlined,
  ArrowLeftOutlined,
  RestOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { parse } from 'csv-parse/browser/esm';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
// import { businessTypeLabel } from "utils/labels";
import { assetsActions, selectAssets } from 'features/cms/assetsSlice';
import { paginationAction } from 'features/pagination/paginationSlice';
import { formatNumber } from 'utils/currency';
// import _ from "lodash";

const { Content } = Layout;

const endpoint = 'portfolio/assets';

const AddBulk = () => {
  const draggerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [item, setItem] = useState(null);
  // const [fileList, setFileList] = useState([]);
  const [tmp, setTmp] = useState(null);
  const [recordCount, setRecordCount] = useState(0);
  const [maxUpload] = useState(100000);
  const [maxSize] = useState(10);
  const [list, setList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [shareholders, setShareholders] = useState([]);
  const [form] = Form.useForm();
  const {
    businessType,
    btEntityEndings,
    corpEntityEndings,
    familyEntityEndings,
    bankEntityEndings,
  } = useSelector(selectCMS);
  const { bulk, duplicate, loaded } = useSelector(selectAssets);
  const { backend, getItem, getList } = useBackend();

  useEffect(() => {
    getItem('portfolio', portfolioId).then(({ item }) => setItem(item));
    getList('shareholder', { limit: 1000 }).then(({ items }) =>
      setShareholders(
        items.map((item) => ({ value: item.id, label: item.businessName })),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId]);

  useEffect(() => {
    if (loaded === true) {
      // message.success({ content: "Record/s prepared", key: "processing", duration: 100 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    return () => {
      dispatch(assetsActions.clearBulkAssets());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (data) => {
    // console.log(data);
    if (recordCount > 0) {
      message.loading({ content: 'Processing', key: 'bulk' });
      setTimeout(() => {
        dispatch(
          assetsActions.loadBulkAssets(
            list.map((item, i) => ({
              ...item,
              ...data,
              // portfolioId: portfolioId,
            })),
          ),
        );
        message.success({
          content: `${recordCount} Record/s are prepared`,
          key: 'bulk',
          duration: 5,
        });
      }, 500);
    } else {
      if (list.length === 0) {
        message.error('Please upload file first with valid records!');
      }
    }
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  const handleValidate = () => {
    let config = {
      loader: true,
    };
    message.loading({ content: 'Validating', key: 'validation' });
    backend()
      .post(`${endpoint}/bulk/validate`, bulk, config)
      .then(({ items, duplicate }) => {
        if (duplicate > 0) {
          message.error({
            content: `${duplicate} duplicate record/s found on database.`,
            key: 'validation',
          });
        } else {
          setValidated(true);
          message.success({
            content: 'Validate successfully...',
            key: 'validation',
          });
          message.success('File records are ready to be upload');
        }
        dispatch(assetsActions.loadBulkAssets(items));
      });
  };

  const handleUpload = () => {
    let config = {
      loader: true,
      // msg: { loading: "Processing...", success: "Added successfully." },
      // redirectTo: "../" + endpoint + "/" + portfolioId,
    };
    console.log(bulk);
    message.loading({ content: 'Creating Bulk Assets', key: 'creation' });
    backend()
      .post(
        `${endpoint}/bulk/create`,
        { bulk, portfolioId: portfolioId },
        config,
      )
      .then(({ items, count }) => {
        setValidated(false);
        message.success({
          content: `${count} record/s created`,
          key: 'creation',
        });
        dispatch(assetsActions.clearBulkAssets());
        dispatch(paginationAction.clear());
        navigate('../' + endpoint + '/' + portfolioId);
      });
  };

  const handleRemoveDuplicate = () => {
    dispatch(assetsActions.removeDuplicate());
    message.success(`${duplicate} Duplicate record/s removed`);
  };

  const handleReset = () => {
    dispatch(assetsActions.clearBulkAssets());
    // setFileList([]);
    setValidated(false);
    // message.success("Record/s reset.");
  };

  const entityEndingOptions = () => {
    const businessType = form.getFieldValue('businessType');
    switch (businessType) {
      case 'TRUST':
        return btEntityEndings;
      case 'CORP':
        return corpEntityEndings;
      case 'FAMILY':
        return familyEntityEndings;
      case 'BANK':
        return bankEntityEndings;
      default:
        return [{ value: '', label: 'Please select Business Type first...' }];
    }
  };

  // const normFile = (e) => {
  //   console.log("Upload event:", e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   if (e?.fileList.length > 0) {
  //     const file = e?.fileList[0];
  //     if (!file) {
  //       // setFileList([]);
  //       alert("File not found!");
  //       return;
  //     }
  //     console.log(`File Name: ${file.name}`);
  //     const fileExt = file.name.split(".").pop();
  //     if (fileExt && fileExt.toLowerCase() !== "csv") {
  //       // setFileList([]);
  //       message.error("This is not CSV File!");
  //       return;
  //     }
  //     parseCsvFile(e?.fileList[0].originFileObj);
  //   }

  //   return e?.fileList;
  // };

  const handleFile = (info) => {
    console.log('Upload event:', info);
    if (info?.fileList.length > 0) {
      const file = info?.file;
      if (!file) {
        // setFileList([]);
        alert('File not found!');
        return;
      }
      console.log(`File Name: ${file.name}`);
      const fileExt = file.name.split('.').pop();
      if (fileExt && fileExt.toLowerCase() !== 'csv') {
        // setFileList([]);
        message.error('This is not CSV File!');
        return;
      }
      parseCsvFile(info?.file);
    }
  };

  const parseCsvFile = (file) => {
    try {
      console.log('parsing csv file', file);
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = (error) => {
        message.error('file reading has failed');
        // console.log('file reading has failed', error)
      };
      reader.onload = async () => {
        // console.log(reader.result);
        if (!reader?.result) {
          return;
        }
        // reader?.result

        const records = parse(reader?.result, {
          autoParse: true,
          trim: true,
          fromLine: 2,
          skipRecordsWithError: true,
          skipRecordsWithEmptyValues: true,
          skipEmptyLines: true,
        });
        var rows = [];
        records.on('data', (row) => {
          // console.log("records", row);
          rows.push(row);
        });
        records.on('error', (error) => {
          console.error('error', error);
          message.error('Error, Something went wrong!');
          throw new Error(error);
        });
        records.on('end', () => {
          if (rows.length === 0) {
            // alert('File is empty!')
            message.info('CSV File is empty!');
            return false;
          }

          const businessList = [];
          if (rows.length - 1 > maxUpload) {
            console.log('total records', rows.length);
            message.info(`You can upload "${maxUpload}" records at a time`);
            return true;
          }
          rows.forEach((col, i) => {
            let duplicate = false;

            // // first attempt to find country by name.
            // const iso2Country = countries.find(
            //   (country) => country.name === col[6]
            // );
            // var country = { name: col[6], iso2: "" };
            // if (iso2Country) {
            //   country = iso2Country;
            // } else {
            //   // second attempt to find country by iso2.
            //   country = countries.find((country) => country.iso2 === col[6]);
            // }
            // if (!country) {
            //   country = { name: col[6], iso2: "--" };
            //   // showWarning(`Invalid Country name "${col[0]}", in row ${i}`);
            //   invalid = true;
            //   invalidAt.push(1);
            //   // return false
            // }
            const existRecord = (businessList || []).find(
              (b) => b.businessName === col[0],
            );
            if (existRecord) {
              existRecord.original = true;
              duplicate = true;
            }
            if (col[0] === '') {
              return false;
            }
            businessList.push({
              // iso2: country.iso2 || "",
              id: i + 1,
              businessName: col[0],
              // email: String(col[0]).toLowerCase().trim(),
              // landLine: col[7],
              // city: col[3],
              // zipCode: col[5],
              // country: col[6],
              // street: col[2],
              // state: col[4],
              shareholderType: 'Corporate',
              // regDate: col[6] ? dayjs(col[6]).format("YYYY-MM-DD") : "",
              // NOTE: below are for only validation file
              original: false,
              duplicate,
            });
            // console.log('membershipIds', membershipIds[iso2])
            return false;
          });
          // console.log(businessList);
          setList(businessList);
          setRecordCount(businessList.length);
          // if (businessList.length > 0) {
          //   if (_.isEmpty(businessList.filter((b) => b.duplicate === true))) {
          //     setValidated(false);
          //   }
          //   dispatch({ type: "set", sidebarShow: false });
          // } else {
          //   dispatch({ type: "set", sidebarShow: true });
          // }
        });
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      console.log('catch error', e);
    }
  };

  const columns = [
    {
      title: 'Sr.',
      dataIndex: 'id',
      key: 'id',
      // render: (country) => countryNameByIso2(country),
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      key: 'businessName',
      // filters: {},
      // sorter: true,
      // sorter: (a, b) => sorter(a, b, "businessName"),
      // sortDirections: ['descend'],
      render: (businessName, row) => (
        <div className={row?.exist ? 'text-red-600' : 'text-gray-600'}>
          {/* <b>{row?.filingNumber}</b> */}
          {/* <br /> */}
          <b>{businessName}</b>
          {/* <br />
          <small>
            <b>{businessTypeLabel(row?.businessType)}</b>
          </small>
          <br />
          <small>
            Shareholder Type: {row?.shareholderType && row?.shareholderType}
          </small> */}
        </div>
      ),
    },
    {
      title: 'Entity Endings',
      dataIndex: 'entityEnding',
      key: 'entityEnding',
    },
    // {
    //   title: "Shareholder",
    //   dataIndex: "shareholder",
    //   key: "shareholder",
    // },
    // {
    //   title: "Contact Details",
    //   dataIndex: "email",
    //   key: "email",
    //   render: (email, row) => (
    //     <div>
    //       <b>{email}</b>
    //       <br />
    //       <small>
    //         <b>{row?.landLine}</b>
    //       </small>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Address Details",
    //   dataIndex: "country",
    //   key: "country",
    //   render: (country, row) => (
    //     <div>
    //       <small>
    //         {row?.street}, {row.city} {" - "}
    //         {row.zipCode}
    //         <br />
    //         {row.state}
    //         <br />
    //       </small>
    //       <b>{country}</b>
    //     </div>
    //   ),
    // },
    {
      title: 'Filing Number',
      dataIndex: 'id',
      key: 'id',
      render: () => 'Will be generated on server',
    },
    {
      title: 'BICRAP',
      dataIndex: 'id',
      key: 'id',
      render: () => 'Will be generated on server',
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          {row.duplicate && (
            <DeleteOutlined
              className="text-red-600"
              onClick={() => dispatch(assetsActions.removeAssetById(id))}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Content>
        {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
        {!item?.id ? (
          <Card title="Loading">
            <Skeleton />
          </Card>
        ) : bulk.length === 0 ? (
          <Card title="Add Asset in Portfolio">
            <Form
              form={form}
              layout="vertical"
              // size="large"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={[20, 20]}>
                <Col sm={{ span: 24 }} md={{ span: 18 }}>
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Asset Details" key="1">
                      <Form.Item label="Selected Portfolio">
                        <Input disabled defaultValue={item.title} />
                      </Form.Item>
                      <Row gutter={12}>
                        <Col sm={12}>
                          <Form.Item
                            label="Business Type"
                            name="businessType"
                            rules={[{ required: 'Business Type required' }]}
                            hasFeedback
                          >
                            <Select
                              placeholder="Business Type"
                              options={businessType}
                              value={tmp}
                              onChange={(value) => {
                                setTmp(value);
                                form.resetFields(['entityEnding']);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={12}>
                          <Form.Item
                            label="Entity Ending"
                            name="entityEnding"
                            rules={[{ required: 'Entity Ending required' }]}
                            hasFeedback
                          >
                            <Select
                              placeholder="Entity Endings"
                              options={entityEndingOptions()}
                              // options={[
                              //   ...btEntityEndings,
                              //   ...familyEntityEndings,
                              //   ...corpEntityEndings,
                              // ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={24}>
                          <Form.Item
                            label="Select Shareholder"
                            name="shareholderId"
                            rules={[
                              { required: 'Select Shareholder required' },
                            ]}
                            hasFeedback
                          >
                            <Select
                              placeholder="Select Shareholder"
                              options={shareholders}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm={24}>
                          <Form.Item
                            // name="file"
                            label="CSV File"
                            // valuePropName="fileList"
                            // getValueFromEvent={normFile}
                            // noStyle
                          >
                            <Upload.Dragger
                              ref={draggerRef}
                              name="files"
                              accept=".csv"
                              beforeUpload={(file) => {
                                // setFileList(file);
                                return false;
                              }}
                              maxCount={1}
                              onChange={handleFile}
                            >
                              <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                              </p>
                              <p className="ant-upload-text">
                                Click or drag file to this area to upload
                              </p>
                              <small className="pb-2">
                                Accept only CSV file with .csv extension
                              </small>
                              <br />
                              <small>
                                Maximum File size <b>{maxSize}MB</b> Allowed
                              </small>
                              <br />
                              <small className="text-red-500 font-bold">
                                Maximum {formatNumber(maxUpload)} records per
                                File are Allowed.
                              </small>
                              <br />
                              {/* <small className="text-green-600 font-bold">
                                1000 records per File gives best performance.
                              </small> */}
                              {recordCount > 0 && (
                                <p className="ant-upload-text text-lg font-bold">
                                  {recordCount} records found
                                </p>
                              )}
                            </Upload.Dragger>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Col>
                <Col sm={{ span: 24 }} md={{ span: 6 }}>
                  <Space
                    direction="vertical"
                    style={{ width: '100%' }}
                    size="large"
                  >
                    <Collapse defaultActiveKey="1">
                      <Collapse.Panel header="Action" key="1">
                        <Row justify="end">
                          <Button
                            type="ghost"
                            htmlType="button"
                            onClick={() => navigate(-1)}
                            icon={<ArrowLeftOutlined />}
                          >
                            Back
                          </Button>
                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Next
                            </Button>
                          </Form.Item>
                        </Row>
                      </Collapse.Panel>
                    </Collapse>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Card>
        ) : (
          <Row>
            <Col md={24}>
              <Table
                rowKey="id"
                // bordered
                tableLayout="auto"
                dataSource={bulk}
                columns={columns}
                rowClassName={(record) =>
                  record.duplicate === true
                    ? 'bg-red-200'
                    : // : record.original === true
                      // ? "bg-green-200"
                      null
                }
                // onRow={(data, i) => {
                //   console.log("data", i, data);
                // }}
                pagination={false}
              />
            </Col>
          </Row>
        )}
      </Content>
      {bulk.length > 0 && (
        <div className="sticky bottom-0 p-4 bg-gray-100">
          <Row>
            <Col md={12}>
              <Space>
                <Button
                  type="ghost"
                  className="btn-danger"
                  htmlType="button"
                  onClick={handleReset}
                  icon={<ReloadOutlined />}
                >
                  Reset
                </Button>
                <Button
                  type="ghost"
                  className="btn-warning"
                  htmlType="button"
                  disabled={duplicate === 0}
                  size={duplicate > 0 && 'large'}
                  onClick={handleRemoveDuplicate}
                  icon={<RestOutlined />}
                >
                  Remove Duplicate
                </Button>
              </Space>
              <br />
              <Space className="mt-2">
                <b>Total Record/s {bulk.length}</b>
                <b className="text-red-500">Duplicate count {duplicate}</b>
              </Space>
            </Col>
            <Col className="text-right" md={12}>
              <Space>
                <Button
                  type="ghost"
                  className="btn-primary"
                  disabled={duplicate > 0}
                  size={duplicate === 0 && validated === false && 'large'}
                  htmlType="button"
                  onClick={handleValidate}
                  icon={<CloudSyncOutlined />}
                >
                  Validate in Database
                </Button>
                <Button
                  type="ghost"
                  disabled={!validated}
                  className="btn-secondary"
                  htmlType="button"
                  size={validated === true && 'large'}
                  onClick={handleUpload}
                  icon={<CloudUploadOutlined />}
                >
                  Upload Assets
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AddBulk;
