import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  Button,
  Collapse,
  Skeleton,
  DatePicker,
  InputNumber,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import dayjs from 'dayjs';

const { Content } = Layout;

const endpoint = 'shareholder';

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const { updateItem, getItem } = useBackend();
  const { countriesOptions } = useSelector(selectCMS);

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      // title: data.title,
      ...data,
      // filename: fileName,
    };
    let config = {
      loader: true,
      msg: { loading: 'Updating...', success: 'Updated successfully.' },
      redirectTo: '../shareholders',
    };
    console.log(payload);
    updateItem(endpoint, id, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Edit Shareholder">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...item,
              regDate: dayjs(item.regDate),
            }}
            // size="large"
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Shareholder Details" key="1">
                    <Row gutter={20}>
                      <Col md={12}>
                        <Form.Item
                          label="Corporate/Business Name"
                          // tooltip="Shareholder Title required"
                          name="businessName"
                          rules={[
                            { required: 'Corporate/Business Name required' },
                          ]}
                          hasFeedback
                        >
                          <Input placeholder="Corporate/Business Name" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Filing Number"
                          name="filingNumber"
                          rules={[{ required: 'Filing Number required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Filing Number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Share"
                      name="share"
                      rules={[{ required: 'Share percentage required' }]}
                      hasFeedback
                    >
                      <InputNumber
                        placeholder="Share"
                        suffix={'%'}
                        className="w-full"
                        min={1}
                        max={100}
                      />
                    </Form.Item>
                    <Row gutter={[20, 20]}>
                      <Col md={24}>
                        <Form.Item
                          label="Street"
                          name="street"
                          rules={[{ required: 'Street required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Street" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[{ required: 'City required' }]}
                          hasFeedback
                        >
                          <Input placeholder="City" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[{ required: 'State required' }]}
                          hasFeedback
                        >
                          <Input placeholder="State" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Zip Code"
                          name="zipCode"
                          rules={[{ required: 'Zip Code required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Zip Code" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          name="country"
                          label="Country"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Country is required',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Please select country"
                            options={countriesOptions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Business Register Date"
                      name="regDate"
                      rules={[{ required: 'Business Register Date required' }]}
                      hasFeedback
                    >
                      <DatePicker
                        placeholder="Business Register Date"
                        className="w-full"
                      />
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Action" key="1">
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Update
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Content>
  );
};

export default Edit;
