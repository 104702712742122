import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  Button,
  Collapse,
  Skeleton,
  DatePicker,
  InputNumber,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import dayjs from 'dayjs';
var localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const { Content } = Layout;

const endpoint = 'shareholder-business';

const BusinessShareholderIndividualEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const { updateItem, getItem } = useBackend();
  const { countriesOptions, maritalStatus } = useSelector(selectCMS);

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      // title: data.title,
      ...data,
      // filename: fileName,
    };
    let config = {
      loader: true,
      msg: { loading: 'Updating...', success: 'Updated successfully.' },
      redirectTo: '../business-shareholders',
    };
    console.log(payload);
    updateItem(endpoint, id, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Edit Individual Shareholder">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...item,
              dob: dayjs(item.dob),
            }}
            // size="large"
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Shareholder Details" key="1">
                    <Row gutter={20}>
                      <Col md={12}>
                        <Form.Item
                          label="First Name"
                          // tooltip="Shareholder Title required"
                          name="firstName"
                          rules={[{ required: 'First Name required' }]}
                          hasFeedback
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Last Name"
                          // tooltip="Shareholder Title required"
                          name="lastName"
                          rules={[{ required: 'Last Name required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Email"
                          // tooltip="Shareholder Title required"
                          name="email"
                          rules={[{ required: 'Email required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Email" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Mobile"
                          // tooltip="Shareholder Title required"
                          name="mobile"
                          rules={[{ required: 'Mobile required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Mobile" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Passport Number"
                          name="passportNumber"
                          rules={[{ required: 'Passport Number required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Passport Number" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Date of Birth"
                          name="dob"
                          rules={[{ required: 'Date of Birth required' }]}
                          hasFeedback
                        >
                          <DatePicker
                            placeholder="Date of Birth"
                            className="w-full"
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          name="maritalStatus"
                          label="Marital Status"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Marital Status" required',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Please select marital status"
                            options={maritalStatus}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Share"
                          name="share"
                          rules={[{ required: 'Share percentage required' }]}
                          hasFeedback
                        >
                          <InputNumber
                            placeholder="Share"
                            suffix={'%'}
                            className="w-full"
                            min={1}
                            max={100}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                      <Col md={24}>
                        <div className="text-lg font-bold">Address</div>
                        {/* <Form.Item
                          label="Street"
                          name="street"
                          rules={[{ required: 'Street required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Street" />
                        </Form.Item> */}
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[{ required: 'City required' }]}
                          hasFeedback
                        >
                          <Input placeholder="City" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[{ required: 'State required' }]}
                          hasFeedback
                        >
                          <Input placeholder="State" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label="Zip Code"
                          name="zipCode"
                          rules={[{ required: 'Zip Code required' }]}
                          hasFeedback
                        >
                          <Input placeholder="Zip Code" />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          name="country"
                          label="Country"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Country is required',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '')
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Please select country"
                            options={countriesOptions}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Action" key="1">
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Update
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Content>
  );
};

export default BusinessShareholderIndividualEdit;
