import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Table, Layout, Space, Card, Badge, Modal, FloatButton } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  UpCircleOutlined,
  DiffOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { usePaginate } from 'backend/usePaginate';
import { useBackend } from 'backend/useBackend';
import { getBadgeColor } from 'utils/labels';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';
import { useBusiness } from 'hooks/useBusiness';
import { config } from 'config';
import { selectAssetActiveCertificates } from 'features/cms/assetsCertificateSlice';
import DateTimeAgo from 'components/DateTimeAgo';
// import { countryNameByIso2 } from "data/countries";
// import { config } from "config";
// const contractExplorerUrl = config.contractExplorerUrl;

const { Content } = Layout;
const apiUrl = config.apiUrl;

const endpoint = 'portfolio/assets';

const PortfolioAssets = () => {
  const navigation = useNavigate();
  const { portfolioId } = useParams();
  const { businessType } = useSelector(selectCMS);
  const activeCertificates = useSelector(selectAssetActiveCertificates);
  const { businessTypeLabel } = useBusiness();

  const { trashItem } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    portfolioId: portfolioId,
    orderBy: 'filingNumber',
    order: 'desc',
    limit: 100,
  });

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };

    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.businessName} {item.entityEnding}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const downloadModal = (item) => {
    let totalCertificateCount = activeCertificates?.filter(
      (c) =>
        c.certificateType === item.businessType && c.entityEnding === 'ALL',
    ).length;
    totalCertificateCount += activeCertificates?.filter(
      (c) =>
        c.certificateType === item.businessType &&
        c.entityEnding === item.entityEnding,
    ).length;
    Modal.info({
      icon: <CloudDownloadOutlined />,
      title: 'Download Certificates',
      content: (
        <div className="max-w-6xl">
          {/* <pre>{JSON.stringify(activeCertificates, null, 2)}</pre> */}
          {totalCertificateCount > 0 ? (
            <>
              <div>
                {activeCertificates
                  .filter(
                    (c) =>
                      c.certificateType === item.businessType &&
                      c.entityEnding === 'ALL',
                  )
                  .map((certificate) => (
                    <div className="mx-6 w-full">
                      <a
                        href={`${apiUrl}/assets-certificate/download/${certificate.id}/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        {certificate?.title}
                      </a>
                    </div>
                  ))}
                {activeCertificates
                  .filter(
                    (c) =>
                      c.certificateType === item.businessType &&
                      c.entityEnding === item.entityEnding,
                  )
                  .map((certificate) => (
                    <div className="mx-6 w-full">
                      <a
                        href={`${apiUrl}/assets-certificate/download/${certificate.id}/${item?.id}`}
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                      >
                        {certificate?.title}
                      </a>
                    </div>
                  ))}
              </div>
              <div className="right">
                <b>
                  <small className="text-danger">
                    Click on certificate link to download
                  </small>
                </b>
              </div>
            </>
          ) : (
            <>
              <h4>No certificate available to download.</h4>
              <div className="right">
                <b>
                  <small className="text-danger">
                    Please upload/update certificate for this type of entity
                  </small>
                </b>
              </div>
            </>
          )}
        </div>
      ),
      centered: true,
      width: '60%',
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      footer: null,
    });
  };

  const columns = [
    // {
    //   title: <Checkbox />,
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id) => <Checkbox />,
    // },
    {
      title: '#',
      dataIndex: 'filingNumber',
      key: 'filingNumber',
      render: (filingNumber) => filingNumber,
    },
    {
      title: 'Business Details',
      dataIndex: 'businessName',
      key: 'businessName',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'businessName'),
      // sortDirections: ['descend']
      render: (businessName, row) => (
        <div>
          {/* <b>{row?.filingNumber}</b> */}
          {/* <br /> */}
          <b>
            <span className="text-gray-600">{businessName}</span>{' '}
            <span className="">{row?.entityEnding}</span>
          </b>
          <br />
          <small className="text-cyan-400">
            <b>{businessTypeLabel(row?.businessType)}</b>
          </small>
          <br />
          {/* <small className="text-gray-600">Registered At: {format(new Date(row?.createdAt), 'PPp')}</small><br /> */}
          {/* <small className="text-gray-600">
            <b>{row?.bicraFormatted}</b>
          </small>
          <br /> */}
          {/* {row?.filingNumberFormatted && (
            <>
              <small className="text-gray-600">
                <b>Filing Number: {row?.filingNumberFormatted}</b>
              </small>
              <br />
            </>
          )}
          <small className="text-gray-600">
            {row?.shareholderType && row?.shareholderType + " Shareholder"}
          </small> */}
        </div>
      ),
    },
    // {
    //   title: "Business Residency",
    //   dataIndex: "country",
    //   key: "country",
    //   render: (country) => countryNameByIso2(country),
    // },
    {
      title: 'Shareholder',
      dataIndex: 'id',
      key: 'shareholder',
      render: (id, row) => row?.shareholder?.businessName ?? 'N/A',
    },
    {
      title: 'Filing Number',
      dataIndex: 'filingNumberFormatted',
      key: 'filingNumberFormatted',
      render: (filingNumberFormatted) =>
        filingNumberFormatted || 'Generating In Backend...',
    },
    {
      title: 'BICRAP',
      dataIndex: 'bicraFormatted',
      key: 'bicraFormatted',
      render: (bicraFormatted) => bicraFormatted || 'Generating In Backend...',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <DateTimeAgo date={createdAt} />,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle">
          <CloudDownloadOutlined
            className="text-cyan-700"
            onClick={() => downloadModal(row)}
          />
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Portfolio Assets">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'businessName',
                label: 'Business Name',
              },
              {
                key: 'bicraFormatted',
                label: 'BICRAP',
              },
              {
                key: 'filingNumberFormatted',
                label: 'Filing Number',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Business Type',
              dbKey: 'businessType',
              options: businessType,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton.Group
        trigger="hover"
        type="primary"
        // style={{ right: 94 }}
        icon={<UpCircleOutlined />}
      >
        <FloatButton
          // type="primary"
          tooltip="Add Bulk Asset"
          icon={<DiffOutlined />}
          onClick={() => navigation(`addBulk`)}
        />
        <FloatButton
          // type="primary"
          tooltip="Add New Asset"
          icon={<PlusOutlined />}
          onClick={() => navigation(`add`)}
        />
      </FloatButton.Group>
    </Content>
  );
};

export default PortfolioAssets;
