import React from 'react';
import { Table, Layout, Space, Card, Badge, Modal } from 'antd';
import {
  // MoreOutlined,
  DollarOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoginOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { useBackend } from 'backend/useBackend';
import { usePaginate } from 'backend/usePaginate';
import { getBadgeColor } from 'utils/labels';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { useSelector } from 'react-redux';
import { selectCMS } from 'features/cms/cmsSlice';
import { paymentStatus } from 'utils/constants';
import DateTimeAgo from 'components/DateTimeAgo';
import { useAuth } from 'backend/useAuth';
import { selectAuth } from 'features/auth/authSlice';
import { config } from 'config';

const { Content } = Layout;
const endpoint = 'users';

const Users = () => {
  const { paymentStatusOptions } = useSelector(selectCMS);
  const { user } = useSelector(selectAuth);
  const { addItem, trashItem } = useBackend();
  const { loginGenerateToken } = useAuth();

  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
  });

  const makeAgentPayment = (id) => {
    let title = 'Registered Agent ',
      sub = 'new',
      agentSignupAmount = 0;
    title += sub === 'new' ? 'Fees' : 'Renewal Fees';
    title += ' By Admin';
    const payload = {
      title: title,
      // items: [id], // For agent no need to pass items
      items: [
        {
          // id: id,
          name: title,
          description: title,
          amount: agentSignupAmount,
        },
      ],
      type: 'User',
      gateway: 'Admin Payment',
      amount: agentSignupAmount,
      currency: 'USD',
      subscription: sub === 'new' ? 'new' : 'renew',
    };
    addItem(`order/admin-agent/${id}`, payload, {
      loader: true,
      msg: { loading: 'Making Payment...', success: 'Payment Success.' },
    }).then(() => {
      filter({});
    });
  };

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.firstName} {item.lastName}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const loginAsUser = (item) => {
    loginGenerateToken({ loginAs: item.email }).then((data) => {
      console.log('data', data);
      const adminLoginUrl = `${config.resellerUrl}/auth/admin-login/${data.tokenId}`;
      // console.log(adminLoginUrl);
      window.open(adminLoginUrl, '_self');
      // window.location.replace(
      //   `http://localhost:3001/auth/admin-login/${data.tokenId}`,
      // );
    });
  };

  const columns = [
    {
      title: 'User Details',
      dataIndex: 'email',
      key: 'email',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'email'),
      // sortDirections: ['descend']
      render: (email, row) => (
        <div>
          <div>
            {row.email !== user.email && (
              <button
                className="p-0 cursor-pointer mr-1"
                onClick={() => loginAsUser(row)}
              >
                <LoginOutlined
                  className="text-red-700 font-bold"
                  title={`Login as ${row.email}`}
                />
              </button>
            )}
            <b>{email}</b>
          </div>
          <div className="text-xs">
            {row.firstName} {row.lastName}
          </div>
          <small className="text-muted font-semibold">
            Registered At: <DateTimeAgo date={row?.createdAt} onlyDate />
          </small>
        </div>
      ),
    },
    {
      title: 'Roles',
      dataIndex: 'rolesString',
      key: 'rolesString',
      // render: (rolesString) => rolesString?.length > 0 && roles.join(", "),
    },
    {
      title: 'Counts',
      dataIndex: '_count',
      key: '_count',
      render: (_count) => (
        <div>
          <div className="font-bold">Business: {_count?.business}</div>
          <div className="font-bold">Order: {_count?.orders}</div>
          {/* {JSON.stringify(_count, null, 2)} */}
        </div>
      ),
    },
    {
      title: 'Agent Payment Status',
      dataIndex: 'agentStatus',
      key: 'agentStatus',
      render: (agentStatus) => (
        <Badge color={getBadgeColor(agentStatus)} count={agentStatus} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Joined At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => <DateTimeAgo date={createdAt} />,
    },
    // {
    //   title: "Register Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (createdAt, row) =>
    //     format(new Date(createdAt), "PPp"),
    // },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          {row.email !== user.email && (
            <Link
              to={{
                pathname: `password/${id}`,
              }}
            >
              <KeyOutlined className="text-red-700" title="Password" />
            </Link>
          )}
          {/* <Dropdown
            menu={{
              items: [
                {
                  key: "item-1",
                  icon: <DollarOutlined />,
                  label: (
                    <Link
                      to="#"
                      className="p-0"
                      onClick={() => makeAgentPayment(row?.id)}
                    >
                      Make Agent Payment
                    </Link>
                  ),
                },
                {
                  key: "item-1",
                  icon: <EyeOutlined className="text-blue-700" title="View" />,
                  label: (
                    <Link
                      to={{
                        pathname: `view/${id}`,
                      }}
                    >
                      View
                    </Link>
                  ),
                },
              ],
            }}
          >
            <MoreOutlined className="p-2 cursor-pointer" />
          </Dropdown> */}
          {row.agentStatus !== paymentStatus.Confirmed && (
            <Link
              to="#"
              className="p-0"
              onClick={() => makeAgentPayment(row?.id)}
            >
              <DollarOutlined
                className="text-dark"
                title="Make Agent Payment"
              />
            </Link>
          )}
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          {/* <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link> */}
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Users">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'email',
                label: 'Email',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Agent Payment Status',
              dbKey: 'agentStatus',
              options: paymentStatusOptions,
            },
          ]}
          dateRangeControl
          handleFilter={filter}
        />

        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
    </Content>
  );
};

export default Users;
