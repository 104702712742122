import React from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  paginationAction,
  selectPagination,
} from 'features/pagination/paginationSlice';

const SelectControl = ({ dbKey, options, label, placeholder = 'Select' }) => {
  const dispatch = useDispatch();
  const { filters } = useSelector(selectPagination);

  const handleChange = (values) => {
    console.info(values);
    dispatch(paginationAction.setFilter({ [dbKey]: values }));
  };

  // console.log("SelectPicker", options, filters);

  return (
    <label>
      {label && <div className="mb-2">{label}</div>}
      <Select
        className="w-full md:w-40"
        value={filters && filters[dbKey]}
        options={options}
        onChange={handleChange}
        placeholder={placeholder}
        // allowClear
      />
    </label>
  );
};

export default SelectControl;
