import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Row, Space } from 'antd';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import SearchControl from './Controls/SearchControl';
import SelectControl from './Controls/SelectControl';
import DateRangeControl from './Controls/DateRangeControl';
import {
  paginationAction,
  selectPagination,
} from 'features/pagination/paginationSlice';

const FilterPanel = ({
  handleFilter,
  searchControl,
  dateRangeControl = false,
  selectControl = [],
}) => {
  const dispatch = useDispatch();
  const { loading, total, filters } = useSelector(selectPagination);

  console.log('FilterPanel', filters);

  return (
    <>
      <Row gutter={[10, 10]} className="mb-3">
        <Col span={24} md={12}>
          <Space align="baseline" wrap>
            <SearchControl {...searchControl} handleFilter={handleFilter} />
            {selectControl.length > 0 &&
              selectControl.map((pickerOption, i) => (
                <SelectControl {...pickerOption} key={i} />
              ))}
          </Space>
        </Col>
        <Col className="flex justify-end" span={24} md={12}>
          <Space align="baseline" wrap className="justify-end">
            {dateRangeControl && <DateRangeControl />}
            <Button
              htmlType="button"
              type="primary"
              icon={<FilterOutlined />}
              loading={loading}
              onClick={() => handleFilter(filters)}
              className="d-block w-full"
            >
              Filter
            </Button>
            <Button
              htmlType="button"
              type="dashed"
              icon={<CloseOutlined />}
              onClick={() => {
                dispatch(paginationAction.clearFilter());
                handleFilter({});
              }}
            >
              Clear
            </Button>
          </Space>
        </Col>
      </Row>
      <Row justify="start" className="mb-4">
        {loading ? (
          <span>Loading...</span>
        ) : total > 0 ? (
          <span>
            Total <b>{total}</b> record/s found
          </span>
        ) : (
          <span>No records found</span>
        )}
      </Row>
    </>
  );
};

FilterPanel.propTypes = {
  searchControl: PropTypes.any,
  dateRangeControl: PropTypes.any,
  selectControl: PropTypes.any,
  handleFilter: PropTypes.func,
};

export default FilterPanel;
