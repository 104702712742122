import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  // Checkbox,
  Select,
  Button,
  Collapse,
  Upload,
  Skeleton,
} from 'antd';
import {
  InboxOutlined,
  ArrowLeftOutlined,
  FileWordOutlined,
} from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import { assetCertificatesActions } from 'features/cms/assetsCertificateSlice';

const { Content } = Layout;

const endpoint = 'assets-certificate';

const Edit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const { baseURL, getToken, deleteS3File, updateItem, getItem } = useBackend();
  const [maxSize] = useState(50);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [certificateType, setCertificateType] = useState(null);
  // const [entityEnding, setEntityEnding] = useState("");
  const [s3Path, setS3Path] = useState('');
  const {
    certificateStatus,
    businessType,
    // shareholderCount,
    corpEntityEndings,
    btEntityEndings,
    familyEntityEndings,
    bankEntityEndings,
  } = useSelector(selectCMS);

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => {
      setItem(item);
      if (item.s3Path) {
        setFileName(item.fileName);
        setS3Path(item.s3Path);
        setCertificateType(item.certificateType);
        // setEntityEnding(item.entityEnding);
        setFileList([
          {
            id: '-1',
            name: item.fileName,
            key: item.s3Path,
          },
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = ({ file, ...data }) => {
    console.log(data);
    const payload = {
      // title: data.title,
      ...data,
      fileName: fileName,
      s3Path: s3Path,
    };
    let config = {
      loader: true,
      msg: { loading: 'Updating...', success: 'Updated successfully.' },
      redirectTo: '../assets/certificates',
    };
    console.log(payload);
    updateItem(endpoint, id, payload, config).then(({ item }) => {
      dispatch(assetCertificatesActions.updateById(item));
    });
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  const validation = (file) => {
    console.info(file.type);
    const isValidFile =
      file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isValidFile) {
      setFileList([]);
      message.error('You can only upload docx file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      setFileList([]);
      message.error('File must be smaller than 50MB!');
      return false;
    }
    return true;
  };

  const handleChange = async (info) => {
    console.log('handleChange', info);
    console.log('info?.file?.status', info?.file?.status);

    if (info?.file?.status === undefined) {
      message.error('File not allowed');
      return;
    }

    if (info?.file?.status === 'done') {
      // Get this url from response in real world.
      message.success({ content: 'File Uploaded', key: 'upload' });
      console.log(info.file.response);
      if (info.file?.response?.key) {
        setS3Path(info.file?.response?.key);
        setFileName(info.file?.response?.name);
      }
    }
    if (info?.file?.status === 'removed') {
      // updateItem(endpoint, state?.id, { goalThumb: "" }, { loading: true });
      if (info?.file?.response?.key) {
        deleteS3File(info?.file?.response?.key);
      } else if (info?.file?.key) {
        deleteS3File(info?.file?.key);
      }
      message.success('File Removed');
    }
    setFileList(info.fileList);
    if (info.file.status === 'error') {
      // setFileList([])
      message.error({ content: info?.file?.response?.message, key: 'upload' });
      // setLoading(true);
    }
    if (info.file.status === 'uploading') {
      message.loading({ content: 'Uploading...', key: 'upload' });
      // setLoading(true);
    }
  };

  const entityEndingVisible = () => {
    // const certificateType = form.getFieldValue("certificateType");
    switch (certificateType) {
      case 'CORP':
        return true;
      case 'BANK':
        return false;
      case 'TRUST':
        return true;
      case 'FAMILY':
        return false;
      default:
        return false;
    }
  };

  const entityEndingOptions = () => {
    switch (certificateType) {
      case 'TRUST':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...btEntityEndings,
        ];
      case 'CORP':
        return [
          { value: '', label: 'Select Entity Ending' },
          { value: 'ALL', label: 'ALL' },
          ...corpEntityEndings,
        ];
      case 'FAMILY':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...familyEntityEndings,
        ];
      case 'BANK':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...bankEntityEndings,
        ];
      default:
        return [{ value: '', label: 'Please select Business Type first...' }];
    }
  };

  // const getEntityEndingsByCertificateType = (value) => {
  //   switch (value) {
  //     case "TRUST":
  //       return 'BT';
  //     case "CORP":
  //       return "ALL";
  //     case "FAMILY":
  //       return "";
  //     case "BANK":
  //       return "";
  //     default:
  //       return "";
  //   }
  // };

  // console.log("fileList", fileList);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Edit Certificate">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...item,
              // entityEnding,
            }}
            // size="large"
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Certificate Details" key="1">
                    <Form.Item
                      label="Certificate Title"
                      // tooltip="Certificate Title required"
                      name="title"
                      rules={[{ required: 'Certificate Title required' }]}
                      hasFeedback
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                    {/* <Form.Item
                      name="category"
                      label="For Shareholder Category"
                      hasFeedback
                    >
                      <Checkbox.Group>
                        <Row>
                          <Col>
                            <Checkbox
                              value="Individual"
                              style={{
                                lineHeight: "32px",
                              }}
                            >
                              Individual
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              value="Corporate"
                              style={{
                                lineHeight: "32px",
                              }}
                            >
                              Corporate
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item> */}
                    {/* <Form.Item
                      name="shareholderCount"
                      label="For Number of Shareholder"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please Select Number of Shareholder!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Number of Shareholder"
                        options={shareholderCount}
                      />
                    </Form.Item> */}
                    <Form.Item
                      name="certificateType"
                      label="Certificate Type"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Certificate type!',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Certificate Type"
                        options={businessType}
                        value={certificateType}
                        onChange={(value, option) => {
                          console.log('option', option);
                          setCertificateType(value);
                          // form.resetFields(["entityEnding"]);
                          form.setFieldValue(
                            'entityEnding',
                            '', // getEntityEndingsByCertificateType(value)
                          );
                          // form.setFieldValue("entityEnding", {
                          //   value: "",
                          //   label: "Select Entity Ending",
                          // });
                        }}
                      />
                    </Form.Item>
                    {entityEndingVisible() && (
                      <Form.Item
                        key={certificateType}
                        name="entityEnding"
                        label="Entity Ending"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please Select Entity Ending!',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Entity Ending"
                          options={entityEndingOptions()}
                          // value={entityEnding}
                          // onChange={(value) => {
                          //   setEntityEnding(value);
                          // }}
                        />
                      </Form.Item>
                    )}
                    <Form.Item name="file" label="Document File">
                      <Upload.Dragger
                        accept=".docx"
                        method="post"
                        name="file"
                        listType="picture"
                        // className="avatar-uploader"
                        data={{ folder: 'assets-certificate' }}
                        fileList={fileList}
                        showUploadList={true}
                        action={`${baseURL}/assets-certificate/upload`}
                        headers={{ Authorization: `Bearer ${getToken()}` }}
                        maxCount={1}
                        beforeUpload={validation}
                        onChange={handleChange}
                        iconRender={(file) => {
                          return <FileWordOutlined />;
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <small className="pb-2">
                          Accept only Document file with .docx extension
                        </small>
                        <br />
                        <small>
                          Maximum File size <b>{maxSize}MB</b> Allowed
                        </small>
                      </Upload.Dragger>
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Publish" key="1">
                      <Form.Item
                        name="status"
                        label="Status"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Status is required',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select status"
                          options={certificateStatus}
                        />
                      </Form.Item>
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Content>
  );
};

export default Edit;
