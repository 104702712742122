import axios from 'axios';
import { message } from 'antd';
import { useCookie, useLocalStorage } from 'react-use';
import { useNavigate } from 'react-router';
import { showLoader } from 'utils/loader';
import { config } from 'config';
import { useDispatch } from 'react-redux';
import { paginationAction } from 'features/pagination/paginationSlice';
import { handleError, isEmpty } from 'utils/validation';

const baseURL = config.apiUrl;
const tokenName = process.env.REACT_APP_TOKEN_NAME || 'adminAuthToken';

const extractErrorMsg = (error) => {
  console.log('error', error.message);
  console.log('response', error.response);

  if (error?.response) {
    if (error?.response?.data) {
      if (error?.response?.data?.errors) {
        return error?.response?.data?.errors.join(' \n');
      }
      if (error?.response?.data?.message?.message) {
        return error?.response?.data?.message?.message;
      }
      // this is for array of error messages
      if (Array.isArray(error?.response?.data?.message)) {
        return error?.response?.data?.message[0];
      }
      return error?.response?.data?.message;
    }
  } else if (error?.message) {
    return error?.message;
    // if (typeof error?.message == "object") return error?.message;
  } else {
    return error?.response?.statusText || 'Network error';
  }
};

export const useBackend = () => {
  // const [messageApi, contextHolder] = message.useMessage();
  const [tokenC, updateTokenC, deleteTokenC] = useCookie(tokenName);
  const [tokenL, updateTokenL, deleteTokenL] = useLocalStorage(tokenName);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backend = (needLoader = false) => {
    const nest = axios.create({ baseURL: baseURL });
    try {
      // Add a request interceptor
      nest.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          if (config.loader) {
            // if (needLoader) {
            showLoader(true);
            // window.showLoader = true
          }
          if (config.msg) {
            if (config.msg.loading) {
              config.msg.id = message.loading({
                content: config.msg.loading,
                key: 'current',
              });
            }
          }
          if (getToken()) {
            config.headers = { Authorization: `Bearer ${getToken()}` };
          }
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        },
      );

      // Add a response interceptor
      nest.interceptors.response.use(
        function (response) {
          // Any status code that lie within the range of 2xx cause this function to trigger
          // Do something with response data
          if (response.config.msg) {
            let msg = response?.config?.msg?.success || response?.data?.message;
            if (response?.config?.msg?.id) {
              message.success({ content: msg, key: 'current' });
            } else {
              message.success(msg);
            }
          }
          // if (response?.data?.token) {
          //   storeToken(response?.data?.token)
          // }

          if (response?.config?.loader) {
            // if (needLoader) {
            showLoader(false);
            // window.showLoader = false
          }
          return response?.data || response;
        },
        function (error) {
          // Any status codes that falls outside the range of 2xx cause this function to trigger
          // Do something with response error
          let msg = extractErrorMsg(error);
          if (error?.response?.config?.msg) {
            if (error?.response?.config?.msg?.id) {
              message.error({ content: msg, key: 'current' });
            }
          } else if (error?.config?.msg) {
            if (error?.config?.msg?.id) {
              message.error({ content: msg, key: 'current' });
            }
          } else {
            // message.error(msg);
          }
          if (
            error?.response?.status === 401 &&
            window.location.href.search('auth') === -1
          ) {
            window.location.href = '/auth/login';
          }
          showLoader(false);
          return Promise.reject(error);
        },
      );
    } catch (error) {
      // message.error(message);
      // console.log(error);
    }
    return nest;
  };

  const updateToken = (token, remember) => {
    updateTokenL(token);
    remember === true && updateTokenC(token);
  };

  const removeToken = (token) => {
    deleteTokenL(token);
    deleteTokenC(token);
  };

  const getToken = () => {
    return tokenL || tokenC || null;
  };

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getItem = (endpoint, id, config = {}) =>
    new Promise((resolve, reject) => {
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      backend()
        .get(`/${endpoint}/${id}`, config)
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const trashItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true, msg: { loading: "Deleting...", success: "Deleted successfully." }
      // }
      backend()
        .delete(`/${endpoint}/${id}`, config)
        .then((data) => {
          dispatch(paginationAction.deleteById({ id }));
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const deleteItem = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      // let config = {
      //   loader: true, msg: { loading: "Deleting...", success: "Deleted successfully." }
      // }
      backend()
        .delete(`/${endpoint}/delete/${id}`, config)
        .then((data) => {
          dispatch(paginationAction.deleteById({ id }));
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const updateItem = (endpoint, id, payload, config) =>
    new Promise((resolve) => {
      backend()
        .put(`/${endpoint}/${id}`, payload, config)
        .then((data) => {
          dispatch(paginationAction.clear());
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const getList = (endpoint, query = {}, config = {}) =>
    new Promise((resolve) => {
      const defaultQuery = { limit: 50 };
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      const params = new URLSearchParams({
        ...defaultQuery,
        ...query,
      }).toString();

      backend()
        .get(`/${endpoint}?${params}`, config)
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  /**
   *
   * @param {Endpoint Name} endpoint
   * @param {Uid} id
   * @returns
   */
  const searchItems = (
    endpoint,
    searchBy,
    searchValue,
    otherConditions = {},
    config = {},
  ) =>
    new Promise((resolve) => {
      const defaultQuery = { limit: 50 };
      // let config = {
      //   loader, msg: { loading: "Registering...", success: "Registered successfully." }
      // }
      const params = new URLSearchParams({
        ...defaultQuery,
        [searchBy]: searchValue,
        // [searchBy]: searchValue + "\uf8ff",
        ...otherConditions,
      }).toString();

      backend()
        .get(`/${endpoint}?${params}`, config)
        .then(({ items }) => {
          return resolve(items);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const addItem = (endpoint, payload, config = {}) =>
    new Promise((resolve) => {
      backend()
        .post(`/${endpoint}`, payload, config)
        .then((data) => {
          dispatch(paginationAction.clear());
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const uploadFile = (endpoint, identifier, formData, config = {}) =>
    new Promise((resolve, reject) => {
      config.headers = { 'Content-Type': 'multipart/form-data' };
      backend()
        .post(`/${endpoint}/file/${identifier}`, formData, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });

  const deleteFile = (endpoint, id, config = {}) =>
    new Promise((resolve) => {
      backend()
        .delete(`/${endpoint}/file/${id}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const uploadS3File = (file, payload, config = {}) =>
    new Promise((resolve) => {
      console.log('file', file);
      config.headers = { 'Content-Type': 'multipart/form-data' };
      backend()
        .post(`/s3/upload`, payload, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }

          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const uploadS3FileUrl = (type = '') => {
    return `${baseURL}/s3/upload/${type}`;
  };

  const deleteS3File = (key, config = {}) =>
    new Promise((resolve) => {
      backend()
        .delete(`/s3?key=${key}`, config)
        .then((data) => {
          if (config?.redirectTo) {
            navigate(`/${config?.redirectTo}`);
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

  const s3GetUrl = (key) => {
    return `${config.cdnUrl}/${key}`;
  };

  const updateAssetAction = async (endpoint, payload, config) => {
    try {
      const response = await backend().put(endpoint, payload.values, config);
      if (!isEmpty(response)) {
        dispatch(paginationAction.updateAssetAction(payload));
        return response;
      }
    } catch (e) {
      return { error: handleError(e, payload.type) };
    }
  };

  return {
    baseURL,
    uploadS3FileUrl,
    uploadS3File,
    backend,
    getToken,
    updateToken,
    removeToken,

    getItem,
    getList,
    searchItems,
    addItem,
    trashItem,
    updateItem,
    deleteItem,
    uploadFile,
    deleteFile,
    deleteS3File,
    s3GetUrl,
    updateAssetAction,
  };
};
