import React from 'react';
import { Table, Layout, FloatButton, Space, Card, Badge, Modal } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { usePaginate } from 'backend/usePaginate';
import { getBadgeColor, shareHolderCountLabel } from 'utils/labels';
import { config } from 'config';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { useSelector } from 'react-redux';
import { selectCMS } from 'features/cms/cmsSlice';
import { useBusiness } from 'hooks/useBusiness';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const apiUrl = config.apiUrl;
const endpoint = 'certificate';

const Certificates = () => {
  const navigation = useNavigate();
  const { businessType, shareholderCount, certificateStatus } =
    useSelector(selectCMS);

  const { trashItem } = useBackend();
  const { businessTypeLabel } = useBusiness();
  const { total, hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    orderBy: 'title',
    order: 'asc',
    limit: 100,
  });

  const deleteConfirm = (e, item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete <b>{item.title}</b>?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Certificate Details',
      dataIndex: 'title',
      key: 'title',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'title'),
      // sortDirections: ['descend'],
      render: (title, row) => (
        <div>
          <div>
            <b>{title}</b>
          </div>
          <div className="font-bold text-xs">
            <div className="text-cyan-400">
              {row.category && row.category.join(', ')}
            </div>
            <div className="text-green-500">
              {shareHolderCountLabel(row.shareholderCount)}
            </div>
            <div className="text-red-500">
              Certificate Type: {businessTypeLabel(row.certificateType)}
            </div>
            {row?.certificateType === 'CORP' && (
              <div className="text-blue-800">
                Corporate Entity Endings: {row?.entityEnding}
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => (
        <DateTimeAgo date={row?.updatedAt ?? createdAt} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <a
            href={`${apiUrl}/${endpoint}/template/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            <CloudDownloadOutlined
              className="text-green-700"
              title="Download Template"
            />
          </a>
          <a
            href={`${apiUrl}/${endpoint}/preview/${id}`}
            target="_blank"
            rel="noreferrer"
          >
            <EyeOutlined className="text-blue-700" title="Preview" />
          </a>
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={(e) => deleteConfirm(e, row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Certificates">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          total={total}
          searchControl={{
            options: [
              {
                key: 'title',
                label: 'Title',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Certificate Type',
              dbKey: 'certificateType',
              options: businessType,
            },
            {
              placeholder: 'Shareholder Count',
              dbKey: 'shareholderCountNum',
              options: shareholderCount,
            },
            {
              placeholder: 'Certificate Status',
              dbKey: 'status',
              options: certificateStatus,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton
        type="primary"
        tooltip="Add New Certificate"
        icon={<PlusOutlined />}
        onClick={() => navigation('add')}
      />
    </Content>
  );
};

export default Certificates;
