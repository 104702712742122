import { authAction } from 'features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBackend } from './useBackend';

export const useAuth = () => {
  const navigate = useNavigate();
  const { removeToken, updateToken, getToken, backend } = useBackend();
  const dispatch = useDispatch();
  const location = useLocation();
  const loader = true;

  const tokenLogin = () => {
    const authToken = getToken();
    if (authToken !== null) {
      dispatch(authAction.loading());
      backend()
        .get('/auth/profile', { msg: null })
        .then(({ user, roles }) => {
          dispatch(
            authAction.login({
              user: user,
              token: authToken,
              roles: roles,
            }),
          );
          navigate(location.pathname);
        })
        .catch(() => {
          dispatch(authAction.authError());
          if (window.location.href.search('auth/') === -1) {
            navigate('/auth/login');
          }
        });
    } else {
      dispatch(authAction.authError());
      if (window.location.href.search('auth/') === -1) {
        navigate('/auth/login');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  /**
   * @param {*} email
   * @param {*} password
   * @param {*} profile
   * @returns
   */
  const signup = (email, password, profile) =>
    new Promise((resolve, reject) => {
      let config = {
        loader,
        msg: { loading: 'Registering...', success: 'Registered successfully.' },
      };
      backend()
        .post('/auth/signup', { email, password, ...profile }, config)
        .then(({ user, token, roles }) => {
          dispatch(
            authAction.login({
              user: user,
              token: token,
              roles: roles,
            }),
          );
          updateToken(token, false);
          return resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });

  /**
   * @param {*} email
   * @param {*} password
   * @returns Promise<user>
   */
  const login = async (email, password, remember) => {
    let config = {
      loader,
      msg: {
        loading: 'Authenticating...',
        success: 'Logged in successfully.',
      },
    };
    await backend()
      .post('/auth/login', { email, password }, config)
      .then(({ user, token, roles }) => {
        dispatch(
          authAction.login({
            user: user,
            token: token,
            roles: roles,
          }),
        );
        updateToken(token, remember);
      });
  };

  const getUserProfile = () =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Retrieving...' },
      };
      backend()
        .get('/auth/profile', config)
        .then(({ user, token, roles }) => {
          dispatch(
            authAction.login({
              user: user,
              token: token,
              roles: roles,
            }),
          );
          return resolve();
        });
    });

  const logout = (redirectTo = '') =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Requesting...', success: 'Logged out successfully.' },
      };
      backend()
        .post('/auth/logout', {}, config)
        .then(() => {
          removeToken();
          dispatch(authAction.logout());
          redirectTo && navigate(redirectTo);
          return resolve();
        });
    });

  const passwordResetEmail = (email) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: {
          loading: 'Requesting...',
          success: 'Password Reset Email Sent!',
        },
      };
      const url =
        window.location.protocol + '//' + window.location.host + '/auth/reset';
      backend()
        .post('/auth/forgot', { email, url }, config)
        .then(() => {
          return resolve();
        });
    });

  const resetPassword = (newPassword, passwordResetCode) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      };
      backend()
        .put('/auth/reset', { newPassword, passwordResetCode }, config)
        .then(() => {
          return resolve();
        });
    });

  const updatePass = (newPassword, oldPassword) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Password Updated.' },
      };
      backend()
        .put('/auth/password', { newPassword, oldPassword }, config)
        .then(() => {
          return resolve();
        });
    });

  const updateAuthProfile = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Profile Updated.' },
      };
      backend()
        .post('/user', { ...payload }, config)
        .then((data) => {
          dispatch(authAction.updateProfile({ updated: payload }));
          return resolve();
        });
    });

  const updateUserProfile = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Updating...', success: 'Profile Updated.' },
      };
      backend()
        .post('/auth/profile', { ...payload }, config)
        .then((data) => {
          dispatch(authAction.updateProfile({ updated: payload }));
          return resolve();
        });
    });

  const deleteAuthUser = (uid) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Deleting...', success: 'User Deleted.' },
      };
      backend()
        .delete(`/user/${uid}`, config)
        .then((data) => {
          return resolve();
        });
    });

  const loginGenerateToken = (payload) =>
    new Promise((resolve) => {
      let config = {
        loader,
        msg: { loading: 'Generating Login access...'},
      };
      backend()
        .post('/auth/login-generate-token', { ...payload }, config)
        .then((data) => {
          return resolve(data);
        });
    });

  return {
    // ...auth,
    tokenLogin,
    signup,
    login,
    logout,
    passwordResetEmail,
    updateAuthProfile,
    updateUserProfile,
    getUserProfile,
    updatePass,
    resetPassword,
    deleteAuthUser,
    loginGenerateToken
  };
};
