import { useState } from 'react';

const useCopiedStatus = () => {
  const [copiedStatus, setCopiedStatus] = useState({});

  const handleCopyToClipboard = async (text, key) => {
    await navigator.clipboard.writeText(text);
    setCopiedStatus({ [text + key]: true });
    setTimeout(() => {
      setCopiedStatus({});
    }, 2000);
  };

  return { copiedStatus, handleCopyToClipboard };
};

export default useCopiedStatus;
