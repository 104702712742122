import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  // Checkbox,
  Select,
  Button,
  Collapse,
  Upload,
} from 'antd';
import {
  UploadOutlined,
  ArrowLeftOutlined,
  FileWordOutlined,
} from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import { assetCertificatesActions } from 'features/cms/assetsCertificateSlice';
const { Content } = Layout;

const endpoint = 'assets-certificate';

const Add = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { baseURL, getToken, deleteS3File, addItem } = useBackend();
  const [maxSize] = useState(50);
  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [s3Path, setS3Path] = useState('');
  const [tmp, setTmp] = useState(null);
  const {
    certificateStatus,
    businessType,
    // shareholderCount,
    corpEntityEndings,
    btEntityEndings,
    familyEntityEndings,
    bankEntityEndings,
  } = useSelector(selectCMS);

  const onFinish = ({ file, ...data }) => {
    console.log(data);
    const payload = {
      ...data,
      fileName: fileName,
      s3Path: s3Path,
    };
    let config = {
      loader: true,
      msg: { loading: 'Processing...', success: 'Added successfully.' },
      redirectTo: '../assets/certificates',
    };
    console.log(payload);
    addItem(endpoint, payload, config).then(({ item }) => {
      dispatch(assetCertificatesActions.add(item));
    });
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  const validation = (file) => {
    console.info(file.type);
    const isValidFile =
      file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (!isValidFile) {
      setFileList([]);
      message.error('You can only upload docx file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      setFileList([]);
      message.error('File must be smaller than 50MB!');
      return false;
    }
    return true;
  };

  const handleChange = async (info) => {
    console.log('handleChange', info);
    console.log('info?.file?.status', info?.file?.status);

    if (info?.file?.status === undefined) {
      message.error('File not allowed');
      return;
    }

    if (info?.file?.status === 'done') {
      // Get this url from response in real world.
      message.success({ content: 'File Uploaded', key: 'upload' });
      console.log(info.file.response);
      if (info.file?.response?.key) {
        setS3Path(info.file?.response?.key);
        setFileName(info.file?.response?.name);
      }
    }
    if (info?.file?.status === 'removed') {
      // updateItem(endpoint, state?.id, { goalThumb: "" }, { loading: true });
      if (info?.file?.response?.key) {
        deleteS3File(info?.file?.response?.key);
      } else if (info?.file?.key) {
        deleteS3File(info?.file?.key);
      }
      message.success('File Removed');
    }
    setFileList(info.fileList);
    if (info.file.status === 'error') {
      // setFileList([])
      message.error({ content: info?.file?.response?.message, key: 'upload' });
      // setLoading(true);
    }
    if (info.file.status === 'uploading') {
      message.loading({ content: 'Uploading...', key: 'upload' });
      // setLoading(true);
    }
  };

  const entityEndingVisible = () => {
    const certificateType = form.getFieldValue('certificateType');
    switch (certificateType) {
      case 'CORP':
        return true;
      case 'BANK':
        return false;
      case 'TRUST':
        return true;
      case 'FAMILY':
        return false;
      default:
        return false;
    }
  };

  const entityEndingOptions = () => {
    const certificateType = form.getFieldValue('certificateType');
    switch (certificateType) {
      case 'TRUST':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...btEntityEndings,
        ];
      case 'CORP':
        return [
          { value: '', label: 'Select Entity Ending' },
          { value: 'ALL', label: 'ALL' },
          ...corpEntityEndings,
        ];
      case 'FAMILY':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...familyEntityEndings,
        ];
      case 'BANK':
        return [
          { value: '', label: 'Select Entity Ending' },
          ...bankEntityEndings,
        ];
      default:
        return [{ value: '', label: 'Please select Business Type first...' }];
    }
  };

  // console.log("fileList", fileList);

  return (
    <Content>
      {/* <Breadcrumb
        style={{ margin: "16px 0" }}
        items={[{ title: "Posts" }, { title: "Add" }]}
      /> */}
      <Card title="Add Certificate">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            status: 'Active',
          }}
          // size="large"
          // onValuesChange={onRequiredTypeChange}
          // requiredMark={requiredMark}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[20, 20]}>
            <Col sm={{ span: 24 }} md={{ span: 18 }}>
              <Collapse defaultActiveKey="1">
                <Collapse.Panel header="Certificate Details" key="1">
                  <Form.Item
                    label="Certificate Title"
                    // tooltip="Certificate Title required"
                    name="title"
                    rules={[{ required: 'Certificate Title required' }]}
                    hasFeedback
                  >
                    <Input placeholder="Title" />
                  </Form.Item>
                  {/* <Form.Item
                    name="category"
                    label="For Shareholder Category"
                    hasFeedback
                  >
                    <Checkbox.Group>
                      <Row>
                        <Col>
                          <Checkbox
                            value="Individual"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Individual
                          </Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            value="Corporate"
                            style={{
                              lineHeight: "32px",
                            }}
                          >
                            Corporate
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item> */}
                  {/* <Form.Item
                    name="shareholderCount"
                    label="For Number of Shareholder"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please Select Number of Shareholder!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Number of Shareholder"
                      options={shareholderCount}
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="certificateType"
                    label="Certificate Type"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please Select Certificate type!',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Certificate Type"
                      options={businessType}
                      value={tmp}
                      onChange={(value) => {
                        setTmp(value);
                        form.resetFields(['entityEnding']);
                      }}
                    />
                  </Form.Item>
                  {entityEndingVisible() && (
                    <Form.Item
                      name="entityEnding"
                      label="Entity Ending"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please Select Entity Ending!',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Entity Ending"
                        options={entityEndingOptions()}
                      />
                    </Form.Item>
                  )}
                  <Form.Item name="file" label="Document File">
                    <Upload.Dragger
                      accept=".docx"
                      method="post"
                      name="file"
                      listType="picture"
                      // className="avatar-uploader"
                      data={{ folder: 'assets-certificate' }}
                      fileList={fileList}
                      showUploadList={true}
                      action={`${baseURL}/assets-certificate/upload`}
                      headers={{ Authorization: `Bearer ${getToken()}` }}
                      maxCount={1}
                      beforeUpload={validation}
                      onChange={handleChange}
                      iconRender={(file) => {
                        return <FileWordOutlined />;
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <UploadOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <small className="pb-2">
                        Accept only Document file with .docx extension
                      </small>
                      <br />
                      <small>
                        Maximum File size <b>{maxSize}MB</b> Allowed
                      </small>
                    </Upload.Dragger>
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 6 }}>
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Publish" key="1">
                    <Form.Item
                      name="status"
                      label="Status"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Status is required',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please select status"
                        options={certificateStatus}
                      />
                    </Form.Item>
                    <Row justify="end">
                      <Button
                        type="ghost"
                        htmlType="button"
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                      >
                        Back
                      </Button>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Content>
  );
};

export default Add;
