import React from 'react';
import { Table, Layout, FloatButton, Space, Card, Badge, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { usePaginate } from 'backend/usePaginate';
import { pascalCaseToSentence, getBadgeColor } from 'utils/labels';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';

import { sorter } from 'utils/custom';
import { useSelector } from 'react-redux';
import { selectCMS } from 'features/cms/cmsSlice';
import { usePrice } from 'hooks/usePrice';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'products';

const Products = () => {
  const { price } = usePrice();
  const navigation = useNavigate();
  const { productTypeOptions, productModuleOptions } = useSelector(selectCMS);

  const { trashItem } = useBackend();
  const { total, hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    orderBy: 'name',
    order: 'asc',
    limit: 100,
  });

  const deleteConfirm = (e, item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete <b>{item.name}</b>?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Site Module',
      dataIndex: 'siteModule',
      key: 'siteModule',
      sorter: (a, b) => sorter(a, b, 'siteModule'),
      // sortDirections: ['ascend' | 'descend'],
      sortOrder: 'ascend',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // filters: {},
      // sorter: true,
      // sorter: (a, b) => sorter(a, b, "name"),
      // sortDirections: ['descend'],
      render: (name, row) => <b>{name}</b>,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      responsive: ['md'],
      align: 'right',
      render: (amount, row) => (
        <div className="text-right">
          {row.onSale ? (
            <span className="text-muted text-uppercase">
              <span className="line-through">
                {price(row?.price, row?.currency)}{' '}
              </span>
              <b>{price(row?.salePrice, row?.currency)}</b>
            </span>
          ) : (
            <b className="text-muted text-uppercase">
              {price(row?.price, row?.currency) || 'NA'}
            </b>
          )}
        </div>
      ),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   responsive: ["md"],
    // },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      responsive: ['md'],
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
      responsive: ['md'],
      render: (productType) => pascalCaseToSentence(productType),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => (
        <DateTimeAgo date={row?.updatedAt ?? createdAt} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            style={{ display: 'none' }}
            className="text-red-600"
            onClick={(e) => deleteConfirm(e, row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Products">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          total={total}
          searchControl={{
            options: [
              {
                key: 'name',
                label: 'Name',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Product Type',
              dbKey: 'productType',
              options: productTypeOptions,
            },
            {
              placeholder: 'Site Module',
              dbKey: 'siteModule',
              options: productModuleOptions,
            },
          ]}
          // dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton
        type="primary"
        tooltip="Add New Product"
        icon={<PlusOutlined />}
        onClick={() => navigation('add')}
        style={{ display: 'none' }}
      />
    </Content>
  );
};

export default Products;
