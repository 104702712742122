import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const DeleteConfirm = ({ onConfirm, handleCancel }) => {
  const deleteConfirm = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>You want to delete?</div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      onCancel: handleCancel,
      cancelText: 'No',
      cancelButtonProps: {
        danger: true,
      },
      onOk: () => {
        onConfirm();
        Modal.destroyAll();
      },
      okText: 'Yes',
      okButtonProps: {
        danger: true,
      },
    });
  };

  return (
    <DeleteOutlined style={{ color: '#ff0000' }} onClick={deleteConfirm} />
  );
};

DeleteConfirm.prototype = {
  onConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
};
