import React from 'react';
import { Outlet } from 'react-router-dom';
import { Row, Col } from 'antd';
import logo from '../assets/images/logo-icon.png';

const AuthLayout = () => {
  return (
    <Row justify="center" align="middle" className="auth-container">
      <Col span={24}>
        <Row justify="center">
          <Col span={24} className="logo-container">
            <img src={logo} alt="logo" className="auth-logo" />
          </Col>
          <Col span={24}>
            <Outlet />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AuthLayout;
