import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Button,
  Collapse,
  Select,
  DatePicker,
  InputNumber,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useBackend } from 'backend/useBackend';
import { useSelector } from 'react-redux';
import { selectCMS } from 'features/cms/cmsSlice';
const { Content } = Layout;

const endpoint = 'shareholder';

const Add = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { addItem } = useBackend();
  const { countriesOptions } = useSelector(selectCMS);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      ...data,
      // filename: fileName,
    };
    let config = {
      loader: true,
      msg: { loading: 'Processing...', success: 'Added successfully.' },
      redirectTo: '../shareholders',
    };
    console.log(payload);
    addItem(endpoint, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  return (
    <Content>
      <Card title="Add Shareholder">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter="20">
            <Col span="18">
              <Collapse defaultActiveKey="1">
                <Collapse.Panel header="Shareholder Details" key="1">
                  <Row gutter={20}>
                    <Col md={12}>
                      <Form.Item
                        label="Corporate/Business Name"
                        // tooltip="Shareholder Title required"
                        name="businessName"
                        rules={[
                          { required: 'Corporate/Business Name required' },
                        ]}
                        hasFeedback
                      >
                        <Input placeholder="Corporate/Business Name" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Filing Number"
                        name="filingNumber"
                        rules={[{ required: 'Filing Number required' }]}
                        hasFeedback
                      >
                        <Input placeholder="Filing Number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Share"
                    name="share"
                    rules={[{ required: 'Share percentage required' }]}
                    hasFeedback
                  >
                    <InputNumber
                      placeholder="Share"
                      suffix={'%'}
                      className="w-full"
                      min={1}
                      max={100}
                    />
                  </Form.Item>
                  <Row gutter={[20, 20]}>
                    <Col md={24}>
                      <Form.Item
                        label="Street"
                        name="street"
                        rules={[{ required: 'Street required' }]}
                        hasFeedback
                      >
                        <Input placeholder="Street" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="City"
                        name="city"
                        rules={[{ required: 'City required' }]}
                        hasFeedback
                      >
                        <Input placeholder="City" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="State"
                        name="state"
                        rules={[{ required: 'State required' }]}
                        hasFeedback
                      >
                        <Input placeholder="State" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        label="Zip Code"
                        name="zipCode"
                        rules={[{ required: 'Zip Code required' }]}
                        hasFeedback
                      >
                        <Input placeholder="Zip Code" />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="country"
                        label="Country"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Country is required',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Please select country"
                          options={countriesOptions}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Business Register Date"
                    name="regDate"
                    rules={[{ required: 'Business Register Date required' }]}
                    hasFeedback
                  >
                    <DatePicker
                      placeholder="Business Register Date"
                      className="w-full"
                    />
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
            </Col>
            <Col span="6">
              <Space
                direction="vertical"
                style={{ width: '100%' }}
                size="large"
              >
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Action" key="1">
                    <Row justify="end">
                      <Button
                        type="ghost"
                        htmlType="button"
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                      >
                        Back
                      </Button>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Add
                        </Button>
                      </Form.Item>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </Content>
  );
};

export default Add;
