import React from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const UploadButton = ({ loading = false, imageUrl = '' }) => {
  return (
    <div>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: '100%',
          }}
        />
      ) : (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadButton;
