export const sorter = (a, b, key) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const truncateHexValue = (fullString, stringLength, separator) => {
  if (!fullString) return '';
  if (fullString.length <= stringLength) return fullString;

  separator = separator ?? '...';

  const charactersToShow = stringLength - separator.length,
    startChars = Math.ceil(charactersToShow / 2),
    endChars = Math.floor(charactersToShow / 2);

  return (
    fullString.slice(0, startChars) +
    separator +
    fullString.slice(fullString.length - endChars)
  );
};
