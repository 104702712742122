import {
  EyeOutlined,
  CopyOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import { STATUS } from 'utils/constants';
import { DeleteConfirm } from 'cms/components/DeleteConfirm';
import { truncateHexValue } from 'utils/custom';
import { config } from 'config';

const { VIEW, MINT, VERIFY } = STATUS;

const openSeaUrl = config.openSeaUrl;
const pinataUrl = config.pinataUrl;

const tokenContract = config.tokenContractAddress;

const UserDetails = ({ title, value, copied, onCopy, color }) => (
  <div>
    <div className="inline">
      {title}: <span className={color}>{value}&nbsp;</span>
    </div>
    {!copied ? <CopyOutlined onClick={onCopy} /> : <CheckCircleOutlined />}
  </div>
);

export const getColumns = (
  handleAction,
  deleteConfirm,
  currentItem,
  copyInfo,
  copiedStatus,
) => {
  return [
    {
      title: 'Asset ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => {
        return <div>{id}</div>;
      },
    },
    {
      title: 'Asset Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Price in USD',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Price in Matic',
      dataIndex: 'priceInMatic',
      key: 'priceInMatic',
    },
    {
      title: 'Asset Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '8%',
    },
    {
      title: 'User Details',
      dataIndex: 'user',
      key: 'user',
      render: (_, row, index) => (
        <>
          {[
            { key: 'userId', color: 'text-yellow-600' },
            { key: 'account', color: 'text-cyan-800' },
            { key: 'email', color: 'text-gray-600' },
          ].map(({ key, color }) => (
            <UserDetails
              key={key}
              title={key.replace(/\b\w/g, (c) => c.toUpperCase())}
              value={key === "email" ? row[key] : truncateHexValue(row[key], 21)}
              copied={copiedStatus[row[key] + index]}
              onCopy={() => copyInfo(row[key], index)}
              color={color}
            />
          ))}
        </>
      ),
    },
    {
      title: 'Asset Image',
      dataIndex: 'ipfsCid',
      key: 'ipfsCid',
      align: 'center',
      render: (ipfsCid) => (
        <a
          href={`${pinataUrl}/${ipfsCid}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          View
        </a>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          <DeleteConfirm onConfirm={() => deleteConfirm(row)} />
        </Space>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id, row, index) => {
        const label = !row.isVerified ? VERIFY : !row.isMinted ? MINT : VIEW;
        return (
          <>
            {label !== VIEW ? (
              <Button
                type="primary"
                size="small"
                onClick={() => handleAction(row, label, index)}
                loading={currentItem === id}
              >
                {label}
              </Button>
            ) : (
              <a
                href={`${openSeaUrl}/${tokenContract}/${row.id}`}
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                View Asset
              </a>
            )}
          </>
        );
      },
    },
  ];
};
