import { config } from 'config';

const env = config.env;

export const tokens = [
  // {
  //   name: 'Blockchain Bank Stable Coin',
  //   symbol: 'BBSC',
  //   decimals: 18,
  //   contractAddressTest: '0x88187e1fe20e87a60efcf8669e8c795db8759786',
  //   contractAddressMain: '0xe0efe30541a4f91769bea9b35b1c142f02ccc67f',
  // },
  {
    name: 'Blockchain Bank Super Coin',
    symbol: 'BBSC',
    decimals: 18,
    contractAddressTest: '0x67f5680178790b4fb1e0b23eeb95d6a8eb95143f', // polygon amoy
    contractAddressMain: '0x72794139471bf184560489d6c8994f7466ee9fa8',
  },
  {
    name: 'Borg Gold Stable Coin',
    symbol: 'BGSC',
    decimals: 18,
    // contractAddressTest: '0xa97564ab69de9747e413bd708ca7980111aa04af', // polygon mumbai
    contractAddressTest: '0x2674012a2fd73ef40f3f3a3a3324a36dd0026862', // polygon amoy
    contractAddressMain: '0x6ae5cf48810531f444847b13741bfee63aeedbd1',
  },
  {
    name: 'LetsCoin',
    symbol: 'LTSC',
    decimals: 18,
    // contractAddressTest: '0xf1807bccd3779b2ef9f821ffe076ab872b89bfd3', // polygon mumbai
    contractAddressTest: '0x2a512a1af5145d901a335f2a5205d5db50829883', // polygon amoy
    contractAddressMain: '0xf1807bccd3779b2ef9f821ffe076ab872b89bfd3',
  },
];

export const getTokenMeta = (symbol) => {
  const token = tokens.find((t) => t.symbol === symbol);
  if (env === 'prod') {
    return {
      name: token.name,
      symbol: token.symbol,
      decimals: token.decimals,
      contractAddress: token.contractAddressMain,
    };
  } else {
    return {
      name: token.name,
      symbol: token.symbol,
      decimals: token.decimals,
      contractAddress: token.contractAddressTest,
    };
  }
};
