export const filterOption = (input, option) => {
  return (
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
};

export const keysFromPath = (path) => {
  let pathname = path.split('/');
  if (pathname != null) {
    return pathname[1];
  }
  return '';
};
