import { selectCMS } from 'features/cms/cmsSlice';
import { useSelector } from 'react-redux';

const useBusiness = () => {
  const {
    businessType,
    btEntityEndings,
    corpEntityEndings,
    familyEntityEndings,
  } = useSelector(selectCMS);

  const entityEndingOptions = (
    form,
    field = 'businessType',
    label = 'Please select Business Type first...',
  ) => {
    const businessType = form.getFieldValue(field);
    switch (businessType) {
      case 'TRUST':
        return btEntityEndings;
      case 'CORP':
        return corpEntityEndings;
      case 'FAMILY':
        return familyEntityEndings;
      default:
        return [{ value: '', label: label }];
    }
  };

  const businessTypeLabel = (cType) => {
    const found = businessType.find((t) => t.value === cType);
    if (found) {
      return found.label;
    }
    return '';
  };

  return {
    entityEndingOptions,
    businessTypeLabel,
  };
};

export { useBusiness };
