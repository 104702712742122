import { useRoutes } from 'react-router-dom';

import AdminRoutes from './AdminRoutes';
import AuthRoutes from './AuthRoutes';
import PageRoutes from './PageRoutes';

const Routes = () => {
  return useRoutes([AdminRoutes, AuthRoutes, PageRoutes]);
};

export default Routes;
