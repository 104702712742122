import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import { Layout, Menu } from 'antd';

import logo from '../assets/images/logo-invert.png';
import logoIcon from '../assets/images/logo-icon-invert.png';

import HeaderLayout from './HeaderLayout';

import { adminMenu } from '../routes/AdminRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from 'features/auth/authSlice';
import {
  selectTheme,
  toggleSiderCollapsedAction,
} from 'features/theme/themeSlice';
import { keysFromPath } from 'utils/helpers';
// import BreadcrumbLayout from "./BreadcrumbLayout"

// import FooterLayout from './FooterLayout'

const { Sider } = Layout;

const AdminLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [pathname, setPathname] = useState('');
  const { roles } = useSelector(selectAuth);
  const { sider } = useSelector(selectTheme);

  useEffect(() => {
    let pathname = location.pathname.split('/');
    if (pathname != null) {
      setPathname(pathname[1]);
    }
  }, [location.pathname]);

  console.log('AdminLayout');

  return (
    <Layout
      style={{ minHeight: '100vh' }}
      // className={cx({ "ant-sider-fixed": sider.fixed })}
    >
      <Sider
        collapsible
        collapsed={sider.collapsed}
        onCollapse={() => dispatch(toggleSiderCollapsedAction())}
        breakpoint="lg"
        collapsedWidth={sider.fullCollapsed ? 0 : 50}
        onBreakpoint={(broken) => {
          console.log('breakPoint', broken);
        }}
        style={
          sider.fixed && {
            overflow: 'auto',
            height: '100vh',
            position: 'sticky',
            left: 0,
            top: 0,
            bottom: 0,
          }
        }
      >
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="logo collapsed">
          <img src={logoIcon} alt="logo" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          // onClick={e => {console.log(e); setCurrent(e.key)}}
          // defaultSelectedKeys={['']}
          // selectedKeys={[pathname]}
          // defaultSelectedKeys={["1"]}
          // defaultOpenKeys={['sub1']}
          // openKeys={[open]}
          selectedKeys={[pathname]}
          items={adminMenu.map(
            (item) =>
              item.menu &&
              item.roles.every((role) => roles.includes(role)) && {
                key: item.key || keysFromPath(item?.path),
                icon: item.icon,
                label:
                  item?.children?.length > 0 ? (
                    item.label
                  ) : item?.path ? (
                    <NavLink to={item.path}>{item.label}</NavLink>
                  ) : (
                    item.label
                  ),
                children:
                  item?.children?.length > 0 &&
                  item?.children.map((i) => ({
                    key: i.key || keysFromPath(i.path),
                    icon: i?.icon || null,
                    label: i.path ? (
                      <NavLink to={i.path}>{i.label}</NavLink>
                    ) : (
                      i.label
                    ),
                  })),
              },
          )}
          // items={routes.map(
          //   (route) =>
          //     route.menu &&
          //     route.roles.find((role) => role === user.role) && {
          //       key: keysFromPath(route.path),
          //       icon: route.icon && <route.icon />,
          //       label: <Link to={route.path}>{route.label}</Link>,
          //     }
          // )}
          // style={{
          //   height: '100%',
          //   borderRight: 0,
          // }}
        ></Menu>
      </Sider>
      <Layout className="main-layout">
        <HeaderLayout />
        <Layout className="site-layout">
          {/* <BreadcrumbLayout /> */}
          <Outlet />
        </Layout>
        {/* <FooterLayout /> */}
      </Layout>
    </Layout>
  );
};

// export default AdminLayout;
export default React.memo(AdminLayout);
