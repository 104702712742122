import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bulk: [],
  duplicate: 0,
  loaded: false,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    loadBulkAssets: (state, { payload }) => {
      state.bulk = payload;
      state.duplicate = payload.filter((b) => b.duplicate === true).length;
      state.loaded = true;
    },
    removeAssetById: (state, { payload }) => {
      state.bulk = state.bulk.filter((a) => a.id !== payload);
      state.duplicate = state.duplicate - 1;
    },
    removeDuplicate: (state) => {
      state.bulk = state.bulk.filter((a) => a.duplicate !== true);
      state.duplicate = 0;
    },
    clearBulkAssets: (state) => {
      state.bulk = [];
      state.duplicate = 0;
      state.loaded = false;
    },
  },
});

export const assetsActions = assetsSlice.actions;
export const selectAssets = (state) => state.assets;
