import React, { useEffect, useState } from 'react';
// import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom';

import { Layout, Descriptions, Skeleton, Card, Badge } from 'antd';
import { useBackend } from 'backend/useBackend';
import { countryNameByIso2 } from 'data/countries';
import { businessTypeLabel, getBadgeColor } from 'utils/labels';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;
const endpoint = 'good-standing';

const BusinessInfo = () => {
  // const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => {
      setItem(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Good Standings Details">
          {item?.business && (
            <Descriptions title="Business Details" layout="vertical" bordered>
              <Descriptions.Item label="Business Type" span={1}>
                <b>{businessTypeLabel(item?.business?.businessType)}</b>
              </Descriptions.Item>
              <Descriptions.Item label="Business Residency" span={2}>
                {countryNameByIso2(item?.business.country)}
              </Descriptions.Item>
              <Descriptions.Item label="BICRA">
                {item?.business.bicraFormatted}
              </Descriptions.Item>
              <Descriptions.Item label="Filing Number">
                {item?.business.filingNumberFormatted}
              </Descriptions.Item>
            </Descriptions>
          )}
          <br />
          <Descriptions
            title="Good Standings Details"
            layout="vertical"
            bordered
          >
            <Descriptions.Item label="Where to Use" span={1}>
              <b>{item?.whereToUse}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Email" span={2}>
              {item?.deliveryEmail}
            </Descriptions.Item>
            <Descriptions.Item label="Delivered">
              {item?.delivered ? 'Yes' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Delivery Status">
              <Badge color={getBadgeColor(item?.status)} count={item?.status} />
            </Descriptions.Item>
          </Descriptions>
          <br />
          {/* <Descriptions title="Shareholder Details" layout="vertical" bordered>
            <Descriptions.Item label="Shareholder Type">
              {item?.shareholderType}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Contact Info" layout="vertical" bordered>
            <Descriptions.Item label="Tel Number">
              {item?.landLine}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{item?.email}</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item?.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item?.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item?.state}</Descriptions.Item>
            <Descriptions.Item label="Country">
              {countryNameByIso2(item?.country)}
            </Descriptions.Item>
            <Descriptions.Item label="ZipCode">
              {item?.zipCode}
            </Descriptions.Item>
          </Descriptions>
          <br /> */}
          <Descriptions title="System Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
            <Descriptions.Item label="Created At">
              <DateTimeAgo date={item.createdAt} onlyDate />
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At">
              <DateTimeAgo date={item.updatedAt} onlyDate />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </Content>
  );
};

export default BusinessInfo;
