import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  paginationAction,
  selectPagination,
} from 'features/pagination/paginationSlice';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('en');

const { RangePicker } = DatePicker;

const rangePresets = [
  {
    label: 'Last 7 Days',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
  {
    label: 'Last Week',
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: 'Last Month',
    value: [dayjs().add(-1, 'month'), dayjs()],
  },
  {
    label: 'Last 6 Moths',
    value: [dayjs().add(-6, 'months'), dayjs()],
  },
  {
    label: 'Last Year',
    value: [dayjs().add(-1, 'years'), dayjs()],
  },
];

const DateRangeControl = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(selectPagination);

  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  // const [dateStart, setDateStart] = useState(dayjs().add(-1, "months"));
  // const [dateEnd, setDateEnd] = useState(dayjs());

  useEffect(() => {
    if (_.isEmpty(filters, _.identity())) {
      setDateStart(null);
      setDateEnd(null);
    }
  }, [filters]);

  const handleChange = (values, dateString) => {
    console.info(values, dateString);
    if (values) {
      setDateStart(values[0]);
      setDateEnd(values[1]);
      dispatch(
        paginationAction.setFilter({
          createdAtWithin: [
            dayjs(values[0]).toISOString(),
            dayjs(values[1]).toISOString(),
          ].join(','),
        }),
      );
    } else {
      setDateStart(null);
      setDateEnd(null);
      dispatch(paginationAction.setFilter({ createdAtWithin: null }));
    }
  };

  // console.log('DateRangePicker', filter);

  return (
    <RangePicker
      presets={rangePresets}
      allowClear
      value={[dateStart, dateEnd]}
      disabledDate={(date) => {
        return date.isAfter(dayjs());
      }}
      onChange={handleChange}
    />
  );
};

export default DateRangeControl;
