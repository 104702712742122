/* eslint-disable no-useless-escape */
// import React from 'react'

import { MESSAGES, STATUS } from './constants';

export const passwordLength = 6;
export const validMobileRegx =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const validEmailRegx =
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validPANNumberRegx = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
export const validAadharNumberRegx = /d{12}/;

// export const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
// export const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})')
// export const mediumRegex = new RegExp('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.{6,}$)')
// export const mediumRegex = new RegExp('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)')

export const strongRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})',
);
// export const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})')
export const mediumRegex = new RegExp(
  '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.{6,}$)',
);
// export const mediumRegex = new RegExp('^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)')

export const hasErrors = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return false;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return true;
  if (obj.length === 0) return false;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return false;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return true;
  }
  return false;
};

export const isEmpty = (obj) => {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

// Separate function for email validation
export function verifyEmail(value) {
  const emailRex =
    /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
}

// Separate function for password strength validation
export function verifyPassword(value) {
  const passwordRex = /^[\S]{6,15}$/;
  if (passwordRex.test(value)) {
    return true;
  }
  return false;
}

// Separate function for field length validation
export function verifyLength(value, length) {
  if (value.length >= length) {
    return true;
  }
  return false;
}

export function verifyLink(value) {
  // eslint-disable-next-line no-useless-escape
  const linkRex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  if (linkRex.test(value)) {
    return true;
  }
  return false;
}

export function verifyMobileNumber(value) {
  const mobRex = /^[0-9]{10,14}$/;
  if (mobRex.test(value)) {
    return true;
  }
  return false;
}

export function verifyUsername(value) {
  const usernameRex = /^(?!.*__.*)(?!.*\.\..*)[a-z0-9_.]+$/;
  if (usernameRex.test(value)) {
    return true;
  }
  return false;
}

export function verifyFullname(value) {
  const fullnameRegex = /^[a-zA-Z ]+$/;
  if (fullnameRegex.test(value)) {
    return true;
  }
  return false;
}

const defaultMessage = (type) => {
  switch (type) {
    case STATUS.MINT:
      return MESSAGES.mintFail;
    case STATUS.VERIFY:
      return MESSAGES.verifyFail;
    default:
      return MESSAGES.defaultError;
  }
};

export const handleError = (error, type) => {
  const message = error?.response?.data?.message ?? defaultMessage(type);
  return message;
};
