import { createSlice } from '@reduxjs/toolkit';
import { fulfilled, idle } from 'features/status';

const initialState = {
  items: [],
  status: idle,
};

export const assetsCertificateSlice = createSlice({
  name: 'assetsCertificateSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    load: (state, { payload }) => {
      state.items = payload;
      state.status = fulfilled;
    },
    removeById: (state, { payload }) => {
      state.items = state.items.filter((a) => a.id !== payload);
    },
    updateById: (state, { payload }) => {
      state.items = state.items.map((a) => (a.id === payload.id ? payload : a));
    },
    add: (state, { payload }) => {
      state.items = state.items.concat(payload);
    },
  },
});

export const assetCertificatesActions = assetsCertificateSlice.actions;
export const selectAssetCertificates = (state) => state.assetsCertificateSlice;
export const selectAssetActiveCertificates = (state) => {
  return state.assetsCertificateSlice.items.filter(
    (c) => c.status === 'Active',
  );
};
