import React from 'react';
import dayjs from 'dayjs';

const ShareholderDetailsByType = ({ shareholder }) => {
  switch (shareholder.type) {
    case 'Individual':
      return (
        <div>
          {shareholder?.firstName} {shareholder?.lastName}
          {shareholder?.dob && (
            <div>
              Dob{' '}
              <b>{dayjs(new Date(shareholder?.dob)).format('DD/MM/YYYY')}</b>
            </div>
          )}
          {shareholder?.passportNumber && (
            <div>
              Passport Number <b>{shareholder?.passportNumber}</b>
            </div>
          )}
        </div>
      );
    case 'Corporate':
      return (
        <div>
          {shareholder?.businessName}
          {shareholder?.regDate && (
            <div>
              Registered Date{' '}
              <b>
                {dayjs(new Date(shareholder?.regDate)).format('DD/MM/YYYY')}
              </b>
            </div>
          )}
          {shareholder?.filingNumber && (
            <div>
              Filing Number <b>{shareholder?.filingNumber}</b>
            </div>
          )}
        </div>
      );
    default:
      return () => null;
  }
};

export default ShareholderDetailsByType;
