import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Layout,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Select,
  Button,
  Collapse,
  Skeleton,
  Upload,
  Modal,
  InputNumber,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import { getBase64 } from 'utils/custom';
import UploadButton from 'components/antd/UploadButton';

const { Content } = Layout;

const endpoint = 'portfolio';

const Edit = () => {
  const navigate = useNavigate();
  const { portfolioId } = useParams();
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const {
    getToken,
    uploadS3FileUrl,
    deleteS3File,
    updateItem,
    getItem,
    getList,
    s3GetUrl,
  } = useBackend();
  const { statuses } = useSelector(selectCMS);
  const [shareholders, setShareholders] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [thumbName, setThumbName] = useState('');
  const [thumbPath, setThumbPath] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  useEffect(() => {
    getItem(endpoint, portfolioId).then(({ item }) => {
      setItem(item);
      if (item.thumbPath) {
        setFileList([
          {
            id: '-1',
            name: item.thumbName,
            url: s3GetUrl(item.thumbPath),
            key: item.thumbPath,
          },
        ]);
      }
    });
    getList('shareholder', { limit: 1000 }).then(({ items }) =>
      setShareholders(
        items.map((item) => ({ value: item.id, label: item.businessName })),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioId]);

  const onFinish = (data) => {
    console.log(data);
    const payload = {
      ...data,
      thumbName,
      thumbPath,
      // filename: fileName,
    };
    let config = {
      loader: true,
      msg: { loading: 'Updating...', success: 'Updated successfully.' },
      redirectTo: '../portfolio',
    };
    console.log(payload);
    updateItem(endpoint, portfolioId, payload, config);
  };

  const onFinishFailed = () => {
    message.error('Please Fill required field!');
  };

  const validation = (file) => {
    const isImageFile =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isImageFile) {
      setFileList([]);
      message.error('You can only upload JPG/PNG/SVG file!');
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setFileList([]);
      message.error('Image must smaller than 2MB!');
      return false;
    }
    return true; //isJpgOrPng && isLt2M;
  };

  const handleChange = async (info) => {
    console.log('handleChange', info);
    console.log('info?.file?.status', info?.file?.status);

    if (info?.file?.status === undefined) {
      message.error('File not allowed');
      return;
    }

    if (info?.file?.status === 'done') {
      // Get this url from response in real world.
      message.success('File Uploaded');
      console.log(info.file.response);
      if (info.file?.response?.key) {
        setThumbPath(info.file?.response?.key);
        setThumbName(info.file?.response?.name);
      }
      // if (info?.goalThumb?.key) {
      //   updateItem(
      //     endpoint,
      //     state?.id,
      //     {
      //       goalThumb: info?.goalThumb?.key || "",
      //     },
      //     { loading: true }
      //   );
      // } else if (info?.file?.response) {
      //   updateItem(
      //     endpoint,
      //     state?.id,
      //     {
      //       goalThumb: info?.file?.response?.key || "",
      //     },
      //     { loading: true }
      //   );
      // }
    }
    if (info?.file?.status === 'removed') {
      // updateItem(endpoint, state?.id, { goalThumb: "" }, { loading: true });
      if (info?.file?.response?.key) {
        deleteS3File(info?.file?.response?.key);
        setThumbPath('');
        setThumbName('');
      } else if (info?.file?.key) {
        deleteS3File(info?.file?.key);
        setThumbPath('');
        setThumbName('');
      }
      message.success('File Removed');
    }
    setFileList(info.fileList);
    if (info.file.status === 'error') {
      // setFileList([])
      message.error(info?.file?.response?.message);
      // setLoading(true);
    }
    if (info.file.status === 'uploading') {
      // message.loading('Uploading...')
      // setLoading(true);
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };

  console.log('fileList', fileList);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title="Edit Portfolio">
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              ...item,
            }}
            // size="large"
            // onValuesChange={onRequiredTypeChange}
            // requiredMark={requiredMark}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[20, 20]}>
              <Col sm={{ span: 24 }} md={{ span: 18 }}>
                <Collapse defaultActiveKey="1">
                  <Collapse.Panel header="Portfolio Details" key="1">
                    <Form.Item
                      label="Thumb"
                      // tooltip="Portfolio Title required"
                      name="thumb"
                    >
                      <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        data={{ folder: 'portfolio' }}
                        fileList={fileList}
                        showUploadList={true}
                        accept="image/*"
                        action={uploadS3FileUrl('image')}
                        headers={{ Authorization: `Bearer ${getToken()}` }}
                        maxCount={1}
                        beforeUpload={validation}
                        onChange={handleChange}
                        onPreview={handlePreview}
                      >
                        {fileList.length === 0 && <UploadButton />}
                      </Upload>
                    </Form.Item>
                    <Form.Item
                      label="Portfolio Title"
                      // tooltip="Portfolio Title required"
                      name="title"
                      rules={[{ required: 'Portfolio Title required' }]}
                      hasFeedback
                    >
                      <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                      label="Portfolio Description"
                      name="description"
                      rules={[{ required: 'Portfolio Description required' }]}
                      hasFeedback
                    >
                      <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      label="Select Shareholder"
                      name="shareholderId"
                      rules={[{ required: 'Select Shareholder required' }]}
                      hasFeedback
                    >
                      <Select
                        placeholder="Select Shareholder"
                        options={shareholders}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Portfolio Total Count"
                      name="count"
                      rules={[{ required: 'Portfolio Total Count' }]}
                      help="For example 1000000"
                      hasFeedback
                    >
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </Collapse.Panel>
                </Collapse>
              </Col>
              <Col sm={{ span: 24 }} md={{ span: 6 }}>
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Collapse defaultActiveKey="1">
                    <Collapse.Panel header="Action" key="1">
                      <Form.Item
                        name="status"
                        label="Status"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Status is required',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select status"
                          options={statuses}
                        />
                      </Form.Item>
                      <Row justify="end">
                        <Button
                          type="ghost"
                          htmlType="button"
                          onClick={() => navigate(-1)}
                          icon={<ArrowLeftOutlined />}
                        >
                          Back
                        </Button>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Update
                          </Button>
                        </Form.Item>
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewOpen(false)}
      >
        <img
          alt="preview"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </Content>
  );
};

export default Edit;
