import React from 'react';
import { Provider } from 'react-redux';

// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyleProvider } from '@ant-design/cssinjs';
// import { EtherProvider } from 'contexts/EtherContext';

import './assets/css/index.css';
import App from './App';
// import reportWebVitals from "./reportWebVitals";
import { store } from 'features';
import Loader from './utils/loader';
import { ConfigProvider } from 'antd';
import { customTheme } from 'antd.theme';
import { Web3Provider } from 'contexts/Web3Context';

// react 18
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <ConfigProvider theme={customTheme}>
    <StyleProvider hashPriority="low">
      <Provider store={store}>
        <BrowserRouter>
          <Web3Provider>
            {/* <EtherProvider> */}
            <App />
            {/* </EtherProvider> */}
          </Web3Provider>
          <Loader />
        </BrowserRouter>
      </Provider>
    </StyleProvider>
  </ConfigProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
