import { lazy } from 'react';

// project imports
import Loadable from '../components/Loadable';
import AuthLayout from '../layouts/AuthLayout';

const Login = Loadable(lazy(() => import('../pages/Auth/Login')));
const Signup = Loadable(lazy(() => import('../pages/Auth/Signup')));
const ChangePass = Loadable(lazy(() => import('../pages/Auth/ChangePass')));
const ForgotPass = Loadable(lazy(() => import('../pages/Auth/ForgotPass')));
const ResetPass = Loadable(lazy(() => import('../pages/Auth/ResetPass')));
const Page404 = Loadable(lazy(() => import('../pages/404')));

const AuthRoutes = {
  path: '/auth',
  element: <AuthLayout />,
  children: [
    {
      path: 'signup',
      element: <Signup />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'forgot',
      element: <ForgotPass />,
    },
    {
      path: 'reset',
      element: <ResetPass />,
    },
    {
      path: 'change-pass',
      element: <ChangePass />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ],
};

export default AuthRoutes;
