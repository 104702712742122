import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Input, Space } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import {
  paginationAction,
  selectPagination,
} from 'features/pagination/paginationSlice';

const SearchControl = ({
  options = [],
  placeholder = 'Search...',
  handleFilter,
}) => {
  const dispatch = useDispatch();

  const { filters } = useSelector(selectPagination);

  const searchRef = useRef(null);
  const [searchBy, setSearchBy] = useState(options[0].key);

  const handleChange = (e) => {
    const value = e.target.value;
    console.info(value);
    dispatch(paginationAction.setFilter({ [searchBy]: value + '%' }));
  };

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      const value = e.target.value;
      console.info(value);
      dispatch(paginationAction.setFilter({ [searchBy]: value + '%' }));
      handleFilter(filters);
    }
  };

  const handleSearch = (s) => {
    if (searchRef) {
      const el = searchRef.current;
      el.value = '';
      el.focus();
      dispatch(paginationAction.setFilter({ prevOption: searchBy }));
      setSearchBy(s);
    }
  };

  const items = options.map((option) => ({
    key: option.key,
    label: option.label,
    onClick: () => {
      handleSearch(option.key);
    },
  }));

  return (
    <Space.Compact>
      <Input
        ref={searchRef}
        placeholder={placeholder}
        style={{ width: '10em' }}
        onChange={handleChange}
        onKeyUp={handleSubmit}
        value={
          filters &&
          filters[searchBy] &&
          String(filters[searchBy]).replace('%', '')
        }
        prefix={<SearchOutlined />}
      />
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          By {options.find((s) => searchBy === s.key)['label']}
          <DownOutlined />
        </Button>
      </Dropdown>
    </Space.Compact>
  );
};

export default SearchControl;
