import React, { useEffect, useState } from 'react';
// import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom';

import {
  Layout,
  Descriptions,
  Skeleton,
  Card,
  Modal,
  Space,
  Button,
} from 'antd';
import { useBackend } from 'backend/useBackend';
import { countryNameByIso2 } from 'data/countries';
import { businessTypeLabel } from 'utils/labels';
import { useWeb3 } from 'contexts/Web3Context';
import { config } from 'config';

// import polygonIcon from "assets/svg/polygon-matic.svg";
import polygonMatic from 'assets/svg/matic.png';
import DateTimeAgo from 'components/DateTimeAgo';

const contractExplorerUrl = config.contractExplorerUrl;
const openSeaUrl = config.openSeaUrl;
// const contractOwner = config.contractOwner;

const { Content } = Layout;
const endpoint = 'business';
const businessContractAddress = config.businessContractAddress;

const BusinessInfo = () => {
  // const navigate = useNavigate();
  // const { currentAccount } = useSelector(selectWeb3);
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem, updateItem } = useBackend();
  const [block, setBlock] = useState(false);

  const {
    safeMint,
    // getBusinessOwner,
    // connectWalletHandler,
  } = useWeb3();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => {
      setItem(item);
      if (item.block) {
        setBlock(JSON.parse(item.block));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // useEffect(() => {
  //   connectWalletHandler();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const sendConfirm = (item) => {
    Modal.confirm({
      icon: (
        <img
          src={polygonMatic}
          alt=""
          style={{
            width: '64px',
            height: '64px',
            // padding: "4px",
            marginRight: '10px',
            // border: "2px solid #8247e5",
            borderRadius: '50%',
          }}
        />
      ),
      title: 'Are you ready to deploy this business?',
      content: (
        <div className="py-10">
          <b>{item.businessFullName}</b>
          <br />
          <small>Once it is submitted it can not be altered.</small>
        </div>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => sendBusiness(item),
      okText: 'Yes',
    });
  };

  const sendBusiness = (item) => {
    safeMint(item?.filingNumberFormatted, businessContractAddress).then(
      (receipt) => {
        if (receipt?.hash) {
          updateItem(`${endpoint}/block`, item.id, {
            ...JSON.parse(JSON.stringify(receipt)),
            owner: receipt.from,
            transactionHash: receipt.hash,
          }).then(() => {
            setBlock({
              ...JSON.parse(JSON.stringify(receipt)),
              owner: receipt.from,
              transactionHash: receipt.hash,
            });
          });
        }
      },
    );
  };

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title={`${item?.businessName} ${item?.entityEnding}`}>
          <Descriptions
            title="Business Details on Blockchain Network"
            layout="vertical"
            bordered
          >
            {false && (
              <Descriptions.Item label="Details on Blockchain Network" span={3}>
                <Space>
                  {/* <Button
                    onClick={(e) =>
                      getBusinessOwner(item.filingNumberFormatted).then(
                        (receipt) => {
                          alert(`Owner of this company: ${receipt}`);
                        }
                      )
                    }
                    type="primary"
                    className="mr-2"
                  >
                    Get Business Owner
                  </Button> */}
                  {/* <Button
                    onClick={(e) =>
                      getBusiness(item.filingNumberFormatted).then(
                        (receipt) => {
                          alert(
                            `Company Details\nName: ${receipt.name}\nFiling Number: ${receipt.filingNumber}\nBICRA: ${receipt.bicra}`
                          );
                        }
                      )
                    }
                    type="primary"
                  >
                    Get Business Details
                  </Button> */}
                </Space>
              </Descriptions.Item>
            )}
            {block && (
              <>
                <Descriptions.Item
                  label="View it on Polygon Scan Explorer"
                  span={3}
                >
                  <a
                    href={`${contractExplorerUrl}/tx/${block.transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {`${contractExplorerUrl}/tx/${block.transactionHash}`}
                  </a>
                </Descriptions.Item>

                <Descriptions.Item label="View it on OpenSea" span={3}>
                  <a
                    href={`${openSeaUrl}/${businessContractAddress}/${Number(
                      item?.filingNumberFormatted,
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {`${openSeaUrl}/${businessContractAddress}/${Number(
                      item?.filingNumberFormatted,
                    )}`}
                  </a>
                </Descriptions.Item>
              </>
            )}
            <Descriptions.Item label="Network Block Details" span={3}>
              {block ? (
                <>
                  {/* <a
                    href={`${contractExplorerUrl}/tx/${block.transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {`${contractExplorerUrl}/tx/${block.transactionHash}`}
                  </a> */}
                  <div>Transaction Hash: {block.transactionHash}</div>
                  <div>Block Number: {block.blockNumber}</div>
                  <div>From: {block.from}</div>
                  <div>To: {block.to}</div>
                  <div>Gas Used: {block.gasUsed}</div>
                  {/* <div>Owner: {block.from}</div> */}
                  {/* <span>Status: {String(block.status)}</span><br /> */}
                  {/* <pre>{JSON.stringify(block, null, 2)}</pre><br /> */}
                  {/* {contractOwner.toLowerCase() ===
                    String(currentAccount).toLowerCase() && (
                    <div className="mt-4">
                      <Button
                        type=""
                        className="btn btn-danger"
                        onClick={() => {
                          navigate(`/business/transfer/${item.id}`);
                        }}
                        // to={`/business/transfer/${item.id}`}
                      >
                        Transfer Ownership
                      </Button>
                    </div>
                  )} */}
                </>
              ) : (
                <>
                  <Button onClick={() => sendConfirm(item)} type="primary">
                    Deploy to Network
                  </Button>
                  {/* <div className="mt-2">
                    This business is deployable on network... Please click above
                    button to deploy.
                  </div> */}
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Business Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Business Name" span={2}>
              <b>
                {item?.businessName} {item?.entityEnding}
              </b>
            </Descriptions.Item> */}
            <Descriptions.Item label="Business Type" span={1}>
              <b>{businessTypeLabel(item?.businessType)}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Business Residency" span={2}>
              {countryNameByIso2(item?.country)}
            </Descriptions.Item>
            <Descriptions.Item label="BICRA">
              {item.bicraFormatted}
            </Descriptions.Item>
            <Descriptions.Item label="Filing Number">
              {item.filingNumberFormatted}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Shareholder Details" layout="vertical" bordered>
            <Descriptions.Item label="Shareholder Type">
              {item.shareholderType}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Contact Info" layout="vertical" bordered>
            <Descriptions.Item label="Tel Number">
              {item.landLine}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{item.email}</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item.state}</Descriptions.Item>
            <Descriptions.Item label="Country">
              {countryNameByIso2(item?.country)}
            </Descriptions.Item>
            <Descriptions.Item label="ZipCode">
              {item.zipCode}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="System Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
            <Descriptions.Item label="Created At">
              <DateTimeAgo date={item.createdAt} onlyDate />
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At">
              <DateTimeAgo date={item.updatedAt} onlyDate />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </Content>
  );
};

export default BusinessInfo;
