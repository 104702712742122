// import { lazy } from 'react';
import {
  // PushpinOutlined,
  TeamOutlined,
  PieChartOutlined,
  BankOutlined,
  FilePdfOutlined,
  SolutionOutlined,
  DollarOutlined,
  FolderOpenOutlined,
  UsergroupAddOutlined,
  CalculatorOutlined,
  ProjectFilled,
  GoldOutlined,
  // OrderedListOutlined,
} from '@ant-design/icons';

// project imports
// import Loadable from "components/Loadable"
import AuthGuard from './AuthGuard';
import AdminLayout from 'layouts/AdminLayout';
import Dashboard from 'pages/Dashboard';

// import Posts from 'pages/Posts';
// import PostsAdd from 'pages/Posts/Add';

import Certificates from 'pages/Certificates';
import CertificatesAdd from 'pages/Certificates/Add';
import CertificatesEdit from 'pages/Certificates/Edit';

import GoodStandings from 'pages/GoodStandings';
import GoodStandingsView from 'pages/GoodStandings/View';

import Users from 'pages/Users';
import UsersView from 'pages/Users/View';
import PasswordReset from 'pages/Users/PasswordReset';

import Orders from 'pages/Orders';
import PaymentsView from 'pages/Orders/View';
import Business from 'pages/Business';
import BusinessView from 'pages/Business/View';

import Portfolio from 'pages/Portfolio';
import PortfolioAdd from 'pages/Portfolio/Add';
import PortfolioEdit from 'pages/Portfolio/Edit';
import PortfolioAssets from 'pages/Portfolio/Assets';
import PortfolioAssetsAdd from 'pages/Portfolio/Assets/Add';
import PortfolioAssetsAddBulk from 'pages/Portfolio/Assets/AddBulk';
import PortfolioAssetsEdit from 'pages/Portfolio/Assets/Edit';
import PortfolioAssetsView from 'pages/Portfolio/Assets/View';

import AssetsCertificates from 'pages/Portfolio/AssetsCertificates';
import AssetsCertificatesAdd from 'pages/Portfolio/AssetsCertificates/Add';
import AssetsCertificatesEdit from 'pages/Portfolio/AssetsCertificates/Edit';

import Shareholders from 'pages/Shareholders';
import ShareholdersAdd from 'pages/Shareholders/Add';
import ShareholdersEdit from 'pages/Shareholders/Edit';
import ShareholdersView from 'pages/Shareholders/View';

import Coupons from 'pages/Coupons';
import CouponsAdd from 'pages/Coupons/Add';
import CouponsEdit from 'pages/Coupons/Edit';
import Products from 'pages/Products';
import ProductsAdd from 'pages/Products/Add';
import ProductsEdit from 'pages/Products/Edit';
import BusinessShareholder from 'pages/BusinessShareholders';
import BusinessShareholderCorporateEdit from 'pages/BusinessShareholders/Edit/ShareholderCorporateEdit';
import BusinessShareholderIndividualEdit from 'pages/BusinessShareholders/Edit/ShareholderIndividualEdit';
import BusinessShareholderCorporateAdd from 'pages/BusinessShareholders/Add/ShareholderCorporateAdd';
import BusinessShareholderIndividualAdd from 'pages/BusinessShareholders/Add/ShareholderIndividualAdd';
// import BusinessTransfer from "pages/Business/Transfer";

import Assets from 'pages/Assets';
import AssetView from 'pages/Assets/View';

export const adminMenu = [
  {
    menu: true,
    path: '/',
    roles: ['admin'],
    label: 'Dashboard',
    icon: <PieChartOutlined />,
  },
  {
    menu: true,
    path: '/portfolio',
    roles: ['admin'],
    label: 'Portfolio',
    icon: <FolderOpenOutlined />,
    // children: [
    //   {
    //     path: "/portfolio",
    //     roles: ["admin"],
    //     label: "Portfolio",
    //     // icon: <FolderOpenOutlined />,
    //   },
    //   {
    //     path: "/assets/certificates",
    //     roles: ["admin"],
    //     label: "Portfolio Certificates",
    //     // icon: <FilePdfOutlined />,
    //   },
    // ],
  },
  {
    menu: true,
    path: '/assets/certificates',
    roles: ['admin'],
    label: 'Assets Certificates',
    icon: <FilePdfOutlined />,
  },
  {
    menu: true,
    path: '/shareholders',
    roles: ['admin'],
    label: 'Shareholders',
    icon: <UsergroupAddOutlined />,
  },
  {
    menu: true,
    path: '/business',
    roles: ['admin'],
    label: 'Business',
    icon: <BankOutlined />,
  },
  {
    menu: true,
    path: '/business-shareholders',
    roles: ['admin'],
    label: 'Business Shareholders',
    icon: <UsergroupAddOutlined />,
  },
  {
    menu: true,
    path: '/certificates',
    roles: ['admin'],
    label: 'Business Certificates',
    icon: <FilePdfOutlined />,
  },
  {
    menu: true,
    path: '/good-standings',
    roles: ['admin'],
    label: 'Good Standings',
    icon: <SolutionOutlined />,
  },
  {
    menu: true,
    path: '/orders',
    roles: ['admin'],
    label: 'Orders/Payments',
    icon: <DollarOutlined />,
  },
  {
    menu: true,
    path: '/users',
    roles: ['admin'],
    label: 'Users',
    icon: <TeamOutlined />,
  },
  {
    menu: true,
    path: '/coupons',
    roles: ['admin'],
    label: 'Coupons',
    icon: <CalculatorOutlined />,
  },
  {
    menu: true,
    path: '/products',
    roles: ['admin'],
    label: 'Products',
    icon: <ProjectFilled />,
  },
  // {
  //   menu: true,
  //   path: '/posts',
  //   roles: ['admin'],
  //   label: 'Posts',
  //   icon: <PushpinOutlined />,
  // },
  {
    menu: true,
    path: '/rwa-assets',
    roles: ['admin'],
    label: 'Assets',
    icon: <GoldOutlined />,
  },
];

const AdminRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <AdminLayout />
    </AuthGuard>
  ),
  // children: adminMenu
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: '/portfolio',
      element: <Portfolio />,
    },
    {
      path: '/portfolio/add',
      element: <PortfolioAdd />,
    },
    {
      path: '/portfolio/edit/:portfolioId',
      element: <PortfolioEdit />,
    },
    {
      path: '/portfolio/assets/:portfolioId',
      element: <PortfolioAssets />,
    },
    {
      path: '/portfolio/assets/:portfolioId/add',
      element: <PortfolioAssetsAdd />,
    },
    {
      path: '/portfolio/assets/:portfolioId/addBulk',
      element: <PortfolioAssetsAddBulk />,
    },
    {
      path: '/portfolio/assets/:portfolioId/edit/:assetId',
      element: <PortfolioAssetsEdit />,
    },
    {
      path: '/portfolio/assets/:portfolioId/view/:assetId',
      element: <PortfolioAssetsView />,
    },
    {
      path: '/assets/certificates',
      element: <AssetsCertificates />,
    },
    {
      path: '/assets/certificates/add',
      element: <AssetsCertificatesAdd />,
    },
    {
      path: '/assets/certificates/edit/:id',
      element: <AssetsCertificatesEdit />,
    },
    {
      path: '/shareholders',
      element: <Shareholders />,
    },
    {
      path: '/shareholders/add',
      element: <ShareholdersAdd />,
    },
    {
      path: '/shareholders/edit/:id',
      element: <ShareholdersEdit />,
    },
    {
      path: '/shareholders/view/:id',
      element: <ShareholdersView />,
    },
    {
      path: '/business',
      element: <Business />,
    },
    {
      path: '/business/view/:id',
      element: <BusinessView />,
    },
    {
      path: '/business-shareholders',
      element: <BusinessShareholder />,
    },
    {
      path: '/business-shareholders/Corporate/edit/:id',
      element: <BusinessShareholderCorporateEdit />,
    },
    {
      path: '/business-shareholders/Individual/edit/:id',
      element: <BusinessShareholderIndividualEdit />,
    },
    {
      path: '/business-shareholders/Corporate/add',
      element: <BusinessShareholderCorporateAdd />,
    },
    {
      path: '/business-shareholders/Individual/add',
      element: <BusinessShareholderIndividualAdd />,
    },
    // {
    //   path: "/business/transfer/:id",
    //   element: <BusinessTransfer />,
    // },
    {
      path: '/certificates',
      element: <Certificates />,
    },
    {
      path: '/certificates/add',
      element: <CertificatesAdd />,
    },
    {
      path: '/certificates/edit/:id',
      element: <CertificatesEdit />,
    },
    {
      path: '/good-standings',
      element: <GoodStandings />,
    },
    {
      path: '/good-standings/view/:id',
      element: <GoodStandingsView />,
    },
    {
      path: '/orders',
      element: <Orders />,
    },
    {
      path: '/orders/view/:id',
      element: <PaymentsView />,
    },
    {
      path: '/users',
      element: <Users />,
    },
    {
      path: '/users/view/:id',
      element: <UsersView />,
    },
    {
      path: '/users/password/:id',
      element: <PasswordReset />,
    },
    // {
    //   path: "/posts",
    //   element: <Posts />,
    // },
    // {
    //   path: "/posts/add",
    //   element: <PostsAdd />,
    // },
    {
      path: '/coupons',
      element: <Coupons />,
    },
    {
      path: '/coupons/add',
      element: <CouponsAdd />,
    },
    {
      path: '/coupons/edit/:id',
      element: <CouponsEdit />,
    },
    {
      path: '/products',
      element: <Products />,
    },
    {
      path: '/products/add',
      element: <ProductsAdd />,
    },
    {
      path: '/products/edit/:id',
      element: <ProductsEdit />,
    },
    {
      path: '/rwa-assets',
      element: <Assets />,
    },
    {
      path: '/rwa-assets/view/:id',
      element: <AssetView />,
    },
  ],
};

export default AdminRoutes;
