import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { selectWeb3, web3Actions } from 'features/web3Slice';
import { config } from 'config';
import { showLoader } from 'utils/loader';
import { selectAuth } from 'features/auth/authSlice';
import { getTokenMeta } from 'tokenMetadata';
import Web3 from 'web3';
import { ERC20ABI } from 'polygon/erc20.abi';
import { ERC721ABI } from 'polygon/erc721.abi';

const Web3Context = React.createContext();

export function useWeb3() {
  return useContext(Web3Context);
}

export function Web3Provider({ children }) {
  // https://docs.metamask.io/wallet/
  const { logged } = useSelector(selectAuth);
  const { currentAccount } = useSelector(selectWeb3);
  const dispatch = useDispatch();

  useEffect(() => {
    if (logged === true) {
      autoConnectWalletHandler();
      watchChainId();
      watchAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  const autoConnectWalletHandler = async () => {
    try {
      if (window?.ethereum) {
        // check if Metamask is installed
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const account = accounts[0];

        console.log(`Selected account is ${account}`);
        console.log('address', account);
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        }); // get chain id
        if (config.chainId !== chainId) {
          if (config.chainId === '0x89') {
            message.error(
              'Please Select Polygon on 🦊 Metamask. to auto connect',
            );
          }
          if (config.chainId === '0x13882') {
            message.error(
              'Please Select Polygon Amoy (Testnet) on 🦊 Metamask. to auto connect',
            );
          }
          return;
        }
        dispatch(web3Actions.loadAccount({ currentAccount: account }));
      } else {
        message.error(
          '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        );
        return {
          connectedStatus: false,
          status:
            '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        };
      }
    } catch (error) {
      message.error(
        'Please connect to Metamask using 🦊 button on the top right on your browser.',
      );
      return {
        connectedStatus: false,
        status:
          'Please connect to Metamask using 🦊 button on the top right on your browser.',
      };
    }
  };

  const connectWalletHandler = async () => {
    try {
      if (window?.ethereum) {
        // check if Metamask is installed
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const account = accounts[0];

        console.log(`Selected account is ${account}`);
        console.log('address', account);
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        }); // get chain id
        if (config.chainId !== chainId) {
          // if (config.chainId === "0x89") {
          //   message.error("Please Select Polygon on 🦊 Metamask.");
          // }
          // if (config.chainId === "0x13882") {
          //   message.error("Please Select Polygon Amoy (Testnet) on 🦊 Metamask.");
          // }
          return;
        }
        dispatch(web3Actions.loadAccount({ currentAccount: account }));
      } else {
        message.error(
          '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        );
        return {
          connectedStatus: false,
          status:
            '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        };
      }
    } catch (error) {
      message.error(
        'Please connect to Metamask using 🦊 button on the top right on your browser.',
      );
      return {
        connectedStatus: false,
        status:
          'Please connect to Metamask using 🦊 button on the top right on your browser.',
      };
    }
  };

  const connectWallet = async () => {
    try {
      if (window?.ethereum) {
        // check if Metamask is installed
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const account = accounts[0];

        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        }); // get chain id
        if (config.chainId !== chainId) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: config.chainId,
              },
            ],
          });
          // if (config.chainId === "0x89") {
          //   message.error("Please Select Polygon on 🦊 Metamask.");
          // }
          // if (config.chainId === "0x13882") {
          //   message.error("Please Select Polygon Amoy (Testnet) on 🦊 Metamask.");
          // }
          return;
        }
        dispatch(web3Actions.loadAccount({ currentAccount: account }));
      } else {
        message.error(
          '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        );
        return {
          connectedStatus: false,
          status:
            '🦊 You must install Metamask into your browser: https://metamask.io/download.html',
        };
      }
    } catch (error) {
      message.error(
        'Please connect to Metamask using 🦊 button on the top right on your browser.',
      );
      return {
        connectedStatus: false,
        status:
          'Please connect to Metamask using 🦊 button on the top right on your browser.',
      };
    }
  };

  const watchChainId = async () => {
    if (window?.ethereum) {
      window.ethereum.on('chainChanged', function (chainId) {
        console.log('chainChanged', chainId);
        if (config.chainId !== chainId) {
          if (config.chainId === '0x89') {
            message.error('Please Select Polygon on 🦊 Metamask.');
          }
          if (config.chainId === '0x13882') {
            message.error(
              'Please Select Polygon Amoy (Testnet) on 🦊 Metamask.',
            );
          }
          // Handle the new chain.
          // Correctly handling chain changes can be complicated.
          // We recommend reloading the page unless you have good reason not to.
        }
        window.location.reload();
      });
    }
  };

  const watchAccount = () => {
    if (window?.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        console.log('accountsChanged', accounts);
        dispatch(web3Actions.loadAccount({ currentAccount: accounts[0] }));
      });
    }
  };

  const safeMint = async (tokenId, contractAddress) => {
    try {
      if (!currentAccount) {
        connectWalletHandler();
      }
      if (window?.ethereum) {
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        }); // get chain id
        if (config.chainId !== chainId) {
          if (config.chainId === '0x89') {
            message.error({
              content: 'Please Select Polygon on 🦊 Metamask.',
              key: 'meta-mask',
              duration: 15,
            });
          }
          if (config.chainId === '0x13882') {
            message.error({
              content: 'Please Select Polygon Amoy (Testnet) on 🦊 Metamask.',
              key: 'meta-mask',
              duration: 15,
            });
          }
          return;
        }
        showLoader(true);
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.
        message.loading({
          content: 'Connecting to 🦊 Metamask',
          key: 'meta-mask',
        });

        // It also provides an opportunity to request access to write
        // operations, which will be performed by the private key
        // that MetaMask manages for the user.
        message.loading({ content: 'Opening 🦊 Metamask', key: 'meta-mask' });

        const web3 = new Web3(window.ethereum);
        // Create a contract instance
        const contract = new web3.eth.Contract(ERC721ABI, contractAddress);

        console.log('tokenId', tokenId);
        console.log('contractAddress', contractAddress);
        console.log(`Selected account is ${currentAccount}`);
        try {
          const owner = await contract.methods.ownerOf(tokenId).call();
          console.log('owner', owner);
          if (owner) {
            // if (owner.toLowerCase() === currentAccount.toLowerCase()) {
            //   message.error({
            //     content: 'You already own this NFT',
            //     key: 'meta-mask',
            //     duration: 15,
            //   });
            //   showLoader(false);
            //   return;
            // }
            message.error({
              content:
                'This Asset is already minted, Please ask developer for help ',
              key: 'meta-mask',
              duration: 15,
            });
            showLoader(false);
            return;
          }
        } catch (error) {
          console.log('error', error);
        }
        // console.log("owner", owner);
        // return
        // const balance = await contract.balanceOf(currentAccount, tokenId);
        // console.log("balance", balance);
        // const balanceFormatted = formatUnits(balance, "wei");
        // if (balanceFormatted > 0) {
        //   throw Error("Business already deployed");
        // }
        // const gas = await web3.eth.getGasPrice();

        // Mint Business
        const txData = await contract.methods
          .safeMint(currentAccount, tokenId, String(tokenId).toString())
          .send({
            from: currentAccount,
            // gas: gas,
            // gas: 1000000,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          });

        // const tx = await contract.methods.safeMint(
        //   currentAccount,
        //   tokenId,
        //   String(tokenId).toString(),
        // );
        // const result = await tx.wait();
        // console.log('mint result', result);
        // console.log('mint result', JSON.stringify(result, null, 2));
        // return result;
        // console.log('txData', txData);
        const receiptJson = JSON.parse(
          JSON.stringify(txData, (key, value) => {
            if (typeof value === 'bigint') {
              return value.toString();
            }
            return value;
          }),
        );
        console.log('receiptJson', receiptJson);

        return {
          ...receiptJson,
          hash: receiptJson.transactionHash,
          owner: currentAccount,
        };
      }
    } catch (e) {
      message.error({
        content: e?.info?.error?.message || e.message,
        key: 'meta-mask',
        duration: 15,
      });
      console.error('error', e?.message);
      // console.error('error.info', e?.info);
    } finally {
      showLoader(false);
    }
  };

  const getERC20Token = async (symbol) => {
    try {
      if (!currentAccount) {
        // connectWalletHandler();
      }
      if (window?.ethereum) {
        const chainId = await window.ethereum.request({
          method: 'eth_chainId',
        }); // get chain id
        if (config.chainId !== chainId) {
          if (config.chainId === '0x89') {
            message.error({
              content: 'Please Select Polygon on 🦊 Metamask.',
              key: 'meta-mask',
              duration: 15,
            });
          }
          if (config.chainId === '0x13882') {
            message.error({
              content: 'Please Select Polygon Amoy (Testnet) on 🦊 Metamask.',
              key: 'meta-mask',
              duration: 15,
            });
          }
          return;
        }
        showLoader(true);
        // Connect to the MetaMask EIP-1193 object. This is a standard
        // protocol that allows Ethers access to make all read-only
        // requests through MetaMask.

        const tokenMetaData = getTokenMeta(symbol);
        const web3 = new Web3(window.ethereum);
        // Create a contract instance
        const contract = new web3.eth.Contract(
          ERC20ABI,
          tokenMetaData.contractAddress,
        );

        console.log('tokenMetaData', tokenMetaData);
        console.log('contractAbi', ERC20ABI);
        console.log('contractAddress', tokenMetaData.contractAddress);
        console.log(`Selected account is ${currentAccount}`);
        const balance = await contract.methods.balanceOf(currentAccount).call();
        // const owner = await contract.ownerOf(tokenId);
        // const symbol = await contract.symbol();
        // const name = await contract.name();
        // const decimals = await contract.decimals();
        // console.log("owner", owner);
        // console.log('balance', balance);
        // console.log('decimals', decimals);
        console.log('balance', balance);
        dispatch(
          web3Actions.loadBalance({
            name: tokenMetaData.name,
            symbol: tokenMetaData.symbol,
            decimals: Number(tokenMetaData.decimals),
            balance: Number(balance),
          }),
        );
      }
    } catch (e) {
      // message.error({
      //   content: e?.info?.error?.message || e.message,
      //   key: 'meta-mask',
      //   duration: 15,
      // });
      console.error('error', e.message);
      // console.error('error.info', e?.info);
    } finally {
      showLoader(false);
    }
  };

  return (
    <Web3Context.Provider
      value={{
        connectWalletHandler,
        connectWallet,
        safeMint,
        // getBusinessOwner,
        // mintPortfolio,
        // mintBusiness,
        getERC20Token,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
}
