import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Descriptions, Skeleton, Card } from 'antd';
import { useBackend } from 'backend/useBackend';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'shareholder';

const Shareholder = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title={`Id: ${id}`}>
          <Descriptions title="Basic Info" layout="vertical" bordered>
            <Descriptions.Item label="Corporate/Business Name">
              {item.businessName}
            </Descriptions.Item>
            <Descriptions.Item label="Filing Number">
              {item.filingNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Share">{item.share}%</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item.state}</Descriptions.Item>
            <Descriptions.Item label="Zip Code">
              {item.zipCode}
            </Descriptions.Item>
            <Descriptions.Item label="Country">
              {item.country}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="System Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
            <Descriptions.Item label="Created At">
              <DateTimeAgo date={item.createdAt} onlyDate />
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At">
              <DateTimeAgo date={item.updatedAt} onlyDate />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </Content>
  );
};

export default Shareholder;
