import { createSlice } from '@reduxjs/toolkit';
import { countriesOptions } from 'data/countries';
import { fulfilled, loading } from 'features/status';

const initialState = {
  dashboard: {
    status: loading,
    portfolio: 0,
    portfolioAssets: 0,
    business: 0,
    assetCertificates: 0,
    certificates: 0,
    payments: 0,
    users: 0,
  },
  statuses: [
    { value: 'Draft', label: 'Draft' },
    // { value: "Pending", label: "Pending" },
    { value: 'Publish', label: 'Publish' },
    // { value: "Trash", label: "Trash" },
  ],
  paymentStatusOptions: [
    { value: 'Pending', label: 'Pending' },
    { value: 'Awaiting', label: 'Awaiting' },
    { value: 'Confirmed', label: 'Confirmed' },
  ],
  booleanOptions: [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ],
  certificateStatus: [
    { value: 'Active', label: 'Active' },
    // { value: 'Pending' , label: 'Pending'},
    // { value: 'Blocked' , label: 'Blocked'},
    { value: 'Disabled', label: 'Disabled' },
  ],
  businessType: [
    { value: 'TRUST', label: 'Blockchain Trust' },
    { value: 'CORP', label: 'Blockchain Corporate' },
    { value: 'FAMILY', label: 'Blockchain Family Trust' },
    { value: 'BANK', label: 'Blockchain Bank' },
    { value: 'INSURANCE', label: 'Captive Insurance' },
  ],
  shareholderTypes: [
    { label: 'Corporate Shareholder', value: 'Corporate' },
    { label: 'Individual Shareholder', value: 'Individual' },
  ],
  maritalStatus: [
    { label: 'Married', value: 'Married' },
    { label: 'Single', value: 'Single' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed', value: 'Widowed' },
  ],
  shareholderCount: [
    { value: 0, label: 'Not Applicable' },
    { value: 1, label: 'One Shareholder' },
    { value: 2, label: 'Two Shareholders' },
  ],
  btEntityEndings: [
    { value: 'Blockchain Trust', label: 'Blockchain Trust' },
    { value: 'BT', label: 'BT' },
  ],
  corpEntityEndings: [
    // { value: "ALL", label: "N/A or ALL" },
    { value: 'Aktiengesellschaft', label: 'Aktiengesellschaft' },
    { value: 'AG', label: 'AG' },
    { value: 'Ltd', label: 'Ltd' },
    { value: 'IBC', label: 'IBC' },
    { value: 'Corp', label: 'Corp' },
    { value: 'Inc.', label: 'Inc.' },
    { value: 'Co.', label: 'Co.' },
    { value: 'BV', label: 'BV' },
    { value: 'NV', label: 'NV' },
    { value: 'Gmbh', label: 'Gmbh' },
    { value: 'PC', label: 'PC' },
    { value: 'LLC', label: 'LLC' },
    { value: 'PLC', label: 'PLC' },
    { value: 'PLLC', label: 'PLLC' },
    { value: 'LLP', label: 'LLP' },
    { value: 'REIT', label: 'REIT' },
    { value: 'VT', label: 'VT' },
    { value: 'Sociedad Anónima', label: 'Sociedad Anónima' },
    { value: 'SA', label: 'SA' },
    {
      value: 'Sociedad de Responsabilidad Limitada',
      label: 'Sociedad de Responsabilidad Limitada',
    },
    { value: 'SRL', label: 'SRL' },
    { value: 'Conglomerate', label: 'Conglomerate' },
    { value: 'Cooperative', label: 'Cooperative' },
    {
      value: 'Decentralized Autonomous Organization',
      label: 'Decentralized Autonomous Organization',
    },
    { value: 'DAO', label: 'DAO' },
    { value: 'DAC', label: 'DAC' },
    { value: 'Association', label: 'Association' },
    { value: 'Club', label: 'Club' },
    { value: 'Foundation', label: 'Foundation' },
    { value: 'Fund', label: 'Fund' },
    { value: 'Institute', label: 'Institute' },
    { value: 'Society', label: 'Society' },
    { value: 'Union', label: 'Union' },
    { value: 'Syndicate', label: 'Syndicate' },
    { value: 'Eingetragener Verein', label: 'Eingetragener Verein' },
    { value: 'EV', label: 'EV' },
  ],
  familyEntityEndings: [
    { value: 'BLOCKCHAIN FAMILY TRUST', label: 'BLOCKCHAIN FAMILY TRUST' },
  ],
  bankEntityEndings: [{ value: 'BLOCKCHAIN BANK', label: 'BLOCKCHAIN BANK' }],
  insuranceEntityEndings: [
    {
      value: 'Captive Insurance Blockchain Trust',
      label: 'Captive Insurance Blockchain Trust',
    },
    {
      value: 'Captive Insurance Corporation',
      label: 'Captive Insurance Corporation',
    },
    { value: 'Captive Insurance Limited', label: 'Captive Insurance Limited' },
    { value: 'Captive Insurance LLP', label: 'Captive Insurance LLP' },
    { value: 'Captive Insurance LLC', label: 'Captive Insurance LLC' },
    {
      value: 'Captive Insurance Cooperative',
      label: 'Captive Insurance Cooperative',
    },
    {
      value: 'Captive Insurance Consortium',
      label: 'Captive Insurance Consortium',
    },
    { value: 'Captive Insurance Group', label: 'Captive Insurance Group' },
  ],
  countriesOptions: countriesOptions(),
  loaded: false,
  couponDiscountTypeOptions: [
    { value: 'Fixed', label: 'Fixed' },
    { value: 'Percentage', label: 'Percentage' },
  ],
  couponUserRuleOptions: [
    { value: 'AllUsers', label: 'All Users' },
    { value: 'SelectedUsers', label: 'Selected Users' },
    { value: 'PerUser', label: 'Per User' },
  ],
  couponProductRuleOptions: [
    { value: 'AllProducts', label: 'All Products' },
    { value: 'SelectedProducts', label: 'Selected Products' },
    // { value: "PerProduct", label: "Per Product" },
  ],
  couponStatusOptions: [
    { value: 'Active', label: 'Active' },
    { value: 'Hold', label: 'Hold' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Expired', label: 'Expired' },
  ],
  productTypeOptions: [
    { value: 'Trust', label: 'Trust' },
    { value: 'Corporate', label: 'Corporate' },
    { value: 'Family', label: 'Family' },
    { value: 'Bank', label: 'Bank' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'GoodStanding', label: 'Good Standing' },
    { value: 'Agent', label: 'Agent' },
    { value: 'AgentRenew', label: 'AgentRenew' },
  ],
  productModuleOptions: [
    { value: 'Individual', label: 'Individual' },
    { value: 'Reseller', label: 'Reseller' },
  ],
  productStatusOptions: [
    { value: 'Publish', label: 'Publish' },
    { value: 'Pending', label: 'Pending' },
  ],
  assetVerificationOptions: [
    { value: 'true', label: 'Verified' },
    { value: 'false', label: 'Pending' },
  ],
  assetMintingOptions: [
    { value: 'true', label: 'Minted' },
    { value: 'false', label: 'Pending' },
  ],
};

export const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    testAction: (state) => {
      state.loaded = true;
    },
    loadDashboard: (state, action) => {
      state.dashboard = { ...action.payload, status: fulfilled };
    },
  },
});

export const { testAction, loadDashboard } = cmsSlice.actions;
export const cmsActions = cmsSlice.actions;
export const selectCMS = (state) => state.cms;

// export default cmsSlice.reducer;
