const shareholderType = {
  Corporate: 'Corporate',
  Individual: 'Individual',
};

const paymentStatus = {
  Awaiting: 'Awaiting',
  Canceled: 'Canceled',
  Confirmed: 'Confirmed',
  Expired: 'Expired',
  Failed: 'Failed',
  Pending: 'Pending',
  Processing: 'Processing',
  Success: 'Success',
};

const paymentButtonVisibility = ['Pending', 'Expired', 'Canceled', 'Failed'];

export { paymentStatus, shareholderType, paymentButtonVisibility };

export const cdnImg = {
  portfolio: 'portfolio/logo-portfolio.jpg',
};

export const MESSAGES = {
  verifyFail: 'Failed to verify the user',
  mintFail: 'Failed to mint Tokens! Try again later',
  deleting: 'Deleting...',
  deletionSuccess: 'Deleted successfully.',
  defaultError: 'Something went wrong, please try again later',
};

export const STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  VERIFY: 'Verify',
  MINT: 'Mint',
  VIEW: 'View',
  INITIAL: 'Initial',
};

export const API_ENDPOINTS = {
  assets: 'assets',
  assetsMint: 'assets/mint',
  allAssets: 'assets/all',
};
