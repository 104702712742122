export const isNumber = (value) => !Number.isNaN(Number(value));

export const calculateDiscount = (value, discount) => {
  const val = isNumber(value) ? value : 0;
  const disc = isNumber(discount) ? discount : 0;
  return val * (disc / 100);
};

export const discountedPrice = (value, discount) => {
  const val = isNumber(value) ? value : 0;
  const disc = isNumber(discount) ? discount : 0;
  return val - val * (disc / 100);
};

export const priceWthSymbol = (
  value,
  currencyISO = 'USD',
  language = 'en-Us',
) => {
  const val = isNumber(value) ? value : 0;
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyISO.toUpperCase(), // 'INR'
  }).format(val);
};

export const formatCurrency = (
  value,
  currencyISO = 'USD',
  signDisplay = 'auto',
  maximumFractionDigits = 4,
  locales = 'en-Us',
) => {
  const val = isNumber(value) ? value : 0;
  return new Intl.NumberFormat(locales, {
    style: 'currency',
    // currencyDisplay: 'name',
    // minimumSignificantDigits: 4,
    // maximumSignificantDigits: 4,
    // minimumFractionDigits: 5,
    signDisplay,
    maximumFractionDigits,
    currency: currencyISO.toUpperCase(), // 'INR'
  }).format(val);
};

export const formatNumber = (value, locales = 'en-Us') => {
  const val = isNumber(value) ? value : 0;
  return new Intl.NumberFormat(locales).format(val);
};

export const formatCryptoCurrency = (
  value,
  currencyISO = 'BTC',
  signDisplay = 'auto',
  maximumFractionDigits = 4,
  locales = 'en-Us',
) => {
  const val = isNumber(value) ? value : 0;
  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currencyDisplay: 'name',
    // minimumSignificantDigits: 4,
    // maximumSignificantDigits: 4,
    // minimumFractionDigits: 5,
    signDisplay,
    maximumFractionDigits,
    currency: currencyISO.toUpperCase(), // 'INR'
  }).format(val);
};

export const formatDiscount = (value, type) => {
  const val = isNumber(value) ? value : 0;
  if (type === 'RATE') {
    return `${val}%`;
  } else {
    return val;
  }
};
