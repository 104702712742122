// https://ant.design/theme-editor#Color

const customTheme = {
  token: {
    // colorPrimary: "#101010",
    colorLink: '#000000',
    colorBorder: '#b0b0b0',
    colorIcon: '#505050',
    colorTextPlaceholder: '#505050',
  },
};

export { customTheme };
