import { Table, Layout, Card, Modal, Flex, message } from 'antd';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';

import { usePaginate } from 'backend/usePaginate';
import useCopiedStatus from 'hooks/useCopiedStatus';
import FilterPanel from 'components/FilterPanel';
import { selectCMS } from 'features/cms/cmsSlice';
import { useBackend } from 'backend/useBackend';
import { STATUS, MESSAGES, API_ENDPOINTS } from 'utils/constants';
import { getColumns } from 'components/AssetsTable';
import { MintFail, MintSuccess, Processing } from 'components/MintLoader';

const { Content } = Layout;
const { SUCCESS, FAIL, MINT, VERIFY, INITIAL } = STATUS;
const { deleting, deletionSuccess } = MESSAGES;
const { assets, assetsMint, allAssets } = API_ENDPOINTS;

const initialMintStatus = {
  status: INITIAL,
  content: '',
};

const Assets = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mintStatus, setMintStatus] = useState(initialMintStatus);
  const [currentItem, setCurrentItem] = useState(null);
  const { assetVerificationOptions, assetMintingOptions } =
    useSelector(selectCMS);
  const { trashItem, updateAssetAction } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(allAssets, {
    limit: 100,
    orderBy: 'id',
  });
  const { copiedStatus, handleCopyToClipboard } = useCopiedStatus();

  const copyInfo = async (text, key) => {
    await handleCopyToClipboard(text, key);
  };

  const handleAction = async (row, action, index) => {
    switch (action) {
      case VERIFY: {
        const data = await updateAssetAction(
          `${assets}/${row.id}`,
          {
            index,
            id: row.id,
            values: { isVerified: true },
            type: STATUS.VERIFY,
          },
          {},
        );
        if (data.error) message.error(data.error);
        break;
      }
      case MINT: {
        setMintStatus(initialMintStatus);
        setCurrentItem(row.id);
        setModalOpen(true);
        const data = await updateAssetAction(
          `${assetsMint}/${row.id}`,
          { index, id: row.id, values: { isMinted: true }, type: STATUS.MINT },
          {},
        );
        setMintStatus({
          status: data.hash ? SUCCESS : FAIL,
          content: data.hash ? data.hash : data.error,
        });
        setCurrentItem(null);
        break;
      }
      default:
        break;
    }
  };

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: deleting, success: deletionSuccess },
    };
    trashItem(assets, item.id, config);
  };
  const handleCancel = () => {
    setModalOpen(false);
  };
  return (
    <Content>
      <Card title="Users">
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'userId',
                label: 'User ID',
              },
              {
                key: 'email',
                label: 'Email',
              },
              {
                key: 'account',
                label: 'Wallet Address',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Asset Verification status',
              dbKey: 'isVerified',
              options: assetVerificationOptions,
            },
            {
              placeholder: 'Tokens Minting status',
              dbKey: 'isMinted',
              options: assetMintingOptions,
            },
          ]}
          dateRangeControl
          handleFilter={filter}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={getColumns(
              handleAction,
              deleteConfirm,
              currentItem,
              copyInfo,
              copiedStatus,
            )}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <Modal
        open={modalOpen}
        footer={null}
        closable={!currentItem}
        onCancel={handleCancel}
      >
        <div style={{ padding: 20 }}>
          <Flex justify="center" align="center" vertical>
            {mintStatus.status === INITIAL ? (
              <Processing />
            ) : mintStatus.status === SUCCESS ? (
              <MintSuccess txHash={mintStatus.content} />
            ) : (
              <MintFail errorMessage={mintStatus.content} />
            )}
          </Flex>
        </div>
      </Modal>
    </Content>
  );
};

export default Assets;
