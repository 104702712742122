import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  Table,
  Layout,
  Space,
  Card,
  Badge,
  Modal,
  Typography,
  FloatButton,
  Image,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  DiffOutlined,
} from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { useWeb3 } from "contexts/Web3Context"
import { usePaginate } from 'backend/usePaginate';
import { getBadgeColor } from 'utils/labels';
import { useBackend } from 'backend/useBackend';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';
import { cdnImg } from 'utils/constants';

import polygonIcon from 'assets/svg/polygon-matic.png';
import polygonMatic from 'assets/svg/matic.png';
import { paginationAction } from 'features/pagination/paginationSlice';
import { config } from 'config';
import { formatNumber } from 'utils/currency';
import DateTimeAgo from 'components/DateTimeAgo';

const { Text } = Typography;
const { Content } = Layout;

const endpoint = 'portfolio';
const contractExplorerUrl = config.contractExplorerUrl;
const portfolioContractAddress = config.portfolioContractAddress;
const openSeaUrl = config.openSeaUrl;

const Portfolio = () => {
  const dispatch = useDispatch();
  const { statuses } = useSelector(selectCMS);
  const navigation = useNavigate();
  const { trashItem, s3GetUrl, updateItem } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
    // orderBy: "createdAt",
    // order: "desc",
    orderBy: 'tokenId',
    order: 'asc',
  });

  const { safeMint } = useWeb3();

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    if (item.count > 0) {
      Modal.warning({
        title: 'This Portfolio can not be delete!',
        content: (
          <>
            <div>
              <b>Portfolio must be empty to delete.</b>
            </div>
            <small>
              You have to delete all {item.count} business belongs to this
              portfolio.
            </small>
          </>
        ),
        centered: true,
        okText: 'Ok',
        cancelButtonProps: {
          danger: true,
        },
        okButtonProps: {
          danger: true,
        },
      });
      return;
    }
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.businessName} {item.entityEnding}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const mintConfirm = (item) => {
    Modal.confirm({
      icon: (
        <img
          src={polygonMatic}
          alt=""
          style={{
            width: '64px',
            height: '64px',
            // padding: "4px",
            marginRight: '10px',
            // border: "2px solid #8247e5",
            borderRadius: '50%',
          }}
        />
      ),
      title: 'Are you ready to deploy portfolio?',
      content: (
        <div className="py-10">
          <b>{item.title}</b>
        </div>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => {
        sendPortfolio(item);
        //trashItem(endpoint, item.id, config),
      },
      okText: 'Yes',
      // cancelButtonProps: {
      //   danger: true,
      // },
      // okButtonProps: {
      //   danger: true,
      // },
    });
  };

  const sendPortfolio = (item) => {
    safeMint(item.tokenId, portfolioContractAddress).then((receipt) => {
      if (receipt?.hash) {
        updateItem(`${endpoint}/block`, item.id, {
          ...JSON.parse(JSON.stringify(receipt)),
          owner: receipt.from,
          transactionHash: receipt.hash,
        }).then(() => {
          dispatch(
            paginationAction.updateById({
              id: item.id,
              item: {
                ...JSON.parse(JSON.stringify(receipt)),
                owner: receipt.from,
                transactionHash: receipt.hash,
              },
            }),
          );
        });
      }
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'tokenId',
      key: 'tokenId',
    },
    {
      key: 'thumbPath',
      title: 'Thumb',
      render: (thumbPath, row) => {
        return (
          <Image
            src={s3GetUrl(row?.thumbPath || cdnImg.portfolio)}
            width={'50px'}
            // preview={false}
            style={{
              border: '1px solid #666',
              borderRadius: '5px',
            }}
          />
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'title'),
      // sortDirections: ['descend']
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      // className: "w-60",
      render: (description, row) => (
        <>
          <Text style={{ maxWidth: '40em' }}>{description}</Text>
          {row.transactionHash && (
            <>
              <br />
              {/* <small className="text-gray-600">
                <a
                  href={`${contractExplorerUrl}/tx/${row.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View it On BlockChain Explorer</b>
                </a>
              </small> */}
              <small className="text-gray-600">
                <a
                  href={`${contractExplorerUrl}/token/${portfolioContractAddress}?a=${row?.tokenId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View Token it On BlockChain Explorer</b>
                </a>
              </small>
              <br />
              <small className="text-gray-600">
                <a
                  href={`${openSeaUrl}/${portfolioContractAddress}/${row?.tokenId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View it On Open Sea</b>
                </a>
              </small>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Shareholder',
      dataIndex: 'id',
      key: 'shareholder',
      render: (id, row) => row?.shareholder?.businessName ?? 'N/A',
    },
    {
      title: 'Assets Count',
      dataIndex: 'count',
      key: 'count',
      render: (count, row) => (
        <>
          {formatNumber(row?._count?.assets)} of {formatNumber(count)}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <DateTimeAgo date={createdAt} />,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          {!row?.transactionHash && (
            <Link to="#" className="p-0" onClick={() => mintConfirm(row)}>
              <img
                src={polygonIcon}
                className="polygon"
                style={{ width: '1.125rem' }}
                alt=""
              />
            </Link>
          )}
          <Link
            to={{
              pathname: `assets/${id}/addBulk`,
            }}
          >
            <DiffOutlined
              className="text-green-500"
              title="Add Bulk Assets to Portfolio"
            />
          </Link>
          {/* <Link
            to={{
              pathname: `edit/${id}/shareholder`,
            }}
          >
            <UserSwitchOutlined
              className="text-red-600"
              title="Update Shareholder of All Assets to Portfolio"
            />
          </Link> */}
          {/* <Link
            to={{
              pathname: `assets/${id}/add`,
            }}
          >
            <PlusSquareOutlined
              className="text-green-500"
              title="Add Asset to Portfolio"
            />
          </Link> */}
          <Link
            to={{
              pathname: `assets/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Portfolio">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'title',
                label: 'Title',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Status...',
              dbKey: 'status',
              options: statuses,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton
        type="primary"
        tooltip="Add New Portfolio"
        icon={<PlusOutlined />}
        onClick={() => navigation('add')}
      />
    </Content>
  );
};

export default Portfolio;
