import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Layout, Descriptions, Skeleton, Card, Row, Col } from 'antd';
import { useBackend } from 'backend/useBackend';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'users';

const UserInfo = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => setItem(item));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card title={`User Id: ${id}`}>
          <Row gutter={[20, 20]}>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <Descriptions title="Basic Info" layout="vertical" bordered>
                <Descriptions.Item label="First Name">
                  {item.firstName}
                </Descriptions.Item>
                <Descriptions.Item label="Last Name">
                  {item.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Full Name">
                  {item.firstName} {item.lastName}
                </Descriptions.Item>
                {/* <Descriptions.Item label="DoB">{item.dob}</Descriptions.Item> */}
                {/* <Descriptions.Item label="Gender">
                {item.gender}
              </Descriptions.Item> */}
              </Descriptions>
              <br />
              <Descriptions title="Roles" layout="vertical" bordered>
                {/* {(item.roles || []).map((role, i) => (
              <Descriptions.Item label={`Role ${i+1}`}>{role.role}</Descriptions.Item>
            ))} */}
                <Descriptions.Item label="Roles">
                  {item.rolesString}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="Contact Info" layout="vertical" bordered>
                {/* <Descriptions.Item label="Mobile">{item?.mobile ?? '---'}</Descriptions.Item> */}
                <Descriptions.Item label="Email">
                  {item.email}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions
                title="Notification Settings"
                layout="vertical"
                bordered
              >
                <Descriptions.Item label="Notification Frequency">
                  {item.noticeFrequency}
                </Descriptions.Item>
              </Descriptions>
              <br />
              <Descriptions title="System Details" layout="vertical" bordered>
                {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
                <Descriptions.Item label="Joined At">
                  <DateTimeAgo date={item.createdAt} onlyDate />
                </Descriptions.Item>
                <Descriptions.Item label="Last Updated At">
                  <DateTimeAgo date={item.updatedAt} onlyDate />
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <Descriptions
                title={`Total ${item?.business.length} Business Registered`}
                layout="vertical"
                bordered
              >
                <Descriptions.Item label="Business Full Name" span={3}>
                  {item?.business.map((b, i) => (
                    <div className="font-bold">
                      {i + 1}.{' '}
                      <Link to={`/business/view/${b.id}`} className="underline">
                        {b.businessFullName}
                      </Link>
                    </div>
                  ))}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Card>
      )}
    </Content>
  );
};

export default UserInfo;
