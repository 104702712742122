import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Col, Row, Card, Layout, Statistic } from "antd";
import {
  TeamOutlined,
  DollarOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  BankOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { formatCurrency } from "@coingecko/cryptoformat";

import { selectAuth } from "features/auth/authSlice";
import { loadDashboard, selectCMS } from "features/cms/cmsSlice";
import { useBackend } from "backend/useBackend";
import { loading } from "features/status";
import { useWeb3 } from "contexts/Web3Context"
import { selectWeb3 } from "features/web3Slice";

const { Content } = Layout;

const Dashboard = () => {
  const { currentAccount, tokens } = useSelector(selectWeb3);
  const dispatch = useDispatch();
  const { dashboard } = useSelector(selectCMS);
  const { getList } = useBackend();
  const { getERC20Token } = useWeb3();
  const { user } = useSelector(selectAuth);

  useEffect(() => {
    getList("statistics/dashboard").then(({ stats }) => {
      console.log(stats);
      dispatch(loadDashboard(stats));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentAccount) {
      setTimeout(() => {
        getERC20Token('BBSC');
      }, 2000);
      setTimeout(() => {
        getERC20Token('LTSC');
      }, 2000);
      setTimeout(() => {
        getERC20Token('BGSC');
      }, 2000);
      // getERC20Token('BBSC');
      // getERC20Token('LTSC');
      // getERC20Token('BGSC');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  return (
    <Content>
      <h2 className="md:pb-4 text-2xl">Welcome, {user.firstName}</h2>
      <Row gutter={[16, 16]} className="mt-4">
        <Col span={24}>
          <b>Business Stats</b>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="business">Registered Business</Link>}
              // title="Registered Business"
              value={dashboard.business}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<BankOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="certificates">Business Certificates</Link>}
              // title="Certificates"
              value={dashboard.certificates}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<FilePdfOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="orders">Order/Payments</Link>}
              // title="Transactions/Payments"
              value={dashboard.payments}
              precision={0}
              // valueStyle={{
              //   color: "#3f8600",
              // }}
              prefix={<DollarOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="users">Users</Link>}
              // title="Users"
              value={dashboard.users}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<TeamOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-4">
        <Col span={24}>
          <b>Portfolio Stats</b>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="portfolio">Portfolio</Link>}
              // title="Portfolio"
              value={dashboard.portfolio}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<FolderOpenOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title={<Link to="assets/certificates">Assets Certificates</Link>}
              // title="Certificates"
              value={dashboard.assetCertificates}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<FilePdfOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 6 }}>
          <Card>
            <Statistic
              title="Portfolio Assets"
              value={dashboard.portfolioAssets}
              precision={0}
              // valueStyle={{
              //   color: "#cf1322",
              // }}
              prefix={<FileTextOutlined className="stats-icon" />}
              // suffix="%"
              loading={dashboard.status === loading}
            />
          </Card>
        </Col>
      </Row>
      {tokens.length > 0 && (
        <Row gutter={[16, 16]} className="mt-4">
          <Col span={24}>
            <b>ERC-20 Tokens</b>
          </Col>
          {/* <pre>{JSON.stringify(token, null, 2)}</pre> */}
          {tokens.map((token) => (
            <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} key={token.symbol}>
              <Card>
                <Statistic
                  title={token.name}
                  // value={formatCurrency(Number(balance) / 10 ** 18, "BBC")}
                  value={formatCurrency(
                    token.balance / 10 ** token.decimals,
                    token.symbol
                  )}
                  precision={0}
                  prefix={
                    <img
                      src={`https://s3.amazonaws.com/cdn.blockchainbankcoin.io/${token.symbol}-64.png`}
                      className="w-16 h-16"
                      alt={token.symbol}
                    />
                  }
                  // suffix="%"
                  // loading={dashboard.status === loading}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Content>
  );
};

export default Dashboard;
