import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Layout, Space, Card, Badge, Modal } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { usePaginate } from 'backend/usePaginate';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';
import DateTimeAgo from 'components/DateTimeAgo';
import { usePrice } from 'hooks/usePrice';
import { camelCaseToSentence, getBadgeColor } from 'utils/labels';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';

const { Content } = Layout;
const endpoint = 'order';

const Orders = () => {
  const { price } = usePrice();
  const { paymentStatusOptions } = useSelector(selectCMS);
  const { trashItem } = useBackend();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
  });

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete <b>{item.title}</b>?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Order Details',
      dataIndex: 'title',
      key: 'title',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'title'),
      // sortDirections: ['descend']
      render: (title, row) => (
        <div>
          <div>
            By{' '}
            <b>
              <Link to={`/users/view/${row?.user?.id}`}>
                {row?.user?.email}
              </Link>
            </b>
          </div>
          {/* <div className="font-bold text-gray-600 text-lg">{title}</div> */}
          <div className="font-bold text-gray-600 text-xs mt-1">
            OrderId: {row?.id}
          </div>
          {/* <br /> */}
          <div className="font-bold text-xs text-gray-400 mt-1">
            Order Type: {row?.type}
          </div>
          <div className="my-2">
            {row?.items?.length > 0 && (
              <div className="border-t-2 border-gray-200 py-2">
                {row.items.map((item, i) => (
                  <div key={i} className="flex justify-between">
                    <div>
                      <div className="font-bold">{item.title}</div>
                      <div className="text-xs">{item.name}</div>
                    </div>
                    <span className="font-bold">{price(item.amount)}</span>
                  </div>
                ))}
              </div>
            )}
            {row?.coupon && (
              <div className="border-t-2 border-gray-200 py-2">
                <div className="flex justify-between">
                  <div>
                    <div className="font-bold">Discount</div>
                    <div className="text-xs">{row.coupon.code}</div>
                  </div>
                  <span className="font-bold">
                    {price(-row.coupon.discountAmount)}
                  </span>
                </div>
              </div>
            )}
            <div className="border-y-2 border-gray-200 py-2">
              <div className="flex justify-between items-end">
                <div>
                  {/* <div className="text-xs">Payment Gateway: <b>{row.gateway}</b></div> */}
                  <div className="font-bold">Total</div>
                </div>
                <span className="font-bold">
                  {price(row?.amount, row?.currency) || 'NA'}
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Payment Gateway',
      dataIndex: 'gateway',
      key: 'gateway',
      render: (gateway, row) => (
        <>
          <b className="text-muted text-uppercase">
            {camelCaseToSentence(gateway)}
          </b>
          <br />
          {/* <small className="text-muted">
            {row?.createdAt && format(new Date(row?.createdAt), "PPp")}
          </small> */}
        </>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (amount, row) => (
        <b className="text-muted text-uppercase text-right">
          {price(row?.amount, row?.currency) || 'NA'}
        </b>
      ),
    },
    {
      title: 'Payment Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => (
        <DateTimeAgo date={row?.updatedAt ?? createdAt} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          {/* <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link> */}
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="All Orders">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'title',
                label: 'Order Name',
              },
              {
                key: 'gateway',
                label: 'Payment Gateway',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Payment Gateway',
              dbKey: 'gateway',
              options: [
                { value: 'Admin Payment', label: 'Admin Payment' },
                { value: 'Stripe', label: 'Stripe' },
                { value: 'Coinbase', label: 'Coinbase' },
              ],
            },
            {
              placeholder: 'Payment Status',
              dbKey: 'status',
              options: paymentStatusOptions,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
    </Content>
  );
};

export default Orders;
