import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Layout, Avatar, Dropdown, Space, Row, Col, Button } from 'antd';
import {
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PoweroffOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { config } from 'config';

import { useAuth } from '../backend/useAuth';
import {
  selectTheme,
  toggleSiderFullCollapsedAction,
} from 'features/theme/themeSlice';
import { useWeb3 } from 'contexts/Web3Context';
import { selectPagination } from 'features/pagination/paginationSlice';
import { selectWeb3 } from 'features/web3Slice';
import { useBackend } from 'backend/useBackend';
import {
  assetCertificatesActions,
  selectAssetCertificates,
} from 'features/cms/assetsCertificateSlice';
import { selectAuth } from 'features/auth/authSlice';
import { fulfilled } from 'features/status';
// import { selectAuth } from "features/auth/authSlice";

const HeaderLayout = () => {
  const dispatch = useDispatch();
  const { currentAccount } = useSelector(selectWeb3);
  const auth = useSelector(selectAuth);
  const {
    status,
    logged,
    user: { email },
  } = auth;

  const { logout, loginGenerateToken } = useAuth();
  const { sider, header } = useSelector(selectTheme);
  const { total, count } = useSelector(selectPagination);
  const { status: assetsStatus } = useSelector(selectAssetCertificates);
  // const { pathname } = useLocation();
  const { connectWallet } = useWeb3();
  const { getList } = useBackend();

  useEffect(() => {
    if (status === fulfilled && logged === true && assetsStatus !== fulfilled) {
      getList(
        'assets-certificate',
        { order: 'asc', orderBy: 'title' },
        { msg: null },
      ).then(({ items }) => {
        dispatch(assetCertificatesActions.load(items));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged, assetsStatus]);

  const items = [
    {
      key: 'item-1',
      icon: <LockOutlined />,
      label: <Link to="/auth/change-pass">Change Password</Link>,
    }, // remember to pass the key prop
    {
      key: 'item-2',
      danger: true,
      icon: <PoweroffOutlined />,
      onClick: () => logout('/auth/login'),
      label: 'Logout',
    },
  ];
  console.log(currentAccount);

  const handleRWA = async () => {
    loginGenerateToken({ loginAs: email }).then((data) => {
      const adminLoginUrl = `${config.resellerUrl}/auth/admin-login/${data.tokenId}/asset`;
      window.open(adminLoginUrl, '_self');
    });
  };

  return (
    <Layout.Header className={cx({ 'ant-header-fixed': header.fixed })}>
      <Row justify={'space-between'}>
        <Col md={{ span: 8 }}>
          {React.createElement(
            sider.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => dispatch(toggleSiderFullCollapsedAction()),
            },
          )}
          {/* {JSON.stringify({ hasMore: hasMore })} */}
          <Space>
            {total > 0 && count > 0 && (
              <span>
                <b>{count}</b> Record/s loaded out of <b>{total}</b>
              </span>
            )}
            {/* <span>{prevPath} = {pathname}</span> */}
          </Space>
        </Col>
        <Col md={{ span: 13 }} className="text-right hidden md:block">
          {currentAccount ? (
            <div>
              <span className="pr-2">Selected Wallet address: </span>
              <b className="text-green-700"> {currentAccount}</b>
            </div>
          ) : (
            <>
              <b>
                {/* 🦊 Wallet not connected */}
                <Button onClick={() => connectWallet()}>
                  🦊 Wallet not connected
                </Button>
              </b>
            </>
          )}
          {/* <div className="user-box">
            <Dropdown menu={{ items }} placement="topRight" arrow>
              <Space align="center">
                <Avatar icon={<UserOutlined />}></Avatar>
              </Space>
            </Dropdown>
          </div> */}
        </Col>
        <Col md={{ span: 1 }} className="text-right">
          <Button onClick={handleRWA}>RWA</Button> 
        </Col>
        <Col md={{ span: 1 }} className="text-right">
          <Dropdown menu={{ items }} placement="topRight" arrow>
            <Space align="center">
              <Avatar icon={<UserOutlined />}></Avatar>
            </Space>
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  );
};

export default HeaderLayout;
