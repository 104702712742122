export const getBadgeColor = (status) => {
  switch (status) {
    case 'Confirmed':
      return 'green';
    case 'Active':
      return 'green';
    case 'Success':
      return 'green';
    case 'Publish':
      return 'green';
    case 'Inactive':
      return 'red';
    case 'Awaiting':
      return 'yellow';
    case 'Pending':
      return 'yellow';
    case 'Hold':
      return 'yellow';
    case 'Blocked':
      return 'volcano';
    case 'Failed':
      return 'volcano';
    case 'Canceled':
      return 'volcano';
    case 'Expired':
      return 'volcano';
    case 'Disabled':
      return 'volcano';
    default:
      return 'geekblue';
  }
};

export const getLabel = (status) => {
  switch (status) {
    case 'Awaiting':
      return 'Awaiting Payment';
    case 'Canceled':
      return 'Canceled';
    case 'Confirmed':
      return 'Confirmed';
    case 'Expired':
      return 'Expired';
    case 'Failed':
      return 'Failed';
    case 'Pending':
      return 'Pending';
    case 'Processing':
      return 'Processing';
    case 'Success':
      return 'Success';
    default:
      return 'NA';
  }
};

export function shareHolderCountLabel(key) {
  switch (key) {
    case 1:
      return 'For One Shareholder';
    case 2:
      return 'For Two Shareholders';
    default:
      return '';
  }
}

export function businessTypeLabel(cType) {
  switch (cType) {
    case 'TRUST':
      return 'Trust';
    case 'CORP':
      return 'Corp';
    case 'FAMILY':
      return 'Family Trust';
    case 'BANK':
      return 'Bank';
    default:
      return '';
  }
}

export function camelCaseToSentence(text) {
  if(text === undefined || text === null) return '';
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function pascalCaseToSentence(text) {
  if(text === undefined || text === null) return '';
  const result = text.replace(/([A-Z])/g, ' $1');
  return result;
}
