import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentAccount: null,
  chainId: null,
  tokens: [
    // {
    //   name: "",
    //   symbol: "",
    //   decimals: 18,
    //   balance: 0,
    // },
  ],
};
const uniqueItems = (items) => {
  var lookup = [];
  var result = [];

  for (const item of items) {
    var id = item?.symbol;

    if (!id) {
      return;
    }
    if (!lookup.includes(id)) {
      lookup.push(id);
      result.push(item);
    }
  }
  return result;
};

export const web3Slice = createSlice({
  name: 'web3',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // loadAccount: (state) => {
    //   state.loaded = true;
    // },
    loadAccount: (state, action) => {
      state.currentAccount = action.payload.currentAccount;
      state.chainId = action.payload.chainId;
    },
    loadBalance: (state, { payload }) => {
      const tokens = state.tokens.concat({
        name: payload.name,
        symbol: payload.symbol,
        decimals: payload.decimals,
        balance: payload?.balance ?? 0, //payload.balance,
      });
      state.tokens = uniqueItems(tokens);
    },
  },
});

export const web3Actions = web3Slice.actions;
export const selectWeb3 = (state) => state.web3;
