import {
  // createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { failed, fulfilled, idle, loading } from 'features/status';
// import { fetchCount } from './counterAPI';

const initialState = {
  status: idle,
  user: {
    displayName: 'Loading...',
    limit: 20,
  },
  token: '',
  logged: false,
  roles: [],
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount) => {
//     // const response = await fetchCount(amount);
//     // // The value we return becomes the `fulfilled` action payload
//     // return response.data;
//   }
// );

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      // console.log("action", action);
      state.user = {
        ...action.payload.user,
        uid: action.payload.uid,
      };
      state.user.limit = action.payload.user.limit || 50;
      state.status = fulfilled;
      state.token = action.payload.token;
      state.roles = action.payload.roles;
      state.logged = true;
    },
    updateProfile: (state, action) => {
      state.user = {
        ...action.payload.updated,
        uid: action.payload.uid,
      };
      state.status = fulfilled;
    },
    updateField: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload.field,
      };
      state.status = action.payload.status;
    },
    authError: (state) => {
      state.status = failed;
    },
    loading: (state) => {
      state.status = loading;
    },
    setLimit: (state, action) => {
      state.user.limit = action.payload.limit;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    logout: (state) => {
      state = initialState;
    },
  },
  // // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading";
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle";
  //       state.value += action.payload;
  //     });
  // },
});

export const {
  authError: authErrorAction,
  login: loginAction,
  logout: logoutAction,
  updateField: updateFieldAction,
  updateProfile: updateProfileAction,
  setLimit: setLimitAction,
} = authSlice.actions;

export const authAction = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
