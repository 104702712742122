import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Layout, Space, Card, Badge, Modal } from 'antd';
import {
  DeleteOutlined,
  DollarOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { getBadgeColor } from 'utils/labels';
import { usePaginate } from 'backend/usePaginate';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';
import { countryNameByIso2 } from 'data/countries';
import { config } from 'config';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';
import { useBusiness } from 'hooks/useBusiness';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'business';
const contractExplorerUrl = config.contractExplorerUrl;
const businessContractAddress = config.businessContractAddress;
const openSeaUrl = config.openSeaUrl;

const Business = () => {
  const {
    businessType,
    btEntityEndings,
    corpEntityEndings,
    familyEntityEndings,
    bankEntityEndings,
  } = useSelector(selectCMS);

  const { addItem, trashItem } = useBackend();
  const { businessTypeLabel } = useBusiness();
  const { hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    limit: 100,
  });

  const incompleteBusinessInfo = (item) => {
    Modal.info({
      title: 'Incomplete Business details!',
      content: (
        <>
          <div>
            User haven't filled all the details or upload the documents for{' '}
            {item.businessName} {item.entityEnding}
          </div>
          <small>
            Please, ask the user to fill all the details and upload documents.
          </small>
        </>
      ),
      centered: true,
      okText: 'Ok, Understood',
    });
  };

  const orderAdminBusiness = (item) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to make order{' '}
            <b>
              {item.businessName} {item.entityEnding}
            </b>
            ?
          </div>
          <small>
            By clicking Yes, you will make an order on behalf of client?
          </small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => {
        addItem(
          `order/business-admin/${item.id}`,
          {},
          {
            loader: true,
            msg: {
              loading: 'Making Admin Order Payment...',
              success: 'Payment Success.',
            },
          },
        ).then(() => {
          filter({});
        });
      },
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const deleteConfirm = (item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete{' '}
            <b>
              {item.businessName} {item.entityEnding}
            </b>
            ?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'filingNumber',
      key: 'filingNumber',
    },
    {
      title: 'Business Details',
      dataIndex: 'businessName',
      key: 'businessName',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'businessName'),
      // sortDirections: ['descend']
      render: (businessName, row) => (
        <div>
          {/* <div className="text-lg">{row?.filingNumber}</div>
          <div className="text-lg">{row?.bicra}</div> */}
          <small>Registered as {row?.registeredAs}</small>
          {row?.user?.email && (
            <small>
              {' '}
              By{' '}
              <b>
                <Link to={`/users/view/${row?.user.id}`} className="underline">
                  {row.user.email}
                </Link>
              </b>
            </small>
          )}
          <br />
          <b className="text-gray-600">
            {businessName} {row?.entityEnding}
          </b>
          <br />
          {/* <small className="text-gray-600">Registered At: {format(new Date(row?.createdAt), 'PPp')}</small><br /> */}
          <small className="text-gray-600">
            <b>{row?.bicraFormatted}</b>
          </small>
          <br />
          {row?.filingNumberFormatted && (
            <>
              <small className="text-gray-600">
                <b>Filing Number: {row?.filingNumberFormatted}</b>
              </small>
              <br />
            </>
          )}
          <small className="text-gray-600">
            {row?.shareholderType && row?.shareholderType + ' Shareholder'}
          </small>
          <br />
          <small className="text-gray-600">
            Business Type: {businessTypeLabel(row.businessType)}
          </small>
          {row.transactionHash && (
            <>
              <br />
              <small className="text-gray-600">
                <a
                  href={`${contractExplorerUrl}/tx/${row.transactionHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View it On BlockChain Explorer</b>
                </a>
              </small>
              <br />
              <small className="text-gray-600">
                <a
                  href={`${openSeaUrl}/${businessContractAddress}/${Number(
                    row?.filingNumberFormatted,
                  )}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>View it On Open Sea</b>
                </a>
              </small>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Business Residency',
      dataIndex: 'country',
      key: 'country',
      render: (country) => countryNameByIso2(country),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Registered At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => <DateTimeAgo date={createdAt} />,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          {!row.agreed && (
            <InfoCircleOutlined
              title="User dose not have filled all details"
              onClick={() => incompleteBusinessInfo(row)}
            />
          )}
          {row.agreed && row.status === 'Pending' && (
            <Link
              to="#"
              className="p-0"
              onClick={() => orderAdminBusiness(row)}
            >
              <DollarOutlined
                className="text-dark"
                title="Make Business Order with Admin Payment"
              />
            </Link>
          )}
          <Link
            to={{
              pathname: `view/${id}`,
            }}
          >
            <EyeOutlined className="text-blue-700" title="View" />
          </Link>
          {/* <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link> */}
          <DeleteOutlined
            className="text-red-600"
            onClick={() => deleteConfirm(row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Business">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          searchControl={{
            options: [
              {
                key: 'businessName',
                label: 'Business Name',
              },
              {
                key: 'bicraFormatted',
                label: 'BICRA',
              },
              {
                key: 'filingNumberFormatted',
                label: 'Filing Number',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Business Type',
              dbKey: 'businessType',
              options: businessType,
            },
            {
              placeholder: 'Entity Endings  ',
              dbKey: 'entityEnding',
              options: [
                ...btEntityEndings,
                ...familyEntityEndings,
                ...corpEntityEndings,
                ...bankEntityEndings,
              ],
            },
            // {
            //   placeholder: "Shareholder Count",
            //   dbKey: "shareholderCountNum",
            //   options: shareholderCount,
            // },
            {
              placeholder: 'Shareholder Type',
              dbKey: 'shareholderType',
              options: [
                {
                  value: 'Individual',
                  label: 'Individual',
                },
                {
                  value: 'Corporate',
                  label: 'Corporate',
                },
              ],
            },
            {
              placeholder: 'Registered As',
              dbKey: 'registeredAs',
              options: [
                {
                  value: 'Agent',
                  label: 'Agent',
                },
                {
                  value: 'Individual',
                  label: 'Individual',
                },
              ],
            },
            // {
            //   placeholder: "Registered As 2",
            //   dbKey: "registeredAs",
            //   options: [
            //     {
            //       value: "Agent",
            //       label: "Agent",
            //     },
            //     {
            //       value: "Individual",
            //       label: "Individual",
            //     },
            //   ],
            // },
          ]}
          dateRangeControl
          handleFilter={filter}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
    </Content>
  );
};

export default Business;
