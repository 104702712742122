import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
  Layout,
  FloatButton,
  Space,
  Card,
  Badge,
  Modal,
  Popover,
} from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { usePaginate } from 'backend/usePaginate';
import { pascalCaseToSentence, getBadgeColor } from 'utils/labels';
import { useBackend } from 'backend/useBackend';
import InfiniteScroll from 'react-infinite-scroller';
import FilterPanel from 'components/FilterPanel';
import { sorter } from 'utils/custom';
import { selectCMS } from 'features/cms/cmsSlice';
import DateTimeAgo from 'components/DateTimeAgo';

const { Content } = Layout;

const endpoint = 'coupons';

const Coupons = () => {
  const navigation = useNavigate();
  const { discountType } = useSelector(selectCMS);

  const { trashItem } = useBackend();
  const { total, hasMore, items, loadMore, filter } = usePaginate(endpoint, {
    orderBy: 'code',
    order: 'asc',
    limit: 100,
  });

  const deleteConfirm = (e, item) => {
    let config = {
      loader: true,
      msg: { loading: 'Deleting...', success: 'Deleted successfully.' },
    };
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <>
          <div>
            You want to delete <b>{item.code}</b>?
          </div>
          <small>After delete, Data can't be recovered.</small>
        </>
      ),
      centered: true,
      // onCancel: () => setList(list.filter(current => current.id !== item.id)),
      cancelText: 'No',
      onOk: () => trashItem(endpoint, item.id, config),
      okText: 'Yes',
      cancelButtonProps: {
        danger: true,
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      // filters: {},
      // sorter: true,
      sorter: (a, b) => sorter(a, b, 'code'),
      // sortDirections: ['descend'],
      render: (code, row) => <b>{code}</b>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      responsive: ['md'],
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   responsive: ["md"],
    // },
    // {
    //   title: "Discount Type",
    //   dataIndex: "discountType",
    //   key: "discountType",
    //   responsive: ["md"],
    // },
    {
      align: 'right',
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      responsive: ['md'],
      render: (discount, row) => (
        <b>
          {discount}
          {row.discountType === 'Percentage' && '%'}
        </b>
      ),
    },
    {
      align: 'right',
      title: 'Discount (Upto)',
      dataIndex: 'upTo',
      key: 'upTo',
      responsive: ['md'],
    },
    {
      align: 'right',
      title: 'Use Condition',
      dataIndex: 'userRule',
      key: 'userRule',
      responsive: ['md'],
      render: (userRule, row) => (
        <>
          <div>
            {userRule === 'SelectedUsers' ? (
              <Popover
                content={row.users.map((user) => (
                  <div>{user.label}</div>
                ))}
                title="Selected User/s"
              >
                <b className="cursor-pointer">
                  {pascalCaseToSentence(userRule)}
                </b>
              </Popover>
            ) : (
              pascalCaseToSentence(userRule)
            )}
          </div>
          <div>
            {row.productRule === 'SelectedProducts' ? (
              <Popover
                content={row.products.map((product) => (
                  <div>{product.label}</div>
                ))}
                title="Selected Product/s"
              >
                <b className="cursor-pointer">
                  {pascalCaseToSentence(row.productRule)}
                </b>
              </Popover>
            ) : (
              pascalCaseToSentence(row.productRule)
            )}
          </div>
        </>
      ),
    },
    {
      align: 'right',
      title: 'Use Limit',
      dataIndex: 'limit',
      key: 'limit',
      responsive: ['md'],
      render: (limit, row) => (
        <b>{limit === 0 ? 'Unlimited' : `${limit} time`}</b>
      ),
    },
    {
      align: 'right',
      title: 'Over All Use Limit',
      dataIndex: 'overAllLimit',
      key: 'overAllLimit',
      responsive: ['md'],
      render: (limit, row) => (
        <b>{limit === 0 ? 'Unlimited' : `${limit} time`}</b>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge color={getBadgeColor(status)} count={status} />
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt, row) => (
        <DateTimeAgo date={row?.updatedAt ?? createdAt} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      className: 'action',
      render: (id, row) => (
        <Space size="middle" className="action">
          <Link
            to={{
              pathname: `edit/${id}`,
            }}
          >
            <EditOutlined className="text-yellow-500" title="Edit" />
          </Link>
          <DeleteOutlined
            className="text-red-600"
            onClick={(e) => deleteConfirm(e, row)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Content>
      <Card title="Coupons">
        {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
        <FilterPanel
          total={total}
          searchControl={{
            options: [
              {
                key: 'code',
                label: 'Code',
              },
            ],
          }}
          selectControl={[
            {
              placeholder: 'Discount Type',
              dbKey: 'discountType',
              options: discountType,
            },
          ]}
          dateRangeControl
          handleFilter={(filterData) => {
            filter(filterData);
          }}
        />
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <h4 style={{ textAlign: 'center' }} key="loader">
              Loading...
            </h4>
          }
        >
          <Table
            rowKey="id"
            bordered
            tableLayout="auto"
            dataSource={items}
            columns={columns}
            pagination={false}
          />
        </InfiniteScroll>
      </Card>
      <FloatButton
        type="primary"
        tooltip="Add New Coupon"
        icon={<PlusOutlined />}
        onClick={() => navigation('add')}
      />
    </Content>
  );
};

export default Coupons;
