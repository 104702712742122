import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Layout, Descriptions, Skeleton, Card, Badge, Col, Row } from 'antd';
import { useBackend } from 'backend/useBackend';
import { businessTypeLabel, getBadgeColor } from 'utils/labels';
import { usePrice } from 'hooks/usePrice';
import DateTimeAgo from "components/DateTimeAgo"

const { Content } = Layout;
const endpoint = 'order';

const OrderInfo = () => {
  const { price } = usePrice();
  // const navigate = useNavigate();
  // const { currentAccount } = useSelector(selectWeb3);
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const { getItem } = useBackend();

  useEffect(() => {
    getItem(endpoint, id).then(({ item }) => {
      setItem(item);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Content>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
      {!item?.id ? (
        <Card title="Loading">
          <Skeleton />
        </Card>
      ) : (
        <Card
          title={
            <div className="space-x-2">
              <span>{item?.gateway}</span>
              <Badge color={getBadgeColor(item?.status)} count={item?.status} />
            </div>
          }
        >
          <Row>
            <Col span={12}>
              <div className="mb-4">
                {item?.items?.length > 0 && (
                  <div className="border-t-2 border-gray-200 p-2">
                    {/* <small>{item?.type}</small> */}
                    {item.items.map((item, i) => (
                      <div key={i} className="flex justify-between">
                        {/* <div>{bus.id}</div> */}
                        <div>
                          <div className="font-bold">{item.title}</div>
                          <div className="text-xs">{item.name}</div>
                        </div>
                        <span className="font-bold">{price(item.amount)}</span>
                      </div>
                    ))}
                  </div>
                )}
                {item?.coupon && (
                  <div className="border-t-2 border-gray-200 p-2">
                    {/* <small>{item?.type}</small> */}
                    <div className="flex justify-between">
                      {/* <div>{bus.id}</div> */}
                      <div>
                        <div className="font-bold">Discount</div>
                        <div className="text-xs">{item.coupon.code}</div>
                      </div>
                      <span className="font-bold">
                        {price(-item.coupon.discountAmount)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="border-y-4 border-gray-200 p-2">
                  {/* <small>{item?.type}</small> */}
                  <div className="flex justify-between items-end">
                    {/* <div>{bus.id}</div> */}
                    <div>
                      {/* <div className="text-xs">Payment Gateway: <b>{item.gateway}</b></div> */}
                      <div className="font-bold">Total</div>
                    </div>
                    <span className="font-bold">
                      {price(item?.amount, item?.currency) || 'NA'}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Descriptions
            title="Payment/Order Details"
            layout="vertical"
            bordered
          >
            <Descriptions.Item label="Order Title" span={3}>
              <b className="text-muted">{item?.title}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Order Amount" span={1}>
              <b className="text-muted text-uppercase">
                {price(item?.amount, item?.currency) || 'NA'}
              </b>
            </Descriptions.Item>
            <Descriptions.Item label="Order Type" span={2}>
              <b>{item.type}</b>
            </Descriptions.Item>
          </Descriptions>
          {item.type === 'Business' &&
            item?.items.map((oItem) => (
              <>
                <br />
                <Descriptions
                  title="Business Details"
                  layout="vertical"
                  bordered
                >
                  <Descriptions.Item label="Business Name" span={2}>
                    <b>
                      {oItem?.business?.businessName}{' '}
                      {oItem?.business?.entityEnding}
                    </b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Business Type" span={1}>
                    <b>{businessTypeLabel(oItem?.business?.businessType)}</b>
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Business Residency" span={3}>
                    {countryNameByIso2(oItem?.business?.country)}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="BICRA">
                    <b>{oItem?.business?.bicraFormatted}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Filing Number">
                    <b>{oItem?.business?.filingNumberFormatted}</b>
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions
                  title="Shareholder Details"
                  layout="vertical"
                  bordered
                >
                  <Descriptions.Item label="Shareholder Type">
                    {oItem?.business?.shareholderType}
                  </Descriptions.Item>
                </Descriptions>
              </>
            ))}
          {item.type === 'GoodStanding' &&
            item?.items.map((oItem) => (
              <>
                <br />
                <Descriptions
                  title="Good Standing Details"
                  layout="vertical"
                  bordered
                >
                  <Descriptions.Item label="Business Name" span={2}>
                    <b>{oItem?.goodStanding?.title}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Where to Use" span={1}>
                    <b>{oItem?.goodStanding?.whereToUse}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivery Email" span={2}>
                    <b>{oItem?.goodStanding?.deliveryEmail}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Delivered" span={1}>
                    <b>{oItem?.goodStanding?.delivered ? 'Yes' : 'No'}</b>
                  </Descriptions.Item>
                </Descriptions>
              </>
            ))}
          {/* {item.type === "Registration" &&
            item?.items.map((oItem) => (
              <>
                <br />
                <Descriptions
                  title="Business Details"
                  layout="vertical"
                  bordered
                >
                  <Descriptions.Item label="Business Name" span={2}>
                    <b>
                      {oItem?.user?.businessName}{" "}
                      {oItem?.user?.entityEnding}
                    </b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Business Type" span={1}>
                    <b>{businessTypeLabel(oItem?.user?.businessType)}</b>
                  </Descriptions.Item>
                  <Descriptions.Item label="Business Residency" span={2}>
                    {countryNameByIso2(oItem?.user?.country)}
                  </Descriptions.Item>
                  <Descriptions.Item label="BICRA">
                    {oItem?.user?.bicraFormatted}
                  </Descriptions.Item>
                  <Descriptions.Item label="Filing Number">
                    {oItem?.user?.filingNumberFormatted}
                  </Descriptions.Item>
                </Descriptions>
              </>
            ))} */}
          {/* <br />
          <Descriptions title="Contact Info" layout="vertical" bordered>
            <Descriptions.Item label="Tel Number">
              {item.landLine}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{item.email}</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="Address Info" layout="vertical" bordered>
            <Descriptions.Item label="Street">{item.street}</Descriptions.Item>
            <Descriptions.Item label="City">{item.city}</Descriptions.Item>
            <Descriptions.Item label="State">{item.state}</Descriptions.Item>
            <Descriptions.Item label="Country">
              {countryNameByIso2(item?.country)}
            </Descriptions.Item>
            <Descriptions.Item label="ZipCode">
              {item.zipCode}
            </Descriptions.Item>
          </Descriptions> */}
          <br />
          <Descriptions title="System Details" layout="vertical" bordered>
            {/* <Descriptions.Item label="Email Verified"></Descriptions.Item> */}
            <Descriptions.Item label="Created At">
              <DateTimeAgo date={item.createdAt} onlyDate />
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated At">
              <DateTimeAgo date={item.updatedAt} onlyDate />
            </Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </Content>
  );
};

export default OrderInfo;
