import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  colorPrimary: '#00b96b',
  header: {
    fixed: true,
  },
  sider: {
    fixed: true,
    fullCollapsed: false,
    collapsed: false,
  },
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    toggleHeaderFixedAction: (state, action) => {
      state.header.fixed = !state.header.fixed;
    },
    toggleSiderFixedAction: (state) => {
      state.sider.fixed = !state.sider.fixed;
    },
    toggleSiderCollapsedAction: (state) => {
      state.sider.collapsed = !state.sider.collapsed;
    },
    toggleSiderFullCollapsedAction: (state) => {
      state.sider.collapsed = !state.sider.collapsed;
      state.sider.fullCollapsed = !state.sider.fullCollapsed;
    },
  },
});

export const {
  toggleHeaderFixedAction,
  toggleSiderCollapsedAction,
  toggleSiderFixedAction,
  toggleSiderFullCollapsedAction,
} = themeSlice.actions;

export const selectTheme = (state) => state.theme;

export default themeSlice.reducer;
