import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';
import { truncateHexValue } from 'utils/custom';
import { config } from 'config';

const contractExplorerUrl = config.contractExplorerUrl;

const Processing = () => {
  return (
    <>
      <Spin tip="Loading" size="large" />
      <div style={{ paddingTop: 10 }}>Minting in process!</div>
      <div>Don't refresh, Please wait for the transaction to complete.</div>
    </>
  );
};

const MintSuccess = ({ txHash }) => {
  const testHash = contractExplorerUrl+ '/tx/' + txHash;
  console.log(testHash, "test....");
  return (
    <>
      <div>
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 36 }} />
      </div>
      <div
        style={{
          paddingTop: 10,
          width: '100%',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        <div>Mint successful! </div>
        <div>View your transaction on Block Explorer</div>
        <a
          href={`${contractExplorerUrl}/tx/${txHash}`}
          target="_blank"
          rel="noreferrer"
          style={{ color: '#1677ff' }}
        >
          {truncateHexValue(txHash, 24)}
        </a>
      </div>
    </>
  );
};

const MintFail = ({ errorMessage }) => {
  return (
    <>
      <div>
        <CloseCircleTwoTone twoToneColor="#750e0e" style={{ fontSize: 36 }} />
      </div>
      <div
        style={{
          paddingTop: 10,
          width: '100%',
          fontSize: 12,
          textAlign: 'center',
        }}
      >
        {errorMessage}
      </div>
    </>
  );
};

export { Processing, MintSuccess, MintFail };
