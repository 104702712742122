import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAuth } from './backend/useAuth';
import Routes from './routes';
import { selectAuth } from 'features/auth/authSlice';
import { fulfilled } from 'features/status';

const App = () => {
  const { status } = useSelector(selectAuth);
  const { tokenLogin } = useAuth();

  useEffect(() => {
    if (status !== fulfilled) {
      tokenLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Routes />;
};

export default App;
